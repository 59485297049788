import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,Grid, Typography, Avatar
} from '@material-ui/core';
import moment from 'moment';
import {config} from '../../../../config';
import BoltIcon from "@material-ui/icons/Power";
// import chartDataRepo from "../../../../helpers/chartDataRepo";


const useStyles = makeStyles(theme => ({
  root: {
    //height: '100%'
    height: theme.spacing(12),
    marginTop: theme.spacing(2),
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));



const TotalControllers = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  //
  // const [chartData, setChartData] = useState({});
  //
  let now =  new Date(Date.now());
  let newFromTime = new Date(
      now.getFullYear() , //-20% of the diff rounded.
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0,
      0
  );
  let newToTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      0
  );

  // eslint-disable-next-line
  const [endDate, setEndDate] = useState(newToTime);
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(newFromTime);

  const [total, setTotal] = useState({"total" : "-"});

  useEffect(() => {
    let dataUrl = config.cqsolaDataApi + '/optimiser/list';
    let params = {
      // 'filter[install_id]': installid,
      'filter[from]': moment(startDate).unix(),
      'filter[to]':   moment(endDate).unix(),
      'limit': 100,
      'page': 0 //getpage here
    };
    // if (searchQuery !== ''){
    //   params['filter[search_query]'] = searchQuery;
    // }
    var queryString = Object.keys(params).map(function(key) {
      return key + '=' + params[key]
    }).join('&');

    fetch(dataUrl + '?' + queryString,{
      credentials: 'include',
    })
      .then(res => res.json())
      .then(
        foundControllers => {
          setTotal({'total' : Array.isArray(foundControllers) && foundControllers.length > 0? foundControllers.length: '-'});
          //chartDataRepo.registerOptimiserIdsForInstallId(installid,(Array.isArray(foundControllers))? getControllerSerialNumbers(foundControllers): [] );
        });
  },
      [startDate, endDate]);

  return (
      <Card
          {...rest}
          className={clsx(classes.root, className)}
      >
        <CardContent>
          <Grid
              container
              justify="space-between"
          >
            <Grid item>
              <Typography
                  className={classes.title}
                  color="inherit"
                  gutterBottom
                  variant="body2"
              >
                TOTAL CONTROLLERS CONNECTED
              </Typography>
              <Typography
                  color="inherit"
                  variant="h3"
              >
                {total.total}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <BoltIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
};

TotalControllers.propTypes = {
  className: PropTypes.string
};

export default TotalControllers;
