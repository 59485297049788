import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import { AccountInviteToolbar, AccountInviteTable } from './components';
import Query from '../../data/Query';
import {DataService} from '../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AccountInviteList = () => {
  const classes = useStyles();
  const dataProvider = React.useContext(DataService);


  const [forceTableRefresh, setForceTableRefresh] = useState(false);
  const [invites, setInvites] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedInvites, setSelectedInvites] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [inviteTotal, setInviteTotal] = useState(0);

  const revokeAccountInvite = inviteId => {
    dataProvider.Account().revokeInviteToJoinAccount(inviteId)
      .then(() => {
        setForceTableRefresh(!forceTableRefresh)
      } )
      .catch(() => {})
  };

  useEffect(() => {

    let listQuery = new Query({ filter: {query: query, page: page, page_size: rowsPerPage} });

    dataProvider.Account().listPendingInvitations(listQuery)
      .then((invitesInAccount) => {
        setInvites(invitesInAccount.invites);
        setInviteTotal(invitesInAccount.meta.total);
      })
  }, [dataProvider, page, rowsPerPage, query, inviteTotal, forceTableRefresh])

  return (
    <div className={classes.root}>
      <AccountInviteToolbar
        query={query}
        inviteTotal={inviteTotal}
        setInviteTotal={setInviteTotal}
        setQuery={(query) => { setPage(0); setQuery(query) }}
      />
      <Typography variant="h5">Pending Invitations</Typography>
      <div className={classes.content}>

        <AccountInviteTable
          invites={invites}
          inviteTotal={inviteTotal}
          selectedInvites={selectedInvites}
          setSelectedInvites={setSelectedInvites}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          revokeAccountInvite={revokeAccountInvite}
        />
      </div>
    </div>
  );
};

export default AccountInviteList;
