import React from 'react';
import ReactDOM from 'react-dom';
import { loadReCaptcha } from 'react-recaptcha-v3'
import {config} from './config';
import * as serviceWorker from './serviceWorker';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

loadReCaptcha(config.reCaptchaSiteKey, () => {});


serviceWorker.unregister();
