import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import md5 from 'md5';
//import {StatusBullet} from "../../../../../../components";
////import {Alert} from "@material-ui/lab";

// eslint-disable-next-line
const statusColors = {
  SeverityTypeInfo: 'primary',
  SeverityTypeWarning: 'warning',
  SeverityTypeCritical: 'danger'
};

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DeviceEventLogsTable = props => {

  //dataProvider DataProvider
  // const dataProvider = React.useContext(DataService);
  const { className,
    deviceEventLogs,
    rowTotal,
    setRowTotal,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    ...rest } = props;

  const classes = useStyles();


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getIcon = severity => {
    switch (String.prototype.toLowerCase.call(severity)) {
      case 'critical':
        return <ErrorOutlineOutlinedIcon color={'error'} />
      case 'warning':
        return <ReportProblemOutlinedIcon htmlColor={'orange'}/>
      case 'info':
      default:
        return <InfoOutlinedIcon htmlColor={'#1e88e5'}/>
    }
  }

  return (
    <>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Detected At</TableCell>
                    <TableCell>Log Type</TableCell>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { /*solarStrings.slice(page, rowsPerPage).map(solarString => ( */ }
                  { deviceEventLogs.map(deviceEventLog => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={md5(JSON.stringify(deviceEventLog))}
                      // selected={selectedCommsUnit.indexOf(deviceEventLog.id) !== -1}
                    >
                      <TableCell>
                        { getIcon(deviceEventLog.severity) }
                      {/*<StatusBullet*/}
                      {/*  className={classes.severity}*/}
                      {/*  color={statusColors[deviceEventLog.severity]}*/}
                      {/*  size="sm"*/}
                      {/*/>*/}
                      </TableCell>
                      <TableCell>{deviceEventLog.serial_number}</TableCell>
                      <TableCell>{moment(deviceEventLog.detected_at).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                      <TableCell>{deviceEventLog.log_type}</TableCell>
                      <TableCell>{deviceEventLog.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={rowTotal}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </>
  );
};

DeviceEventLogsTable.propTypes = {
  className: PropTypes.string,
  deviceEventLogs: PropTypes.array.isRequired
};

export default DeviceEventLogsTable;
