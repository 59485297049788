import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';
import Query from '../../data/Query';
import {DataService} from '../../App';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();
  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [currentAccount, setCurrentAccount] = useState({});
  const [query, setQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [userTotal, setUserTotal] = useState(0);

  useEffect(() => {
    dataProvider.User().getCurrentUser()
      .then((user) => {
        setCurrentUser(user)
      })
  }, [dataProvider])

  useEffect(() => {
    dataProvider.Account().getCurrentAccount()
      .then((account) => {
        setCurrentAccount(account)
      })
  }, [dataProvider])


  useEffect(() => {

    let listQuery = new Query({ filter: {query: query, page: page, page_size: rowsPerPage} });

    dataProvider.Account().listUsersInAccount(listQuery)
      .then((usersInAccount) => {
        setUsers(usersInAccount.users);
        setUserTotal(usersInAccount.meta.total);
      })
  }, [dataProvider, page, rowsPerPage, query, userTotal])

  return (
    <div className={classes.root}>
      <UsersToolbar
        currentUserEmail={currentUser.email}
        isCurrentUserAccountOwner={currentAccount.is_current_user_owner}
        query={query}
        setQuery={(query) => { setPage(0); setQuery(query) }}
      />
      <div className={classes.content}>
        <UsersTable
          users={users}
          userTotal={userTotal}
          setUserTotal={setUserTotal}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          currentUserEmail={currentUser.email}
          isCurrentUserAccountOwner={currentAccount.is_current_user_owner}
        />
      </div>
    </div>
  );
};

export default UserList;
