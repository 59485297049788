import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {ReportTable, ReportFilters, ReportToolbar} from './components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }
}));



function createShadingData(insightId, controllerId, location, group, startedAt, finishedAt) {
  return { insightId, controllerId, location, group, startedAt, finishedAt };
}

const shadingRows = [
  createShadingData('1b','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createShadingData('1a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createShadingData('2a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createShadingData('3a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createShadingData('4a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createShadingData('5a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
];


const ReportList = () => {
  const classes = useStyles();
  let reports = [];
  return (
    <div className={classes.root}>

      {/*<Grid*/}
      {/*  container*/}
      {/*  spacing={4}*/}
      {/*>*/}

      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalShadedPanels />*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalDamagedPanels />*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalDamagedCables />*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalDamagedControllers />*/}
      {/*  </Grid>*/}

      {/*</Grid>*/}
      <Typography
        variant="body1"

      >
        Filters
      </Typography>
      <PrintIcon />

      <Typography
        gutterBottom
        variant="h2"
        className={classes.title}
      >
        Maintenance Report
      </Typography>
      <Typography
        color="textSecondary"
        variant="body1"
        className={classes.title}
      >
        11:48pm 28/12/2020
      </Typography>
      <ReportFilters />
      <Paper>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.title}
        >
          Shading Detected
        </Typography>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>

                <TableCell>Controller Id</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Group</TableCell>
                <TableCell align="right">Shading Started At</TableCell>
                <TableCell align="right">Shading Finished At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shadingRows.map((row) => (
                //controllerId, location, group, startedAt, finishedAt
                <TableRow key={row.insightId}>
                  <TableCell component="th" scope="row">
                    {row.controllerId}
                  </TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.group}</TableCell>
                  <TableCell align="right">{moment(row.startedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                  <TableCell align="right">{moment(row.finishedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.title}
        >
          PID Detected
        </Typography>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>

                <TableCell>Controller Id</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Group</TableCell>
                <TableCell align="right">First Detected At</TableCell>
                <TableCell align="right">Last Detected At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shadingRows.map((row) => (
                //controllerId, location, group, startedAt, finishedAt
                <TableRow key={row.insightId}>
                  <TableCell component="th" scope="row">
                    {row.controllerId}
                  </TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.group}</TableCell>
                  <TableCell align="right">{moment(row.startedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                  <TableCell align="right">{moment(row.finishedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          variant="h4"
          gutterBottom
          className={classes.title}
        >
          Under Performing Panels Detected - Likely Damaged
        </Typography>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>

                <TableCell>Controller Id</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Group</TableCell>
                <TableCell align="right">Weekly Expected</TableCell>
                <TableCell align="right">Weekly Measured</TableCell>
                <TableCell align="right">Detected At</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {shadingRows.map((row) => (
                //controllerId, location, group, startedAt, finishedAt
                <TableRow key={row.insightId}>
                  <TableCell component="th" scope="row">
                    {row.controllerId}
                  </TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.group}</TableCell>
                  <TableCell align="right">{moment(row.startedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                  <TableCell align="right">{moment(row.finishedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                  <TableCell align="right">{moment(row.finishedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          variant="h4"
          gutterBottom
          className={classes.title}
        >
          Damaged Controllers
        </Typography>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>

                <TableCell>Controller Id</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Group</TableCell>
                <TableCell align="right">Last Detected At</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {shadingRows.map((row) => (
                //controllerId, location, group, startedAt, finishedAt
                <TableRow key={row.insightId}>
                  <TableCell component="th" scope="row">
                    {row.controllerId}
                  </TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.group}</TableCell>
                  <TableCell align="right">{moment(row.finishedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


        <Typography
          variant="h4"
          gutterBottom
          className={classes.title}
        >
          Damaged Cables
        </Typography>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>

                <TableCell>Controller Id</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Group</TableCell>
                <TableCell align="right">Cable</TableCell>
                <TableCell align="right">Detected At</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {shadingRows.map((row) => (
                //controllerId, location, group, startedAt, finishedAt
                <TableRow key={row.insightId}>
                  <TableCell component="th" scope="row">
                    {row.controllerId}
                  </TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.group}</TableCell>
                  <TableCell align="right">{row.group}</TableCell>
                  <TableCell align="right">{moment(row.finishedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ReportToolbar />
      <div className={classes.content}>
        <ReportTable insights={reports} />
      </div>
    </div>
  );
};

export default ReportList;
