import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button, Typography} from '@material-ui/core';

import { SearchInput } from 'components';
import {Link, NavLink as RouterLink} from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  quoteText: {
    fontWeight: 300,
    paddingRight: theme.spacing(2),
    textDecoration: 'underline'
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    // marginRight: theme.spacing(1)
  }
}));


const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    // style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));



const UsersToolbar = props => {
  const { className, query, setQuery, currentUserEmail, isCurrentUserAccountOwner, ...rest } = props;

  const classes = useStyles();
  const handleOnChange = event => {
    event.preventDefault();
    setQuery(event.target.value);
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(isCurrentUserAccountOwner)? <>
        <div className={classes.row}>
          <span className={classes.spacer} />
          <Link
            className={classes.quoteText}
            to={'/account/invites'}
          >
            <Typography
              variant="body1"
            >Pending Invites</Typography>
          </Link>
          <Button
            color="primary"
            component={CustomRouterLink}
            to={'/account/invites'}
            variant="contained"
          >
            Invite user
          </Button>
        </div>
      </>: <></>}
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
          value={query}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
