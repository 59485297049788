import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
}));




const SidePlaneGridItem = props => {
  const classes = useStyles();

  return <Grid
    className={classes.quoteContainer}
    item
    lg={5}
  >
    <div className={classes.quote}>
      <div className={classes.quoteInner}>
        <Typography
          className={classes.quoteText}
          variant="h1"
        >
          Automating your solar experience
        </Typography>
        <div className={classes.person}>
          <Typography
            className={classes.name}
            variant="body1"
          >
            Next Gen Solar
          </Typography>
          <Typography
            className={classes.bio}
            variant="body2"
          >
            Improving your solar park
          </Typography>
        </div>
      </div>
    </div>
  </Grid>

};

SidePlaneGridItem.propTypes = {
  // className: PropTypes.string,
  // onClose: PropTypes.func,
  // open: PropTypes.bool.isRequired,
  // variant: PropTypes.string.isRequired
};

export default SidePlaneGridItem;

