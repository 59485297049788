import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  Link
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReactFlagsSelect from 'react-flags-select';
import Translations from '../../helpers/translations';

import withWidth from '@material-ui/core/withWidth';
import {DataService} from '../../App';


const schema = {
  mfaToken: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 6
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  nameGrid: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
}));

const MfaSetupMobile = props => {
  const { history } = props;

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);



  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBack = () => {
    history.goBack();
  };



  const handleSignUpSubmit = event => {
    event.preventDefault();
    handleSignUp();
  };



  const handleSignUp = () => {

    if (!formState.reCaptcha.token){
      let errorText = '';
      errorText = 'Captcha not verified';

      // incorrect password or user disabled
      let errors = {
        passwordConfirm: [errorText]
      };
      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {},
        reCaptcha: {
          ...formState.reCaptcha,
          forceReset: !formState.reCaptcha.forceReset
        },
      }));
      return;
    }
    dataProvider.User().MFASetupMobile(
      formState.values['mobile'],
      formState.reCaptcha.token,
      () => {
        //history.push('/');
        //show
      },
      (error) => {
        let errorText = '';
        if (error != null){

          if (errorText === ''){
            errorText = Translations.Errors.ServerUnavailable;
          }
          // incorrect password or user disabled
          let errors = {
            password: [errorText]
          };
          setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
            reCaptcha: {
              ...formState.reCaptcha,
              forceReset: !formState.reCaptcha.forceReset
            },
          }));

        }

      }
    );
    //history.push('/');
  };
  const [selected, setSelected] = useState('AU');


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>

      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                Automating your solar experience
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="body1"
                >
                  Next Gen Solar
                </Typography>
                <Typography
                  className={classes.bio}
                  variant="body2"
                >
                  Improving your solar park
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <form
              className={classes.form}
              onSubmit={handleSignUpSubmit}
            >
              <Typography
                className={classes.title}
                variant="h2"
              >
                Setup Mobile
              </Typography>

              <Grid container spacing={1}>
                <Grid
                  item
                  lg={2}
                >
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={code => setSelected(code)}
                    countries={['AU','US', 'GB', 'FR', 'DE', 'IT', 'NG']}
                    placeholder=" "
                    customLabels={{
                      'US': { secondary: '+1' },
                      'GB': {  secondary: '+44' },
                      'AU': { secondary: '+61' }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={8}
                >
                  <TextField
                    className={classes.textField}
                    error={hasError('mobile')}
                    fullWidth
                    helperText={
                      hasError('mobile') ? formState.errors.email[0] : null
                    }
                    label="Mobile Number"
                    name="mobile"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                <Link
                  component={RouterLink}
                  to="/"
                >
                  Skip for now
                </Link>
              </Typography>
            </form>

          </div>
        </Grid>
      </Grid>
    </div>
  );
};

MfaSetupMobile.propTypes = {
  history: PropTypes.object
};



export default withWidth()(withRouter(MfaSetupMobile));
