import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import validate from 'validate.js';
import { green} from '@material-ui/core/colors';
import { ApiAccessDetails} from './components'
import {
  Grid,
} from '@material-ui/core';
import {UserApiToken} from '../../data/UserApiTokenService/UserApiTokenService';
import {DataService} from '../../App';

const schema = {
  restrict_to_ip: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  is_active: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },

};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));


export default function ApiAccessEdit(props) {
  const classes = useStyles();
  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [userApiToken, setUserApiToken] = useState({

  });
  useEffect(() => {
    //load User Api Token
    let userApiTokenId = props.match.params.id;
    if (userApiTokenId != null) {
      dataProvider.UserApiToken().get(userApiTokenId).then(results => {
        let token = new UserApiToken(results.api_token);
        setUserApiToken(token)
      })
    }
  }, [props.match.params.id, dataProvider]);





  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);




  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <ApiAccessDetails userApiToken={userApiToken}/>

        </Grid>
      </Grid>
    </div>
  );
}
