import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import {green} from '@material-ui/core/colors';
import {DataService} from "../../App";
import translations from "../../helpers/translations";
import {Alert} from "@material-ui/lab";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";


const schema = {
  claim_key: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  location: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },

};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  buttonSuccess: {
    height: '20%',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },


  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: green[500],
    },
    '& $active': {
      color: theme.palette.primary,
    },
    '& $disabled': {
      color: "red"
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: green[500] + ' !important'
  }
}));

function getSteps() {
  return ['Enter registration key', 'Add location', 'Confirm Claim'];
}



export default function SolarStringClaim(props) {
  const classes = useStyles();
  const dataProvider = React.useContext(DataService);

  const [activeStep, setActiveStep] = React.useState(0);
  const [claimsError, setClaimsError] = React.useState('');
  const [validatedClaimKey, setValidatedClaimKey] = React.useState(false);
  //const nextButton = React.useRef();
  // const  [,setState]=useState(); // used for force rendering on validation

  const getStepContent = (step) => {

    switch (step) {
      case 0:
        return <>
          <TextField
            className={classes.textField}
            error={hasError('claim_key')}
            helperText={
              hasError('claim_key') ? formState.errors.claim_key[0] : null
            }
            InputProps={{
              classes: (validatedClaimKey) ? {
                notchedOutline: classes.notchedOutline
              } : {}
            }}
            label="Claim key"
            name="claim_key"
            onChange={handleChange}
            onKeyPress={handleKeypress}
            type="text"
            value={formState.values.claim_key || ''}
            variant="outlined"
          />
          <Typography>Add the claim key that came with the original packaging </Typography>

        </>;
      case 1:
        return <>
          {/*<TextField*/}
          {/*  className={classes.textField}*/}
          {/*  error={hasError('group')}*/}
          {/*  fullWidth*/}
          {/*  helperText={*/}
          {/*    hasError('group') ? formState.errors.group[0] : null*/}
          {/*  }*/}
          {/*  label="Group"*/}
          {/*  name="group"*/}
          {/*  onChange={handleChange}*/}
          {/*  onKeyPress={handleKeypress}*/}
          {/*  type="text"*/}
          {/*  value={formState.values.group || ''}*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
          <TextField
            className={classes.textField}
            error={hasError('location')}
            fullWidth
            helperText={
              hasError('location') ? formState.errors.location[0] : null
            }
            label="Location"
            name="location"
            onChange={handleChange}
            onKeyPress={handleKeypress}
            type="text"
            value={formState.values.location || ''}
            variant="outlined"
          />
          <Typography>Location should be distinctive, and will appear in reports. e.g. "Western String 25", "Carpark 2", or "Gary's Home Roof"</Typography>
        </>;
      case 2:
        return <Table>
          {/*<TableHead>*/}
          {/*  <TableRow>*/}
          {/*    <TableCell>Claim Key</TableCell>*/}
          {/*    <TableCell>Location</TableCell>*/}
          {/*  </TableRow>*/}
          {/*</TableHead>*/}
          <TableBody>
            <TableRow
              className={classes.tableRow}
              hover
            >
              <TableCell>Claim key</TableCell>
              <TableCell>{formState.values.claim_key || ''}</TableCell>
            </TableRow>
            <TableRow
              className={classes.tableRow}
              hover
            >
              <TableCell>Location</TableCell>
              <TableCell>{formState.values.location || ''}</TableCell>
            </TableRow>
          </TableBody>
        </Table> ;
      default:
        return <Typography>Unknown step</Typography>;
    }
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;



  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const validateStep = (step) => {
    setClaimsError('');
    return new Promise( (resolve, reject) => {
      let schemaStub = {};
      switch (step) {
        case 0:
          schemaStub.claim_key = schema.claim_key;
          break;
        case 1:
          schemaStub.location  = schema.location;
          break;
        default:
          break;
      }
      const errors = validate(formState.values, schemaStub);

      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));

      if (errors) {
        if (errors.hasOwnProperty('claim_key')){
          reject(new Error(errors.claim_key[0]));
          return;
        }
        if (errors.hasOwnProperty('location')){
          reject(new Error(errors.location[0]));
          return;
        }
        reject(new Error(errors));
        return;
      }

      switch (step) {
        case 0:
          dataProvider.DeviceRegistration()
            .validate(formState.values.claim_key)
            .then(isValid => {
              if (!isValid){
                reject(new Error(translations.Errors.ClaimKeyInvalid));
                return;
              }
              resolve();
            })
            .catch(err => {
              reject(err);
            });
          break;
        case 2:
          dataProvider.DeviceRegistration()
            .claim(formState.values.claim_key)
            .then(isValid => {
              if (!isValid){
                reject(new Error(translations.Errors.ClaimKeyInvalid));
                return;
              }
              props.history.push('/solar-string/list');
              resolve();
            })
            .catch(err => {
              reject(err);
            });
          break;
        default:
          resolve();
      }
    });


  };


  const handleKeypress = event => {
    if (event.charCode === 13 ) {
      handleNext();
    }
  };

  const handleChange = event => {
    event.persist();
    if (event.target.name === 'claim_key') {
      setValidatedClaimKey(false);
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const steps = getSteps();
  //
  // const startValidateClaimKey = () => {
  //  //setValidationLoading(true)
  //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };
  // const finishValidateClaimKey = (result) => {
  //
  //    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };
  //




  const handleNext = () => {
    validateStep(activeStep)
      .then(() => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setValidatedClaimKey(true);
      })
      .catch((err) => {
        setValidatedClaimKey(false);
        setClaimsError(err.message);
        // show validation errors
      });
  };

  const handleBack = () => {

    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            classes={{
              root: classes.step,
              completed: classes.completed,
            }}
          > {/*, MuiStepIcon-completed: green}}> */}
            <StepLabel
              classes={{
                alternativeLabel: classes.alternativeLabel,
                labelContainer: classes.labelContainer
              }}
              StepIconProps={{
                classes: {
                  root: classes.step,
                  completed: classes.completed,
                }
              }}
            >{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
              {(claimsError !== '') ?
                <Alert
                  variant="outlined"
                  severity="error"
                  style={{marginTop: '1rem'}}
                >{claimsError}</Alert>
                : <></>
              }
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    color="primary"
                    // ref={nextButton}
                    className={classes.button}
                    // endIcon={<SendIcon />}
                    // pending={pending}
                    // pendingPosition="end"
                    variant="contained"
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}
