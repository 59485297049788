import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel, Checkbox
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';




import { fetchIcon, InsightDeviceType} from '../../../../types/insight';

import { StatusBullet } from 'components';
import {config} from '../../../../config';



const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  // inner: {
  //   minWidth: 800
  // },
  inner: {
    minWidth: 800,
    minHeight: 'auto',
    [theme.breakpoints.up('lg')]: {
      minHeight: '400px',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const statusColors = {
  Info: 'primary',
  Warning: 'warning',
  Critical: 'danger'
};


const unique = (value, index, self) => {
  return self.indexOf(value) === index
};

const fetchAffectedDevicesText = (affectedDevices) => {



  let outputText = '';
  let deviceIds = [];
  for (let affectedDeviceIndex in affectedDevices){
    if (!affectedDevices.hasOwnProperty(affectedDeviceIndex))  continue;
    let affectedDevice = affectedDevices[affectedDeviceIndex];
    if (!affectedDevice.hasOwnProperty('device_id') ||
      !affectedDevice.hasOwnProperty('device_type')
    ){
      //corrupt data
      console.error('Affected Device is corrupt: ' + JSON.stringify(affectedDevice));
      continue;
    }
    switch(affectedDevice.device_type){
      case InsightDeviceType.CQSolaController:
        let ids = affectedDevice.device_id.split(':');
        if (ids.length < 2){
          console.error('Affected Device is corrupt: ' + JSON.stringify(affectedDevice));
          continue;
        }
        deviceIds.push(ids[1]);

        break;
      case InsightDeviceType.CQSolaCommsUnit:
      default:
        deviceIds.push(affectedDevice.device_id);
        break;
    }
  }

  outputText = deviceIds.filter(unique).join(',');
  if (outputText.length > 32){
    outputText = outputText.substring(0, 32) + '...';
  }

  return outputText;
};


const deleteUserInsight = (insightId) => {
  alert('deleted');
};
const editUserInsight = (insightId) => {
  alert('edit mode');
};





const LatestInsights = props => {
  const { className, installId, from, to, ...rest } = props;

  const classes = useStyles();

  //const [insights] = useState(insightMockData);
  const [insights, setInsights] = useState([]);

  const [selectedInsights, setSelectedInsights] = useState([]);
  //const [page, setPage] = useState(0);
  const page = 0;

  const handleSelectAll = event => {
    const { insights } = props;

    let selectedInsights;

    if (event.target.checked && insights != null) {
      selectedInsights = insights.map(user => user.id);
    } else {
      selectedInsights = [];
    }

    setSelectedInsights(selectedInsights);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedInsights.indexOf(id);
    let newSelectedInsights = [];

    if (selectedIndex === -1) {
      newSelectedInsights = newSelectedInsights.concat(selectedInsights, id);
    } else if (selectedIndex === 0) {
      newSelectedInsights = newSelectedInsights.concat(selectedInsights.slice(1));
    } else if (selectedIndex === selectedInsights.length - 1) {
      newSelectedInsights = newSelectedInsights.concat(selectedInsights.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedInsights = newSelectedInsights.concat(
        selectedInsights.slice(0, selectedIndex),
        selectedInsights.slice(selectedIndex + 1)
      );
    }

    setSelectedInsights(newSelectedInsights);
  };

  // const handlePageChange = (event, page) => {
  //   setPage(page);
  // };
  //
  // const handleRowsPerPageChange = event => {
  //   setRowsPerPage(event.target.value);
  // };

  useEffect(() => {



    let params = {
      'filter[install_id]': installId,
      'filter[from]': moment(from).format(),
      'filter[to]': moment(to).format(),
      //"filter[optimiser_ids]": query.filters.optimiserIds,
      //"mode": query.mode,
      //"limit": queryLimit
    };

    let queryString = Object.keys(params).map(function(key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');


    let dataUrl = config.cqsolaDataApi + '/insight/list?' + queryString;
    fetch(dataUrl, {
      method: 'get',
      credentials: 'include'
    })
      .then(function(res) {
        return res.json();
      })
      .then(function(insightResponse) {
      //let insightResponse = JSON.parse(_insightResponse);
        if (Object.prototype.hasOwnProperty.call(insightResponse,'error') &&
          insightResponse.error != null
        ){
          console.error(insightResponse.error);
        }
        else {
          setInsights((Array.isArray(insightResponse))? insightResponse : []);
        }
        //callback({isLoaded: true, query: query, data: resBlob,  cacheResult: cacheResults.pass});
        //let dateFormat = 'YYYY-MM-DD-hhmmss';
        //let filename = "cqsola-" + query.filters.installId + "-" + query.dataType +"-" + query.filters.from.format(dateFormat) + "-" + query.filters.to.format(dateFormat) + ".csv";
        //download(resBlob, filename, "text/csv" );
        //setInsights

      });
  }, [installId, page, from, to]);
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            color="primary"
            size="small"
            variant="outlined"
          >
            Add Manual Entry
          </Button>
        }
        title="Insights"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedInsights.length === insights.length}
                      color="primary"
                      indeterminate={
                        selectedInsights.length > 0 &&
                        selectedInsights.length < insights.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell />
                  <TableCell sortDirection="desc">
                    <Tooltip
                      enterDelay={300}
                      title="Sort"
                    >
                      <TableSortLabel
                        active
                        direction="desc"
                      >
                        Detected At
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>Devices Affected</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                { (insights.length < 1 )?
                  <TableRow>
                    <TableCell
                      align={'center'}
                      colSpan={7}
                      valign={'middle'}
                    >
                      <Typography varient="h1">No Insights Found</Typography>
                    </TableCell>
                  </TableRow> : <></> }
                {insights.map(insight => (
                  <TableRow
                    hover
                    key={insight.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedInsights.indexOf(insight.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, insight.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.statusContainer}>
                        {fetchIcon(insight.type)}
                      </div>
                    </TableCell>
                    <TableCell>
                      {moment(insight.detected_at).format('MMMM Do YYYY, h:mm:ss a')}
                    </TableCell>
                    <TableCell>
                      {fetchAffectedDevicesText(insight.affected_devices)}
                    </TableCell>
                    <TableCell>
                      <div className={classes.statusContainer}>
                        <StatusBullet
                          className={classes.severity}
                          color={statusColors[insight.severity]}
                          size="sm"
                        />
                        {insight.status}
                      </div>
                    </TableCell>
                    <TableCell>{insight.message}</TableCell>
                    <TableCell>{ (insight.is_user_defined)?
                      <>
                        <Button onClick={() => {editUserInsight(insight.id)}}>
                          <EditIcon />
                        </Button>
                        <Button onClick={() => {deleteUserInsight(insight.id)}}>
                          <DeleteIcon />
                        </Button>
                      </>: <></> }</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestInsights.propTypes = {
  className: PropTypes.string
};

export default LatestInsights;
