import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import FlashOffIcon from '@material-ui/icons/FlashOff';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import BuildIcon from '@material-ui/icons/Build';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import React from "react";
import uuid from "uuid/v1";

export const InsightType = {
  DirtyThresholdExceeded:   "DirtyThresholdExceeded",
  DegradeThresholdExceeded: "DegradeThresholdExceeded",
  TempHighReached:          "TempHighReached",
  StringTurnedOff:          "StringTurnedOff",
  StringTurnedOn:           "StringTurnedOn",
  TripDetected:             "TripDetected",
  FailedOptimiser:          "FailedOptimiser",
  BrokenCableDetected:      "BrokenCableDetected",
  CurrentLimitActivated:    "CurrentLimitActivated"
};

export const SeverityType = {
  Info:     "Info",
  Warning:  "Warning",
  Critical: "Critical"
};

export const InsightInputType = {
  AiGenerated:     "Ai",
  UserGenerated:   "User",
  DeviceGenerated: "Device"
};


export const InsightDeviceType = {
  CQSolaController:     "Controller",
  CQSolaCommsUnit:     "CommsUnit",
  Battery:   "Battery",
  Inverter: "Inverter"
};

export const InsightDefaultValues = {
  id: "",
  affected_devices: [],
  affected_solar_strings_count: 0,
  affected_controllers_count: 0,
  detected_at: new Date(Date.now()),
  generated_at: new Date(Date.now()),
  detected_from: new Date(Date.now()),
  detected_to: new Date(Date.now()),
  comparison_from: null,
  comparison_to: null,
  certainty: 100,
  severity: SeverityType.Info,
  type: InsightType.StringTurnedOff,
  message: '',
  generated_by: InsightInputType.DeviceGenerated,
  extra_data: {}
};


  export const fetchIcon = (insightType) => {

  switch(insightType) {
    case InsightType.DirtyThresholdExceeded:
      return <TrendingDownIcon/>;
    case InsightType.DegradeThresholdExceeded:
      return <BuildIcon/>;
    case InsightType.TempHighReached:
      return <WhatshotIcon/>;
    case InsightType.StringTurnedOff:
    case InsightType.StringTurnedOn:
      return <PowerSettingsNewIcon/>;
    case InsightType.TripDetected:
      return <FlashOffIcon/>;
    case InsightType.FailedOptimiser:
    case InsightType.BrokenCableDetected:
      return <BuildIcon/>;
    case InsightType.CurrentLimitActivated:
      return <TrendingDownIcon/>;
    default:
      return <ArrowRightIcon/>;
  }
};

export const fetchDescription = (insightType) => {

  switch(insightType) {
    case InsightType.DirtyThresholdExceeded:
      return "Dirty Panel";
    case InsightType.DegradeThresholdExceeded:
      return "Degraded Performance";
    case InsightType.TempHighReached:
      return "Thermal Limiting";
    case InsightType.StringTurnedOff:
      return "String Turned Off";
    case InsightType.StringTurnedOn:
      return "String Turned On";
    case InsightType.TripDetected:
      return "Trip Detected";
    case InsightType.FailedOptimiser:
      return "Failed Controller";
    case InsightType.BrokenCableDetected:
      return "Broken Cable";
    case InsightType.CurrentLimitActivated:
      return "Current Limiting";
    default:
      return "Insight"
  }
};
//	ID             string       `json:"id"`
// 	InstallIDs     []string     `json:"install_ids"`
// 	OptimiserIDs   []string     `json:"optimiser_ids"`
// 	GeneratedAt    time.Time    `json:"generated_at"`
// 	DetectedAt     time.Time    `json:"detected_at"`
// 	DetectedFrom   time.Time    `json:"detected_from"`
// 	DetectedTo     time.Time    `json:"detected_to"`
// 	ComparisonFrom time.Time    `json:"comparison_from"`
// 	ComparisonTo   time.Time    `json:"comparison_to"`
// 	Certainty      uint         `json:"certainty"`
// 	Severity       SeverityType `json:"severity"`
// 	Type           InsightType  `json:"type"`
// 	Message        string       `json:"message"`
// 	IsUsedDefined  bool         `json:"is_user_defined"`
// 	UserID		   string		`json:"user_id"`

export const insightMockData = [
  {
    id: "cd14c781-10dd-11eb-8cdb-8d6f024085c7",
    affected_devices: [
      {
        device_type: "CommsUnit",
        device_id: "BRI857",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10000",
        location: {
          lat: -27.680993132085383,
          lng: 153.18129607484448
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10001",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10002",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10003",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10004",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      }
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 15,
    detected_at:  1591250400,
    generated_at:  1591250400,
    detected_from: 1591250400 - (60*60),
    detected_to: 1591250400 + (60*60),
    comparison_from: null,
    comparison_to: null,
    certainty: 100,
    severity: SeverityType.Info,
    type:     InsightType.StringTurnedOff,
    message: 'Manual String Powered Off - Emergency Stop Activated',
    generated_by: InsightInputType.DeviceGenerated,
    extra_data: {
      peak: 100,
      threshold: 80,
      measured:  60,
    }


  },
  {
    id: "fbf24eb0-10dd-11eb-91d6-3327eb9b81a9",
    affected_devices:  [
      {
        device_type: "CommsUnit",
        device_id: "BRI857",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "CommsUnit",
        device_id: "BRI854",
        location: {
          lat: -27.680993132085383,
          lng: 153.18129607484448
        }
      },
    ],
    affected_controllers_count: 15,
    detected_at: Date.now() ,
    generated_at: Date.now(),
    certainty: 100,
    severity: SeverityType.Critical,
    type:     InsightType.TripDetected,
    message: 'Overvoltage Trip - Device Restarted',
    generated_by: InsightInputType.DeviceGenerated,
    extra_data: {
      peak: 100,
      threshold: 80,
      measured:  60,
    }
  },
  {
    id: uuid(),
    affected_devices: [
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10000",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10001",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10002",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10003",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10004",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10005",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10006",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10007",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10008",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10009",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10010",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10011",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10012",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10013",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10014",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      }
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 15,
    detected_at: Date.now(),
    generated_at: Date.now(),
    certainty: 100,
    severity: SeverityType.Info,
    type:     InsightType.StringTurnedOn,
    message: 'Manual String Powered On - Emergency Stop Deactivated',
    generated_by: InsightInputType.DeviceGenerated,
    extra_data: {
      peak: 100,
      threshold: 80,
      measured:  60,
    }

  },
  {
    id: uuid(),
    affected_devices: [
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10001",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      }
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 1,
    detected_at: Date.now(),
    generated_at: Date.now(),
    certainty: 75,
    severity: SeverityType.Warning,
    type:     InsightType.DirtyThresholdExceeded,
    message: 'Dirty Panel Detected - Cleaning Required',
    generated_by: InsightInputType.AiGenerated,
    extra_data: {
      peak: 100,
      threshold: 80,
      measured:  60,
    }
  },
  {
    id: uuid(),
    affected_devices: [
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10009",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      }
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 1,
    detected_at: Date.now(),
    generated_at: Date.now(),
    certainty: 100,
    severity: SeverityType.Critical,
    type:     InsightType.DegradeThresholdExceeded,
    message: 'Panel Degradation Detected - Replacement Required',
    generated_by: InsightInputType.AiGenerated,
    extra_data: {
      peak: 100,
      threshold: 80,
      measured:  60,
    }
  },
  {
    id: uuid(),
    affected_devices: [
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10008",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10009",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      }
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 2,
    detected_at: Date.now(),
    generated_at: Date.now(),
    certainty: 100,
    severity: SeverityType.Critical,
    type:     InsightType.BrokenCableDetected,
    message: 'Broken Cable Detected - Replacement Required',
    generated_by: InsightInputType.DeviceGenerated,
    extra_data: {
      peak: 100,
      threshold: 80,
      measured:  10,
    }
  },
  {
    id: uuid(),
    affected_devices: [
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10007",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      }
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 1,
    detected_at: Date.now(),
    generated_at: Date.now(),
    certainty: 100,
    severity: SeverityType.Critical,
    type:     InsightType.FailedOptimiser,
    message: 'Power Controller Hardware Failure Detected - Replacement Required',
    generated_by: InsightInputType.DeviceGenerated,
    extra_data: {
      peak: 100,
      threshold: 80,
      measured:  10,
    }
  },
  {
    id: uuid(),
    affected_devices: [
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10001",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10002",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10005",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10006",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 4,
    detected_at: Date.now(),
    generated_at: Date.now(),
    certainty: 100,
    severity: SeverityType.Info,
    type:     InsightType.TempHighReached,
    message: 'Power Limited due to heat',
    generated_by: InsightInputType.DeviceGenerated,
    extra_data: {
      threshold: 80,
      measured:  85,
    }
  },
  {
    "id":"",
    "affected_devices":null,
    "generated_at":"0001-01-01T00:00:00Z",
    "detected_at":"0001-01-01T00:00:00Z",
    "detected_from":"0001-01-01T00:00:00Z",
    "detected_to":"0001-01-01T00:00:00Z",
    "comparison_from":"0001-01-01T00:00:00Z",
    "comparison_to":"0001-01-01T00:00:00Z",
    "certainty":0,
    "severity":"",
    "type":"",
    "message":"",
    "generated_by":"",
    "user_id":""
  },
  {
    id: uuid(),
    affected_devices: [
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10001",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10002",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10005",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
      {
        device_type: "Controller",
        device_id: "BRI857:OSA10006",
        location: {
          lat: -27.681054887908367,
          lng: 153.18128869823593
        }
      },
    ],
    affected_solar_strings_count: 1,
    affected_controllers_count: 4,
    detected_at: Date.now(),
    generated_at: Date.now(),
    certainty: 100,
    severity: SeverityType.Info,
    type:     "Manual",
    message: 'Manual Entry - Cleaned Panels',
    generated_by: InsightInputType.UserGenerated,
    extra_data: {}

  },
];
