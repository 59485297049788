import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from '../../../../components/ConfirmDialog';
import {DataService} from '../../../../App';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },

  createdAtCell: {
    width: theme.spacing(2)
  },
  actionCell: {
    width: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className,
    users,
    userTotal,
    setUserTotal,
    selectedUsers,
    setSelectedUsers,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    currentUserEmail,
    isCurrentUserAccountOwner,

    ...rest } = props;

  const dataProvider = React.useContext(DataService);
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  // const handleSelectAll = event => {
  //   const { users } = props;
  //
  //   let selectedUsers;
  //
  //   if (event.target.checked) {
  //     selectedUsers = users.map(user => user.id);
  //   } else {
  //     selectedUsers = [];
  //   }
  //
  //   setSelectedUsers(selectedUsers);
  // };

  // eslint-disable-next-line
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  //
  // const isActionOpen = userId => {
  //
  //   return openContexts.hasOwnProperty(userId) && openContexts[userId] ;
  // }
  const removeUserFromAccount = userId => {
    dataProvider.Account().removeUserFromAccount(userId).then( () => {
      setUserTotal(userTotal - 1)
    }).catch(() => {})
  };

  // const handleActionClick = event => {
  //   setActionAnchorElement(event.currentTarget);
  //   setIsActionOpen(!isActionOpen)
  //   //setOpenContexts({...openContexts, [user.id]: !isActionOpen(user.id) });
  //   //this.setState({ anchorEl: event.currentTarget });
  // };

  // const handleActionClose = () => {
  //   setActionAnchorElement(null);
  //   setIsActionOpen(!isActionOpen)
  // };

  const getRole = user => {
    return (isCurrentUserAccountOwner && user.email === currentUserEmail)? "Account Owner": "Administator";
  }

  const canRemoveUser = user => {
    return isCurrentUserAccountOwner && user.email !== currentUserEmail;
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox">*/}
                  {/*  <Checkbox*/}
                  {/*    checked={selectedUsers.length === users.length}*/}
                  {/*    color="primary"*/}
                  {/*    indeterminate={*/}
                  {/*      selectedUsers.length > 0 &&*/}
                  {/*      selectedUsers.length < users.length*/}
                  {/*    }*/}
                  {/*    onChange={handleSelectAll}*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  {/*<TableCell>Is Locked</TableCell>*/}
                  {/*<TableCell>Registration date</TableCell>*/}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/*users.slice( (page) * rowsPerPage, (page+1) * rowsPerPage)*/}
                {users.map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    // key={user.id}
                    // selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                  {/*  <TableCell padding="checkbox">*/}
                  {/*    <Checkbox*/}
                  {/*      checked={selectedUsers.indexOf(user.id) !== -1}*/}
                  {/*      color="primary"*/}
                  {/*      onChange={event => handleSelectOne(event, user.id)}*/}
                  {/*      value="true"*/}
                  {/*    />*/}
                  {/*  </TableCell>*/}
                    <TableCell>
                      <div className={classes.nameContainer}>
                        {/*<Avatar*/}
                        {/*  className={classes.avatar}*/}
                        {/*  src={user.avatarUrl}*/}
                        {/*>*/}
                        {/*  {getInitials(user.name)}*/}
                        {/*</Avatar>*/}
                        <Typography variant="body1">{user.first_name} {user.last_name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{getRole(user)}</TableCell>
                    {/*<TableCell>{(user.is_active)? 'True': 'False'}</TableCell>*/}
                    {/*<TableCell>{(user.is_locked)? 'True': 'False'}</TableCell>*/}
                    {/*<TableCell className={classes.createdAtCell}>*/}
                    {/*  {moment(user.created_at).format('DD/MM/YYYY')}*/}
                    {/*</TableCell>*/}
                    <TableCell
                      // style={{ justifyContent: 'flex-start'}}
                    >
                      {canRemoveUser(user)?
                        <>
                          <IconButton aria-label="delete" onClick={() =>     setConfirmOpen(true)}>
                            <DeleteIcon />
                          </IconButton>
                          <ConfirmDialog
                            title="Remove User from Account"
                            open={confirmOpen}
                            setOpen={setConfirmOpen}
                            onConfirm={() => {removeUserFromAccount(user.email) }}
                          >
                            <Typography
                              variant="body1"
                            >
                              Are you sure you want to remove user from account?
                            </Typography>
                          </ConfirmDialog>
                        </>:
                        <></> }


                      {/*<IconButton*/}
                      {/*  // aria-label="More"*/}
                      {/*  // aria-haspopup="true"*/}
                      {/*  aria-owns={isActionOpen ? 'long-menu' : undefined}*/}

                      {/*  onClick={handleActionClick}*/}
                      {/*>*/}
                      {/*  <MoreHorizIcon />*/}
                      {/*</IconButton>*/}
                      {/*<Menu*/}
                      {/*  anchorEl={actionAnchorElement}*/}
                      {/*  id="long-menu"*/}
                      {/*  open={isActionOpen}*/}
                      {/*  onClose={handleActionClose}*/}
                      {/*  // PaperProps={{*/}
                      {/*  //   style: {*/}
                      {/*  //     maxHeight: 25 * 4.5,*/}
                      {/*  //     width: 200,*/}
                      {/*  //   },*/}
                      {/*  // }}*/}
                      {/*>*/}
                      {/*  /!*<MenuItem key="action_user_disable" onClick={handleActionClose}>*!/*/}
                      {/*  /!*  Disable User*!/*/}
                      {/*  /!*</MenuItem>*!/*/}
                      {/*  <MenuItem key="action_user_remove" onClick={handleActionClose}>*/}
                      {/*    Remove User from account*/}
                      {/*  </MenuItem>*/}
                      {/*  /!*<MenuItem key="action_user_unlock" onClick={handleActionClose}>*!/*/}
                      {/*  /!*  Unlock User*!/*/}
                      {/*  /!*</MenuItem>*!/*/}
                      {/*</Menu>*/}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={userTotal}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string
};

export default UsersTable;
