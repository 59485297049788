import {config} from '"../../config';
import Translations from '../../helpers/translations';

class UserApiToken  {

  id: '';
  name: '';
  restrict_to_ip: '';
  is_active: '';
  notes: '';
  updated_at: '';
  created_at: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

class UserApiTokenList {
  data: Array<UserApiToken>;
  meta: {
    total: 0,
    page:  0,
    page_size: 0
  };
}

const apiUrl = config.cqsolaDataApi;
export default class UserApiTokenService {

  constructor(config) {
    if (config == null){
      config = {};
    }
    this.config = config;
  }


  get(userApiTokenId): Promise<UserApiToken>  {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: userApiTokenId,
        })
      };
      let dataUrl = `${apiUrl}/user/api_token/get`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then((response) => {
          return response.json()
        })
        .then(result => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  save(userApiToken, currentPassword) {
    // remove user from local storage to log user out
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          api_token: userApiToken,
          current_password: currentPassword
        })
      };
      let dataUrl = `${apiUrl}/user/api_token/save`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then((response) => {
          return response.json()
        })
        .then(result => {
          if (Object.prototype.hasOwnProperty.call(result, 'error') &&
            result.error != null
          ){
            console.error(result.error);
            reject(result.error);
            return;
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  list(query): Promise<UserApiTokenList> {

    let service = this;
    let dataUrl = `${apiUrl}/user/api_token/list`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch api tokens');
          }
        })
        .then(function (response) {
          // let expectedResponseFields = [
          //   'error',
          //   'meta',
          //   'comms_units'
          // ];
          //
          // let isValidResponse = !expectedResponseFields.map((field) => {
          //   return Object.prototype.hasOwnProperty.call(response, field)
          // }).includes(false);
          //
          // if (!isValidResponse){
          //   reject(Translations.Errors.UnExpectedResponse);
          // }
          if (response.hasOwnProperty('error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            //validate response
            if (!Array.isArray(response.api_tokens)){
              reject(new Error(Translations.Errors.UnExpectedResponse));
            }
            let output = new UserApiTokenList();
            output.data = [];
            for (let i =0; i < response.api_tokens.length; i++){
              output.data[i] =new UserApiToken(response.api_tokens[i])
            }
            output.meta = response.meta;
            resolve(output);
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });


  }

  delete(userApiTokenId) {

    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: userApiTokenId,
        })
      };
      let dataUrl = `${apiUrl}/user/api_token/delete`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then((response) => {
          return response.json()
        })
        .then(result => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }




}

export {UserApiTokenService, UserApiToken, UserApiTokenList};
