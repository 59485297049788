import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {  Typography } from '@material-ui/core';
import {ReportTable, ReportToolbar} from './components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }
}));


function createCleaningData(insightId, controllerId, location, group, startedAt, finishedAt) {
  return { insightId, controllerId, location, group, startedAt, finishedAt };
}

const shadingRows = [
  createCleaningData('1b','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createCleaningData('1a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createCleaningData('2a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createCleaningData('3a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createCleaningData('4a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
  createCleaningData('5a','OSA10001', 'West Quarter', 'Testing Group', Date.now(), Date.now()),
];


const ReportCleaning = () => {
  const classes = useStyles();
  let reports = [];
  return (
    <div className={classes.root}>

      {/*<Grid*/}
      {/*  container*/}
      {/*  spacing={4}*/}
      {/*>*/}

      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalShadedPanels />*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalDamagedPanels />*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalDamagedCables />*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    lg={4}*/}
      {/*    md={4}*/}
      {/*    xl={4}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <TotalDamagedControllers />*/}
      {/*  </Grid>*/}

      {/*</Grid>*/}
      <Typography
        variant="body1"

      >
        Filters
      </Typography>
      <PrintIcon />

      <Typography
        className={classes.title}
        gutterBottom
        variant="h2"
      >
        Cleaning Report
      </Typography>
      <Typography
        className={classes.title}
        color="textSecondary"
        variant="body1"
      >
        11:48pm 28/12/2020
      </Typography>

      <Paper>
        <Typography
          className={classes.title}
          gutterBottom
          variant="h4"
        >
          Cleaning Needed
        </Typography>
        <TableContainer>
          <Table
            aria-label="a dense table"
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>

                <TableCell>Controller Id</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Group</TableCell>
                <TableCell align="right">Shading Started At</TableCell>
                <TableCell align="right">Shading Finished At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shadingRows.map((row) => (
                //controllerId, location, group, startedAt, finishedAt
                <TableRow key={row.insightId}>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {row.controllerId}
                  </TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.group}</TableCell>
                  <TableCell align="right">{moment(row.startedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                  <TableCell align="right">{moment(row.finishedAt).format('D/M/Y hh:mm:ss A')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ReportToolbar />
      <div className={classes.content}>
        <ReportTable insights={reports} />
      </div>
    </div>
  );
};

export default ReportCleaning;
