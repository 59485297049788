import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import ReactFlagsSelect from 'react-flags-select';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography,
  Backdrop,
  Fade,
  Modal,
  InputLabel,
  Select,
  FormControl,
  Tooltip,
  InputAdornment,
  OutlinedInput
} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Translations from '../../helpers/translations';
import Countries from '../../helpers/countries';

import HelpIcon from '@material-ui/icons/Help';

import googleLibPhoneNumber from 'google-libphonenumber';

import { ReCaptcha } from 'react-recaptcha-v3'
import {config} from '../../config';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {SidePlaneGridItem} from '../../layouts/Main/components';
import {DataService} from '../../App';
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Terms } from './components';
//const termsHtml = require("./terms.html");

validate.validators.mobileNumberValidator = function(value, options, key, attributes) {
  let isValidMobile = false;
  try {
    let countryCode = 'AU';
    if (attributes.hasOwnProperty('country') && attributes.country !== null ){
      countryCode = attributes.country;
    }
    const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
    isValidMobile = phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, countryCode), countryCode);
  }catch{
    isValidMobile = false;
  }
  if (isValidMobile){
    return;
  } else {
    return 'is not a valid mobile number';
  }
};


var schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  mobileNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    mobileNumberValidator: {isValidNumber: true},
  },
  // mobileNumber:  function(value, attributes, attributeName, options, constraints) {
  //
  //   if (typeof value === 'undefined' || value === null || value === ''){
  //     return false;
  //   }
  //   let isValidMobile = false;
  //   let countryCode = attributes['country'];
  //   try {
  //     const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
  //     isValidMobile = phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, countryCode), countryCode);
  //   }
  //   catch{
  //
  //     //invalid phone number
  //     isValidMobile = false;
  //   }
  //   if (isValidMobile) return null;
  //   return {
  //     presence: {allowEmpty: false, message: 'needs to be a mobile number'}
  //   };
  // },
  //{
  //  presence: { allowEmpty: false, message: 'is required' },
  //  length: {
  //    maximum: 64
  //  }
  //},
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  country: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$/,
      flags: 'i',
      message: 'must be at least 9 characters long, and contain 1 upper case, 1 lower case, 1 special characters, and no whitespaces'
    },
    length: {
      minimum: 9,
      maximum: 128
    }
  },
  passwordConfirm: {
    equality: {
      attribute: 'password',
      message: '^Passwords do not match'
    },
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  },

};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grid: {
    height: '100%'
  },
  nameGrid: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0),
    }
  },
  expiredLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  countryDropDownButton: {
    height: theme.spacing(6.75),
    '&::after': {
      display: 'none'
    }
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },


}));



const SignUpBegin = props => {
  const { history } = props;

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const classes = useStyles();

  const [selectedCountry, setSelectedCountry] = useState('AU');

  const [emailSent, setEmailSent] = useState(false);

  let initalFormState = {
    isValid: false,
    values: {
      country: 'AU',
      countryCode: 'AU',
      inviteToken: '',
    },
    touched: {},
    errors: {},

  };
  let inviteTokenPresent = (props.location.pathname.indexOf('invite') !== -1);
  let inviteToken = null;
  if (inviteTokenPresent) {
    schema.inviteToken = {
      presence: { allowEmpty: false },
      format: {
        pattern: /^[a-zA-Z0-9]+$/,
        flags: 'i',
      },
    };

    try {
      inviteToken = props.match.params.invite ?? '';
    } catch (e) {}
  }

  if (inviteTokenPresent && inviteToken !== '') {
    // formState.values = {
    //   ...formState.values,
    //   inviteToken: inviteToken
    // }
    initalFormState.values.inviteToken = inviteToken;
  }
  const [formState, setFormState] = useState(initalFormState);
  const [showCapsLockWarning, setShowCapsLockWarning] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const formValidationErrors = () => {
    let {email, ...schemaTokenPresent} = schema;
    return validate(formState.values, (inviteTokenPresent)? schemaTokenPresent: schema);
  };

  // useEffect(() => {
  //
  //   //below removes email from the schema
  //   const errors = formValidationErrors();
  //   setFormState(formState => ({
  //     ...formState,
  //     isValid: errors ? false : true,
  //     errors: errors || {}
  //   }));
  // }, [formState.values]);
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const onBlur = event => {
    //if currently has an error, check for validation
    if (hasError(event.target.name)){
      const errors = formValidationErrors();
      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
    }
  }
  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  const onKeyDown = keyEvent => {
    if (keyEvent.getModifierState('CapsLock')) {
      setShowCapsLockWarning(true);
    } else {
      setShowCapsLockWarning(false)
    }
  };

  const handleCountryChange = event => {
    setSelectedCountry(event.target.value);
    return handleChange(event)
  }

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const getSpacing = (props) => {
    if (isWidthUp('lg', props.width)) {
      return 2;
    }
    return 0;
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const verifyReCaptchaCallback = (recaptchaToken) => {
    if (isSubmitting) {
      handleSignUp(recaptchaToken);
    }
  };

  useEffect(() => {
    //get captcha and start form submit
    if (isSubmitting) {
      reCaptchaRef.current.execute(); // then goto verifyReCaptchaCallback once we have the recaptcha token
    }
  }, [isSubmitting]);


  const reCaptchaRef = React.useRef();
  const handleSignUpSubmit = event => {
    event.preventDefault();
    let errors = formValidationErrors();
    if (errors){
      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
      return;
    }
    else {
      //this is a very annoying way to handle this for recaptcha, but
      //storing the last recatpcha can result in failure of the recaptcha. Instead
      //we're going to have to use a "useEffect" above with an isSubmitting dependancy
      setIsSubmitting(true);
    }
  };



  const handleSignUp = (reCaptchaToken) => {

    dataProvider.User().signUpBegin(
      formState.values['email'],
      formState.values['firstName'],
      formState.values['lastName'],
      formState.values['country'],
      formState.values['mobileNumber'],
      formState.values['password'],
      formState.values['passwordConfirm'],
      formState.values['policy'],
      formState.values['inviteToken'],
      reCaptchaToken,
    )
      .then( response => {
        if (response != null &&
          Object.prototype.hasOwnProperty.call(response, 'redirect') !== false &&
          response.redirect != null
        ) {
          //goto step 2
          setEmailSent(true);
        }
      })
      .catch( error => {
        let errorText = '';
        if (error != null){
          switch(true){
            case (Object.prototype.hasOwnProperty.call(error, 'user_forbidden') !== false &&
              error.user_forbidden != null
            ):
              errorText = error.user_forbidden;
              break;
            case (Object.prototype.hasOwnProperty.call(error, 'invalid_recaptcha') !== false &&
              error.invalid_recaptcha != null
            ):
              errorText = error.invalid_recaptcha;
              break;
            default:
              break;
          }
          if (errorText === ''){
            errorText = Translations.Errors.ServerUnavailable;
          }
          // incorrect password or user disabled
          let errors = {
            password: [errorText]
          };
          setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
          }));

        }
      }).finally(() => {
        setIsSubmitting(false);
      });
  };



  return (
    <div className={classes.root}>

      <Grid
        className={classes.grid}
        container
      >
        <SidePlaneGridItem />
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={modalOpen}
              onClose={handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={modalOpen}>
                <div className={classes.paper}>
                  <Terms />

                </div>
              </Fade>
            </Modal>
            <div className={classes.contentBody}>

              <form
                className={classes.form}
                onSubmit={handleSignUpSubmit}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Create new account
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Get started with your new solar equipment
                </Typography>
                {emailSent ? (
                  <div>
                    <MailOutlineIcon />
                    <Typography variant='h5' className={classes.instructions}>
                      Check your Email
                    </Typography>
                    <Typography className={classes.instructions}>
                      We've sent through an email with a link to continue the signup process. Please check your junk mail to ensure the link isn't missed.
                    </Typography>
                  </div>
                ) : (
                  <>

                    { (inviteTokenPresent && formState.values.inviteToken !== '' && formState.errors['inviteToken']) ?
                      <span className={classes.expiredLinkContainer} >
                        <NotInterestedIcon />
                        <Typography
                          className={classes.contentBody}
                          style={{paddingLeft:'0.5rem', justifyContent: 'unset'}}
                          variant="h5"
                        >Invite link has expired or is invalid</Typography>
                      </span>
                      : <>
                        <Grid
                          // className={classes.nameGrid}

                          container
                          spacing={ getSpacing(props) }
                        >

                          <Grid
                            item
                            lg={6}
                            xs={12}
                          >
                            <TextField
                              className={classes.textField}
                              error={hasError('firstName')}
                              fullWidth
                              helperText={
                                hasError('firstName') ? formState.errors.firstName[0] : null
                              }
                              label="First name"
                              name="firstName"
                              onChange={handleChange}
                              onKeyDown={onKeyDown}
                              onBlur={onBlur}
                              type="text"
                              value={formState.values.firstName || ''}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            className={classes.content}
                            item
                            lg={6}
                            xs={12}
                          >
                            <TextField
                              className={classes.textField}
                              error={hasError('lastName')}
                              fullWidth
                              helperText={
                                hasError('lastName') ? formState.errors.lastName[0] : null
                              }
                              label="Last name"
                              name="lastName"
                              onChange={handleChange}
                              onKeyDown={onKeyDown}
                              onBlur={onBlur}
                              type="text"
                              value={formState.values.lastName || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        {(!inviteTokenPresent )?
                          <TextField
                            className={classes.textField}
                            error={hasError('email')}
                            fullWidth
                            helperText={
                              hasError('email') ? formState.errors.email[0] : null
                            }
                            label="Email address"
                            name="email"
                            onChange={handleChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            type="text"
                            value={formState.values.email || ''}
                            variant="outlined"
                          />
                          :
                          <></>
                        }

                        <FormControl
                          className={classes.textField}
                          error={hasError('country')}
                          fullWidth
                          helperText={
                            hasError('country') ? formState.errors.country[0] : null
                          }
                          variant="outlined"
                        >
                          <InputLabel id="sign-up-country-label">Country</InputLabel>
                          <Select
                            labelId="sign-up-country-label"
                            native
                            helperText={
                              hasError('country') ? formState.errors.country[0] : null
                            }
                            label="Country"
                            name="country"
                            onChange={handleCountryChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={formState.values.country || ''}
                            variant="outlined"
                          >
                            {Countries.map(country =>
                              <option
                                key={country.Code + '_' + country.SMSPrefix}
                                value={country.Code}>{country.Name}</option>)}
                          </Select>
                        </FormControl>
                        <Grid
                          // className={classes.nameGrid}
                          container
                          spacing={ 2 }
                        >
                          <Grid
                            item
                            lg={3}
                            xs={4}
                          >
                            <ReactFlagsSelect
                              selected={selectedCountry}
                              className={classes.textField}
                              alignOptionsToRight
                              selectButtonClassName={classes.countryDropDownButton}
                              fullWidth={true}
                              error={hasError('countryCode')}
                              helperText={
                                hasError('countryCode') ? formState.errors.countryCode[0] : null
                              }
                              onSelect={(code) => {
                                formState.values['countryCode'] = code;
                                setFormState(formState => ({
                                  ...formState,
                                  values: formState.values
                                }));
                                setSelectedCountry(code);
                              }}
                              countries={Countries.map(item => item.Code)}
                              placeholder=" "
                              customLabels={
                                Countries.reduce((acc, item) => {
                                  acc[item.Code] = {primary: '+' + item.SMSPrefix };
                                  return acc;
                                }, {})
                              }
                            />
                          </Grid>
                          <Grid
                            className={classes.content}
                            item
                            lg={9}
                            xs={8}
                          >
                            <FormControl
                              className={classes.textField}
                              error={hasError('mobileNumber')}
                              // helperText={
                              //   hasError('mobileNumber') ? formState.errors.mobileNumber[0] : null
                              // }
                              variant="outlined"
                            >
                              <InputLabel htmlFor="mobile-number-input">Mobile Number</InputLabel>
                              <OutlinedInput
                                label="Mobile Number"
                                id="mobile-number-input"
                                name="mobileNumber"
                                onChange={handleChange}
                                onKeyDown={onKeyDown}
                                onBlur={onBlur}
                                type="text"
                                value={formState.values.mobileNumber || ''}
                                variant="outlined"
                                endAdornment={
                                  <InputAdornment position="end">

                                    <Tooltip title="Used to verify you and secure your account">
                                      <IconButton>
                                        <HelpIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText id="input-mobile-number-helper-text">{hasError('mobileNumber') ? formState.errors.mobileNumber[0] : null}</FormHelperText>
                            </FormControl>

                          </Grid>
                        </Grid>
                        <TextField
                          className={classes.textField}
                          error={hasError('password')}
                          fullWidth
                          helperText={
                            hasError('password') ? formState.errors.password[0] : null
                          }
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          onKeyDown={onKeyDown}
                          onBlur={onBlur}
                          type="password"
                          value={formState.values.password || ''}
                          variant="outlined"
                        />
                        <TextField
                          className={classes.textField}
                          error={hasError('passwordConfirm')}
                          fullWidth
                          helperText={
                            hasError('passwordConfirm') ? formState.errors.passwordConfirm[0] : null
                          }
                          label="Confirm Password"
                          name="passwordConfirm"
                          onChange={handleChange}
                          onKeyDown={onKeyDown}
                          onBlur={onBlur}
                          type="password"
                          value={formState.values.passwordConfirm || ''}
                          variant="outlined"
                        />
                        { (showCapsLockWarning === true) ?
                          <Alert
                            variant="outlined"
                            severity="warning"
                            style={{ marginTop: '1rem' }}
                          >{Translations.Warnings.CapsLockOn}</Alert>
                          : <></>
                        }
                        <div className={classes.policy}>
                          <Checkbox
                            checked={formState.values.policy || false}
                            className={classes.policyCheckbox}
                            color="primary"
                            name="policy"
                            onChange={handleChange}
                          />
                          <Typography
                            className={classes.policyText}
                            color="textSecondary"
                            variant="body1"
                          >
                            I have read and agree to the{' '}
                            <Link
                              color="primary"
                              component={RouterLink}
                              onClick={handleModalOpen}
                              to="#"
                              underline="always"
                              variant="h6"

                            >
                              Terms and Conditions
                            </Link>
                          </Typography>
                        </div>

                        <FormHelperText error>
                          {(formState.errors.hasOwnProperty('policy'))? formState.errors.policy[0] : '' }
                        </FormHelperText>
                        <ReCaptcha

                          ref={ reCaptchaRef }
                          sitekey={config.reCaptchaSiteKey}
                          action='user_signup_begin'
                          verifyCallback={verifyReCaptchaCallback}
                        />
                        <Button
                          className={classes.signUpButton}
                          color="primary"
                          disabled={isSubmitting} // {!formState.isValid && !
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          <span style={{marginRight: (!isSubmitting)? '0rem' : '1rem' }}>Sign up now</span>{(!isSubmitting)? <></>: <CircularProgress color={'white'} size={'1rem'}/>}
                        </Button>
                      </> }

                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Have an account?{' '}
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="h6"
                      >
                        Sign in
                      </Link>
                    </Typography>
                  </>
                )}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUpBegin.propTypes = {
  history: PropTypes.object
};



export default withWidth()(withRouter(SignUpBegin));
