export default [
  //Country Name', 'Country Name Abbreviation', 'Country Code
  { Name:'Afghanistan', Code: 'AF', SMSPrefix: '93'},
  { Name:'Albania', Code: 'AL', SMSPrefix: '355'},
  { Name:'Algeria', Code: 'DZ', SMSPrefix: '213'},
  { Name:'American Samoa', Code: 'AS', SMSPrefix: '1'},
  { Name:'Andorra', Code: 'AD', SMSPrefix: '376'},
  { Name:'Angola', Code: 'AO', SMSPrefix: '244'},
  { Name:'Anguilla', Code: 'AI', SMSPrefix: '1'},
  { Name:'Antigua and Barbuda', Code: 'AG', SMSPrefix: '1'},
  { Name:'Argentina', Code: 'AR', SMSPrefix: '54'},
  { Name:'Armenia', Code: 'AM', SMSPrefix: '374'},
  { Name:'Aruba', Code: 'AW', SMSPrefix: '297'},
  { Name:'Australia', Code: 'AU', SMSPrefix: '61'},
  { Name:'Austria', Code: 'AT', SMSPrefix: '43'},
  { Name:'Azerbaijan', Code: 'AZ', SMSPrefix: '994'},
  { Name:'Bahamas', Code: 'BS', SMSPrefix: '1'},
  { Name:'Bahrain', Code: 'BH', SMSPrefix: '973'},
  { Name:'Bangladesh', Code: 'BD', SMSPrefix: '880'},
  { Name:'Barbados', Code: 'BB', SMSPrefix: '1'},
  { Name:'Belarus', Code: 'BY', SMSPrefix: '375'},
  { Name:'Belgium', Code: 'BE', SMSPrefix: '32'},
  { Name:'Belize', Code: 'BZ', SMSPrefix: '501'},
  { Name:'Benin', Code: 'BJ', SMSPrefix: '229'},
  { Name:'Bermuda', Code: 'BM', SMSPrefix: '1'},
  { Name:'Bhutan', Code: 'BT', SMSPrefix: '975'},
  { Name:'Bolivia', Code: 'BO', SMSPrefix: '591'},
  { Name:'Bosnia and Herzegovina', Code: 'BA', SMSPrefix: '387'},
  { Name:'Botswana', Code: 'BW', SMSPrefix: '267'},
  { Name:'Brazil', Code: 'BR', SMSPrefix: '55'},
  { Name:'British Indian Ocean Territory', Code: 'IO', SMSPrefix: '1'},
  { Name:'Brunei Darussalam', Code: 'BN', SMSPrefix: '673'},
  { Name:'Bulgaria', Code: 'BG', SMSPrefix: '359'},
  { Name:'Burkina Faso', Code: 'BF', SMSPrefix: '226'},
  { Name:'Burundi', Code: 'BI', SMSPrefix: '257'},
  { Name:'Cambodia', Code: 'KH', SMSPrefix: '855'},
  { Name:'Cameroon', Code: 'CM', SMSPrefix: '237'},
  { Name:'Canada', Code: 'CA', SMSPrefix: '1'},
  { Name:'Cape Verde', Code: 'CV', SMSPrefix: '238'},
  { Name:'Cayman Islands', Code: 'KY', SMSPrefix: '1'},
  { Name:'Central African Republic', Code: 'CF', SMSPrefix: '236'},
  { Name:'Chad', Code: 'TD', SMSPrefix: '235'},
  { Name:'Chile', Code: 'CL', SMSPrefix: '56'},
  { Name:'China', Code: 'CN', SMSPrefix: '86'},
  { Name:'Colombia', Code: 'CO', SMSPrefix: '57'},
  { Name:'Comoros', Code: 'KM', SMSPrefix: '269'},
  { Name:'Congo', Code: 'CG', SMSPrefix: '242'},
  { Name:'Democratic Republic of the Congo', Code: 'CD', SMSPrefix: '242'},
  { Name:'Cook Islands', Code: 'CK', SMSPrefix: '682'},
  { Name:'Costa Rica', Code: 'CR', SMSPrefix: '506'},
  { Name:'Cote d’Ivorie', Code: 'CI', SMSPrefix: '225'},
  { Name:'Croatia', Code: 'HR', SMSPrefix: '385'},
  { Name:'Cuba', Code: 'CU', SMSPrefix: '53'},
  { Name:'Curaçao', Code: 'CW', SMSPrefix: '599'},
  { Name:'Cyprus', Code: 'CY', SMSPrefix: '357'},
  { Name:'Czech Republic', Code: 'CZ', SMSPrefix: '420'},
  { Name:'Djibouti', Code: 'DJ', SMSPrefix: '253'},
  { Name:'Dominica', Code: 'DM', SMSPrefix: '1'},
  { Name:'Dominican Republic', Code: 'DO', SMSPrefix: '1'},
  { Name:'Ecuador', Code: 'EC', SMSPrefix: '593'},
  { Name:'Egypt', Code: 'EG', SMSPrefix: '20'},
  { Name:'El Salvador', Code: 'SV', SMSPrefix: '503'},
  { Name:'Equatorial Guinea', Code: 'GQ', SMSPrefix: '240'},
  { Name:'Eritrea', Code: 'ER', SMSPrefix: '291'},
  { Name:'Estonia', Code: 'EE', SMSPrefix: '372'},
  { Name:'Ethiopia', Code: 'ET', SMSPrefix: '251'},
  { Name:'Falkland Islands', Code: 'FK', SMSPrefix: '500'},
  { Name:'Faroe Islands', Code: 'FO', SMSPrefix: '298'},
  { Name:'Fiji', Code: 'FJ', SMSPrefix: '679'},
  { Name:'Finland', Code: 'FI', SMSPrefix: '358'},
  { Name:'France', Code: 'FR', SMSPrefix: '33'},
  { Name:'French Guiana', Code: 'GF', SMSPrefix: '594'},
  { Name:'French Polynesia', Code: 'PF', SMSPrefix: '689'},
  { Name:'Gabon', Code: 'GA', SMSPrefix: '241'},
  { Name:'Gambia', Code: 'GM', SMSPrefix: '220'},
  { Name:'Georgia', Code: 'GE', SMSPrefix: '995'},
  { Name:'Germany', Code: 'DE', SMSPrefix: '49'},
  { Name:'Ghana', Code: 'GH', SMSPrefix: '233'},
  { Name:'Gibraltar', Code: 'GI', SMSPrefix: '350'},
  { Name:'Greece', Code: 'GR', SMSPrefix: '30'},
  { Name:'Greenland', Code: 'GL', SMSPrefix: '299'},
  { Name:'Grenada', Code: 'GD', SMSPrefix: '1'},
  { Name:'Guam', Code: 'GU', SMSPrefix: '1'},
  { Name:'Guatemala', Code: 'GT', SMSPrefix: '502'},
  { Name:'Guernsey', Code: 'GG', SMSPrefix: '44'},
  { Name:'Guinea', Code: 'GN', SMSPrefix: '224'},
  { Name:'Guinea-Bissau', Code: 'GW', SMSPrefix: '245'},
  { Name:'Guyana', Code: 'GY', SMSPrefix: '592'},
  { Name:'Haiti', Code: 'HT', SMSPrefix: '509'},
  { Name:'Honduras', Code: 'HN', SMSPrefix: '504'},
  { Name:'Hong Kong', Code: 'HK', SMSPrefix: '852'},
  { Name:'Hungary', Code: 'HU', SMSPrefix: '36'},
  { Name:'Iceland', Code: 'IS', SMSPrefix: '354'},
  { Name:'India', Code: 'IN', SMSPrefix: '91'},
  { Name:'Indonesia', Code: 'ID', SMSPrefix: '62'},
  { Name:'Iran', Code: 'IR', SMSPrefix: '98'},
  { Name:'Iraq', Code: 'IQ', SMSPrefix: '964'},
  { Name:'Isle of Man', Code: 'IM', SMSPrefix: '44'},
  { Name:'Ireland', Code: 'IE', SMSPrefix: '353'},
  { Name:'Israel', Code: 'IL', SMSPrefix: '972'},
  { Name:'Italy', Code: 'IT', SMSPrefix: '39'},
  { Name:'Jamaica', Code: 'JM', SMSPrefix: '1'},
  { Name:'Japan', Code: 'JP', SMSPrefix: '81'},
  { Name:'Jersey', Code: 'JE', SMSPrefix: '44'},
  { Name:'Jordan', Code: 'JO', SMSPrefix: '962'},
  { Name:'Kazakhstan', Code: 'KZ', SMSPrefix: '7'},
  { Name:'Kenya', Code: 'KE', SMSPrefix: '254'},
  { Name:'Kiribati', Code: 'KI', SMSPrefix: '686'},
  { Name:'Kuwait', Code: 'KW', SMSPrefix: '965'},
  { Name:'Kyrgyzstan', Code: 'KG', SMSPrefix: '996'},
  { Name:'Laos', Code: 'LA', SMSPrefix: '856'},
  { Name:'Latvia', Code: 'LV', SMSPrefix: '371'},
  { Name:'Lebanon', Code: 'LB', SMSPrefix: '961'},
  { Name:'Lesotho', Code: 'LS', SMSPrefix: '266'},
  { Name:'Liberia', Code: 'LR', SMSPrefix: '231'},
  { Name:'Libya', Code: 'LY', SMSPrefix: '218'},
  { Name:'Liechtenstein', Code: 'LI', SMSPrefix: '423'},
  { Name:'Lithuania', Code: 'LT', SMSPrefix: '370'},
  { Name:'Luxembourg', Code: 'LU', SMSPrefix: '352'},
  { Name:'Macao', Code: 'MO', SMSPrefix: '853'},
  { Name:'Macedonia', Code: 'MK', SMSPrefix: '389'},
  { Name:'Madagascar', Code: 'MG', SMSPrefix: '261'},
  { Name:'Malawi', Code: 'MW', SMSPrefix: '265'},
  { Name:'Malaysia', Code: 'MY', SMSPrefix: '60'},
  { Name:'Maldives', Code: 'MV', SMSPrefix: '960'},
  { Name:'Mali', Code: 'ML', SMSPrefix: '223'},
  { Name:'Malta', Code: 'MT', SMSPrefix: '356'},
  { Name:'Marshall Islands', Code: 'MH', SMSPrefix: '692'},
  { Name:'Martinique', Code: 'MQ', SMSPrefix: '596'},
  { Name:'Mauritania', Code: 'MR', SMSPrefix: '222'},
  { Name:'Mauritius', Code: 'MU', SMSPrefix: '230'},
  { Name:'Mexico', Code: 'MX', SMSPrefix: '52'},
  { Name:'Micronesia', Code: 'FM', SMSPrefix: '691'},
  { Name:'Moldova', Code: 'MD', SMSPrefix: '373'},
  { Name:'Monaco', Code: 'MC', SMSPrefix: '377'},
  { Name:'Mongolia', Code: 'MN', SMSPrefix: '976'},
  { Name:'Montenegro', Code: 'ME', SMSPrefix: '382'},
  { Name:'Montserrat', Code: 'MS', SMSPrefix: '1'},
  { Name:'Morocco', Code: 'MA', SMSPrefix: '212'},
  { Name:'Mozambique', Code: 'MZ', SMSPrefix: '258'},
  { Name:'Myanmar', Code: 'MM', SMSPrefix: '95'},
  { Name:'Namibia', Code: 'NA', SMSPrefix: '264'},
  { Name:'Nauru', Code: 'NR', SMSPrefix: '674'},
  { Name:'Nepal', Code: 'NP', SMSPrefix: '977'},
  { Name:'Netherlands', Code: 'NL', SMSPrefix: '31'},
  { Name:'New Zealand', Code: 'NZ', SMSPrefix: '64'},
  { Name:'Nicaragua', Code: 'NI', SMSPrefix: '505'},
  { Name:'Niger', Code: 'NE', SMSPrefix: '227'},
  { Name:'Nigeria', Code: 'NG', SMSPrefix: '234'},
  { Name:'Niue', Code: 'NU', SMSPrefix: '683'},
  { Name:'Norfolk Island', Code: 'NF', SMSPrefix: '61'},
  { Name:'North Korea', Code: 'KP', SMSPrefix: '850'},
  { Name:'Northern Mariana Islands', Code: 'MP', SMSPrefix: '1'},
  { Name:'Norway', Code: 'NO', SMSPrefix: '47'},
  { Name:'Oman', Code: 'OM', SMSPrefix: '968'},
  { Name:'Pakistan', Code: 'PK', SMSPrefix: '92'},
  { Name:'Palau', Code: 'PW', SMSPrefix: '680'},
  { Name:'Palestine', Code: 'PS', SMSPrefix: '970'},
  { Name:'Panama', Code: 'PA', SMSPrefix: '507'},
  { Name:'Papau New Guinea', Code: 'PG', SMSPrefix: '675'},
  { Name:'Paraguay', Code: 'PY', SMSPrefix: '595'},
  { Name:'Peru', Code: 'PE', SMSPrefix: '51'},
  { Name:'Phillippines', Code: 'PH', SMSPrefix: '63'},
  { Name:'Pitcairn', Code: 'PN', SMSPrefix: '872'},
  { Name:'Poland', Code: 'PL', SMSPrefix: '48'},
  { Name:'Portugal', Code: 'PT', SMSPrefix: '351'},
  { Name:'Puerto Rico', Code: 'PR', SMSPrefix: '1'},
  { Name:'Qatar', Code: 'QA', SMSPrefix: '974'},
  { Name:'Romania', Code: 'RO', SMSPrefix: '40'},
  { Name:'Russia', Code: 'RU', SMSPrefix: '7'},
  { Name:'Rwanda', Code: 'RW', SMSPrefix: '250'},
  { Name:'Saint Helena', Code: 'SH', SMSPrefix: '290'},
  { Name:'Saint Kitts and Nevis', Code: 'KN', SMSPrefix: '1'},
  { Name:'Saint Lucia', Code: 'LC', SMSPrefix: '1'},
  { Name:'Samoa', Code: 'WS', SMSPrefix: '685'},
  { Name:'San Marino', Code: 'SM', SMSPrefix: '378'},
  { Name:'Sao Tome and Principe', Code: 'ST', SMSPrefix: '239'},
  { Name:'Saudi Arabia', Code: 'SA', SMSPrefix: '966'},
  { Name:'Senegal', Code: 'SN', SMSPrefix: '221'},
  { Name:'Serbia', Code: 'RS', SMSPrefix: '381'},
  { Name:'Seychelles', Code: 'SC', SMSPrefix: '248'},
  { Name:'Sierra Leone', Code: 'SL', SMSPrefix: '232'},
  { Name:'Singapore', Code: 'SG', SMSPrefix: '65'},
  { Name:'Sint Maarten', Code: 'SX', SMSPrefix: '1'},
  { Name:'Slovakia', Code: 'SK', SMSPrefix: '421'},
  { Name:'Slovenia', Code: 'SI', SMSPrefix: '386'},
  { Name:'Solomon Islands', Code: 'SB', SMSPrefix: '677'},
  { Name:'Somalia', Code: 'SO', SMSPrefix: '252'},
  { Name:'South Africa', Code: 'ZA', SMSPrefix: '27'},
  { Name:'South Korea', Code: 'KR', SMSPrefix: '82'},
  { Name:'South Sudan', Code: 'SS', SMSPrefix: '211'},
  { Name:'Spain', Code: 'ES', SMSPrefix: '34'},
  { Name:'Sri Lanka', Code: 'LK', SMSPrefix: '94'},
  { Name:'Sudan', Code: 'SD', SMSPrefix: '249'},
  { Name:'Suriname', Code: 'SR', SMSPrefix: '597'},
  { Name:'Swaziland', Code: 'SZ', SMSPrefix: '268'},
  { Name:'Sweden', Code: 'SE', SMSPrefix: '46'},
  { Name:'Switzerland', Code: 'CH', SMSPrefix: '41'},
  { Name:'Syria', Code: 'SY', SMSPrefix: '963'},
  { Name:'Taiwan', Code: 'TW', SMSPrefix: '886'},
  { Name:'Tajikistan', Code: 'TJ', SMSPrefix: '992'},
  { Name:'Tanzania', Code: 'TZ', SMSPrefix: '255'},
  { Name:'Thailand', Code: 'TH', SMSPrefix: '66'},
  { Name:'Togo', Code: 'TG', SMSPrefix: '228'},
  { Name:'Tokelau', Code: 'TK', SMSPrefix: '690'},
  { Name:'Tonga', Code: 'TO', SMSPrefix: '676'},
  { Name:'Trinidad and Tobago', Code: 'TT', SMSPrefix: '1'},
  { Name:'Tunisia', Code: 'TN', SMSPrefix: '216'},
  { Name:'Turkey', Code: 'TR', SMSPrefix: '90'},
  { Name:'Turkmenistan', Code: 'TM', SMSPrefix: '993'},
  { Name:'Turks and Caicos Islands', Code: 'TC', SMSPrefix: '1'},
  { Name:'Tuvalu', Code: 'TV', SMSPrefix: '688'},
  { Name:'US Virgin Islands', Code: 'VI', SMSPrefix: '1'},
  { Name:'Uganda', Code: 'UG', SMSPrefix: '256'},
  { Name:'Ukraine', Code: 'UA', SMSPrefix: '380'},
  { Name:'United Arab Emirates', Code: 'AE', SMSPrefix: '971'},
  { Name:'United Kingdom', Code: 'GB', SMSPrefix: '44'},
  { Name:'United States', Code: 'US', SMSPrefix: '1'},
  { Name:'Uruguay', Code: 'UY', SMSPrefix: '598'},
  { Name:'Uzbekistan', Code: 'UZ', SMSPrefix: '998'},
  { Name:'Vanuatu', Code: 'VU', SMSPrefix: '678'},
  { Name:'Venezuela', Code: 'VE', SMSPrefix: '58'},
  { Name:'Vietnam', Code: 'VN', SMSPrefix: '84'},
  { Name:'Virgin Islands', Code: 'VI', SMSPrefix: '1'},
  { Name:'Yemen', Code: 'YE', SMSPrefix: '967'},
  { Name:'Zambia', Code: 'ZM', SMSPrefix: '260'},
  { Name:'Zimbabwe', Code: 'ZW', SMSPrefix: '263'},
];
