import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography
} from '@material-ui/core';

//import UserService from '../../data/UserService';
import Translations from '../../helpers/translations';
import {ReCaptcha} from 'react-recaptcha-v3';
import {config} from '../../config';
import {Alert} from '@material-ui/lab';
import {SidePlaneGridItem} from '../../layouts/Main/components'
import {DataService} from '../../App';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },


  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Login = props => {
  const { history, location } = props;

  const classes = useStyles();

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const [showCapsLockWarning, setShowCapsLockWarning] = useState(false);
  const [loginError, setLoginError] = useState('');

  const [isWaitingOnNetworkRequest, setIsWaitingOnNetworkRequest] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false,
      captchaResetCallback: (token) => {}
    }
  });

  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  const onKeyDown = keyEvent => {
    if (keyEvent.getModifierState('CapsLock')) {
      setShowCapsLockWarning(true);
    } else {
      setShowCapsLockWarning(false)
    }
  };
  // useEffect(() => {
  //   const errors = validate(formState.values, schema);
  //
  //   setFormState(formState => ({
  //     ...formState,
  //     isValid: errors ? false : true,
  //     errors: errors || {}
  //   }));
  // }, [formState.values]);

  // const handleBack = () => {
  //   history.goBack();
  // };

  const handleChange = event => {
    event.persist();
    setLoginError('');
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    const errors = validate(formState.values, schema);
    if (errors) {
      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
      return;
    }
    setIsWaitingOnNetworkRequest(true);

    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        forceReset: !formState.reCaptcha.forceReset,
        captchaResetCallback: (reCaptchaToken) => {
          dataProvider.User().login(
            formState.values['email'],
            formState.values['password'],
            reCaptchaToken)
            .then((response) => {
              let redirect = '/';
              if (response != null &&
                Object.prototype.hasOwnProperty.call(response, 'redirect') !== false &&
                response.redirect != null
              ) {
                redirect = response.redirect;
              }
              if (redirect === '/' &&
                  location.state != null &&
                  location.state.from != null &&
                  location.state.from.pathname != null
              ){

                let queryPart = '';
                if ( location.state.from.search != null){
                  queryPart = location.state.from.search;
                }
                redirect = location.state.from.pathname + queryPart;

              }
              history.push(redirect);

            })
            .catch((error) => {
              let errorText = '';
              if (error != null &&
                Object.prototype.hasOwnProperty.call(error, 'user_forbidden') !== false &&
                error.user_forbidden != null
              ) {
                errorText = error.user_forbidden;
              } else {
                errorText = Translations.Errors.ServerUnavailable;
              }

              // incorrect password or user disabled
              setLoginError(errorText)
              //let errors = {
              //  password: [errorText]
              //};
              setFormState(formState => ({
                ...formState,
                isValid: false,//errors ? false : true,
                errors:  {}
              }));
            }).finally(() => {
              setIsWaitingOnNetworkRequest(false);
            });

        }
      }
    }));



  };

  const reCaptchaRef = React.useRef();

  const verifyReCaptchaCallback = (recaptchaToken) => {
    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        token: recaptchaToken
      },
    }));
    formState.reCaptcha.captchaResetCallback(recaptchaToken)


  };


  useEffect(() => {
    if (reCaptchaRef.current) {
      reCaptchaRef.current.execute();
    }
  }, [formState.reCaptcha.forceReset]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <SidePlaneGridItem />
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Sign in
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  autoComplete="username"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  autoComplete="current-password"
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                { (showCapsLockWarning === true) ?
                  <Alert
                    variant="outlined"
                    severity="warning"
                    style={{ marginTop: '1rem' }}
                  >{Translations.Warnings.CapsLockOn}</Alert>
                  : <></>
                }
                {(loginError !== '') ?
                  <Alert
                    variant="outlined"
                    severity="error"
                    style={{marginTop: '1rem'}}
                  >{loginError}</Alert>
                  : <></>
                }

                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={isWaitingOnNetworkRequest}//!formState.isValid }
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  <span style={{marginRight: (!isWaitingOnNetworkRequest)? '0rem' : '1rem' }}>Sign in now</span>{(!isWaitingOnNetworkRequest)? <></>: <CircularProgress color={'white'} size={'1rem'}/>}
                </Button>


                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Can't Login?{' '}
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    variant="h6"
                  >
                    Forgot password
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don't have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/sign-up/begin"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
                <ReCaptcha
                  ref={ reCaptchaRef }
                  sitekey={config.reCaptchaSiteKey}
                  action='user_login'
                  verifyCallback={verifyReCaptchaCallback}
                />
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(Login);
