import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  // Budget,
  // TotalInspectionDrones,
  // TasksProgress,
  // TotalProfit,
  LatestEnergy,
  TotalDailyEnergy,
  TotalControllers,
  ModuleHealth
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >

        <Grid
          item
          lg={4}
          md={6}
          xl={6}
          xs={12}
        >
          <LatestEnergy />
        </Grid>
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={4}*/}
        {/*  md={6}*/}
        {/*  xl={3}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <LatestEnergy />*/}
        {/*</Grid>*/}

        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <ModuleHealth />
        </Grid>
        <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
        >
          <TotalDailyEnergy />
          <TotalControllers />
        </Grid>
        {/*<Grid*/}
        {/*    item*/}
        {/*    lg={3}*/}
        {/*    sm={6}*/}
        {/*    xl={3}*/}
        {/*    xs={12}*/}
        {/*>*/}

        {/*</Grid>*/}
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={3}*/}
        {/*  sm={6}*/}
        {/*  xl={3}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <Budget />*/}
        {/*</Grid>*/}
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={3}*/}
        {/*  sm={6}*/}
        {/*  xl={3}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <TotalInspectionDrones />*/}
        {/*</Grid>*/}
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={3}*/}
        {/*  sm={6}*/}
        {/*  xl={3}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <TasksProgress />*/}
        {/*</Grid>*/}
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={3}*/}
        {/*  sm={6}*/}
        {/*  xl={3}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <TotalProfit />*/}
        {/*</Grid>*/}
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={4}*/}
        {/*  md={6}*/}
        {/*  xl={3}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <LatestProducts />*/}
        {/*</Grid>*/}
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={8}*/}
        {/*  md={12}*/}
        {/*  xl={9}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <LatestOrders />*/}
        {/*</Grid>*/}
      </Grid>
    </div>
  );
};

export default Dashboard;
