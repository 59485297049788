import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import {ReCaptcha} from 'react-recaptcha-v3';
import {config} from '../../../../config';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Translations from '../../../../helpers/translations';
import validate from 'validate.js';
import {Alert} from "@material-ui/lab";

import {DataService} from '../../../../App';

const useStyles = makeStyles(() => ({
  root: {},
  SavedTick: {
    display: 'block',
    animation: 'fade-out 5s'
  },
  SavedTickHidden: {
    display: 'none'
  }
}));

const schema = {
  current_password: {
    presence: {  allowEmpty: false, message: 'is required' }
  },
  password: {
    presence: {  allowEmpty: false, message: 'is required' },
    format: {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/,
      message: 'must contain at least 1 Capital, 1 Number, 1 lowercase and 1 symbol'
    },
    length: {
      minimum: 9
    }
  },
  password_confirm: {
    // You need to confirm your password
    presence: true,
    // and it needs to be equal to the other password
    equality: {
      attribute: 'password',
      message: '^The passwords do not match'
}
}
};

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  // const [values, setValues] = useState({
  //   password: '',
  //   confirm: ''
  // });
  const [showCapsLockWarning, setShowCapsLockWarning] = useState(false);
  const [showSavedTick, setShowSavedTick] = React.useState(false);
  const [loadingNetworkRequest, setLoadingNetworkRequest] = React.useState(false);

  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  const onKeyDown = keyEvent => {
    if (keyEvent.getModifierState('CapsLock')) {
      setShowCapsLockWarning(true);
    } else {
      setShowCapsLockWarning(false)
    }
  };

  const [formState, setFormState] = useState({
    isValid: false,
    validateValues: true,
    values: {
      current_password: '',
      password: '',
      password_confirm: ''
    },
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false,
      captchaResetCallback: (token) => {}
    }
  });

  useEffect(() => {

    if (formState.validateValues){
      const errors = validate(formState.values, schema);

      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
    }
  }, [formState.values, formState.validateValues]);


  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handlePasswordChangeSubmit = event => {

    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      validateValues: true,
      reCaptcha: {
        ...formState.reCaptcha,
        forceReset: !formState.reCaptcha.forceReset,
        captchaResetCallback: (reCaptchaToken) => {
          setLoadingNetworkRequest(true);
          dataProvider.User().resetPassword(
            reCaptchaToken,
            formState.values.password,
            formState.values.password_confirm,
            null, null,
            formState.values.current_password
          ).then(response => {
            //password reset

            setFormState(formState => ({
              ...formState,
              validateValues: false,
              values: {
                current_password: '',
                password: '',
                password_confirm: ''
              },
              touched: {},
              isValid: false,
              errors: {},
              // reCaptcha: {
              //   ...formState.reCaptcha,
              //   forceReset: !formState.reCaptcha.forceReset
              // },
            }));
            setShowSavedTick(true);
            setTimeout(() => {
              setFormState(formState => ({
                ...formState,
                validateValues: true,
              }));
              setShowSavedTick(false);
            }, 5000)

          }).catch(error => {
            //showError Message

            if (error != null) {
              let errorText = '';
              errorText = error.message;
              if (errorText === '') {
                errorText = Translations.Errors.ServerUnavailable;
              }
              // incorrect password or user disabled
              let errors = {
                password_confirm: [errorText]
              };
              setFormState(formState => ({
                ...formState,
                validateValues: true,
                isValid: errors ? false : true,
                errors: errors || {},
                // reCaptcha: {
                //   ...formState.reCaptcha,
                //   forceReset: !formState.reCaptcha.forceReset
                // },
              }));

            }

          }).finally(() => {
            setLoadingNetworkRequest(false);
          });
        }
      }
    }));

  };


  const reCaptchaRef = React.useRef();

  const verifyReCaptchaCallback = (recaptchaToken) => {
    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        token: recaptchaToken
      },
    }));
    formState.reCaptcha.captchaResetCallback(recaptchaToken)


  };

  useEffect(() => {
    if (reCaptchaRef.current) {
      reCaptchaRef.current.execute();
    }
  }, [ formState.reCaptcha.forceReset]);

  //
  // const handleChange = event => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        onSubmit={handlePasswordChangeSubmit}
      >
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Current Password"
            name="current_password"
            onChange={handleChange}
            onKeyDown={onKeyDown}
            type="password"
            error={hasError('current_password')}
            helperText={
              hasError('current_password') ? formState.errors.current_password[0] : null
            }
            value={formState.values.current_password || ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="New Password"
            name="password"
            onChange={handleChange}
            onKeyDown={onKeyDown}
            type="password"
            style={{ marginTop: '1rem' }}
            error={hasError('password')}
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            value={formState.values.password || ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            name="password_confirm"
            error={hasError('password_confirm')}
            helperText={
              hasError('password_confirm') ? formState.errors.password_confirm[0] : null
            }
            onChange={handleChange}
            onKeyDown={onKeyDown}
            style={{ marginTop: '1rem' }}
            type="password"
            value={formState.values.password_confirm || ''}
            variant="outlined"
          />
          { (showCapsLockWarning === true) ?
            <Alert
              severity="warning"
              style={{ marginTop: '1rem' }}
            >{Translations.Warnings.CapsLockOn}</Alert>
            : <></>
          }
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!formState.isValid || loadingNetworkRequest}
            type="submit"
          >
            Update
          </Button>
          <CheckCircleOutlineIcon
            className={showSavedTick? classes.SavedTick : classes.SavedTickHidden}
            style={{paddingLeft:'0.5rem', animationFillMode: 'forwards'}}
          />
        </CardActions>
        <ReCaptcha
          ref={ reCaptchaRef }
          sitekey={config.reCaptchaSiteKey}
          action='user_password_change'
          verifyCallback={verifyReCaptchaCallback}
        />
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
