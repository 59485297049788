import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

import validate from 'validate.js';


import { loadReCaptcha } from 'react-recaptcha-v3'
import {config} from '../../../../config';
import withWidth from '@material-ui/core/withWidth';
import {DataService} from '../../../../App';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


loadReCaptcha(config.reCaptchaSiteKey, () => {});




const schema = {
    name: {
      presence: {allowEmpty: false, message: 'is required'},
      length: {
        maximum: 32
      }
    },
  legalName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 32
    }
  },
  primaryEmail: {
    presence: {allowEmpty: true, message: 'is required'},
  }
}



const useStyles =  makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grid: {
    height: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  countryDropDownButton: {
    height: theme.spacing(6.75),
    '&::after': {
      display: 'none'
    }
  },
  expiredLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  MFATick: {
    display: 'block',
    animation: 'fade-out 5s'
  },
  MFATickHidden: {
    display: 'none'
  }

}));

const AccountDetails = props => {
  const account = props.account;
  const classes = useStyles();
  const dataProvider = React.useContext(DataService);


  const accountToFormValues = account => {
    return {
      name:  (Object.prototype.hasOwnProperty.call(account ?? {},'name'))? account.name : '',
      legalName:  (Object.prototype.hasOwnProperty.call(account ?? {},'legal_name'))? account.legal_name : '',
      primaryEmail:  (Object.prototype.hasOwnProperty.call(account ?? {},'primary_email'))? account.primary_email : '',
    }
  };
  //const [isSavingAccount, setIsSavingAccount] = useState(false);
  const [showSuccessTick, setShowSuccessTick] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: accountToFormValues(account),
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false
    }
  });
  const handleUserSaveSubmit = event => {
    event.preventDefault();
    //setIsSavingAccount(true);
    dataProvider.Account().saveCurrentAccount(
      formState.values.name,
      formState.values.legalName,
      formState.values.primaryEmail,
    ).then( response => {
      //user saved
      //setIsSavingAccount(false);
      setShowSuccessTick(true);
      setTimeout(() => {setShowSuccessTick(false); }, 5000);
    }).catch( error => {
      //showError Message
      console.error(error);
      //setIsSavingAccount(false);
    })
  };

  useEffect(() => {
    let newValues =  accountToFormValues(account);
    setFormState(formState => ({
      ...formState,
      values: newValues
    }));
  }, [account]);


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  return (
    <Card
    >
      <form
        onSubmit={handleUserSaveSubmit}
      >
        <CardHeader
          subheader="The information can be edited"
          title="Account"
        />
        <Divider />
        <CardContent>
          <Grid
            className={classes.grid}
            container
            spacing={3}
          >
            <Grid
              className={classes.content}
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                  hasError('name') ? formState.errors.name[0] : null
                }
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={formState.values.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('legalName')}
                fullWidth
                helperText={
                  hasError('legalName') ? formState.errors.legalName[0] : null
                }
                label="Legal name"
                name="legalName"
                onChange={handleChange}
                required
                value={formState.values.legalName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('primaryEmail')}
                fullWidth
                helperText={
                  hasError('primaryEmail') ? formState.errors.primaryEmail[0] : null
                }
                label="Primary Billing Email"
                name="primaryEmail"
                onChange={handleChange}
                required
                value={formState.values.primaryEmail || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <span className={classes.expiredLinkContainer} >
            <Button
              color="primary"
              //disabled={isSavingAccount}
              style={{ justifyContent: 'unset'}}
              type="submit"
              variant="contained"
            >
              Save details
            </Button>
            <CheckCircleOutlineIcon
              className={showSuccessTick? classes.MFATick : classes.MFATickHidden}
              style={{paddingLeft:'0.5rem', animationFillMode: 'forwards'}}
            />
          </span>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default withWidth()(withRouter(AccountDetails));
