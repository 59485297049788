import React, {useState, Suspense, lazy, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {Card, CardContent, FormControlLabel, Checkbox} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { SearchInput } from '../../../../components';

import chartDataRepo from '../../../../helpers/chartDataRepo'

import moment from 'moment';
import {config} from '../../../../config';

const Controllers = lazy(() => import('./data'));

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const ControllerSelector = props => {
  const { installid, className, from, to, selectedControllers, handleControllerSelectionChange, ...rest } = props;

  const classes = useStyles();



  const [controllers, setControllers] = useState( {
    data: [],
    isLoaded: false

  });
  const [searchQuery, setSearchQuery] = useState( '');

  const controllerSelectionChange = (event) => {
    let controllerSerialNumber = event.target.name;
    if (event.target.checked){
      if (!selectedControllers.includes(controllerSerialNumber)) {
        let newSelectedControllers = [...selectedControllers];
        newSelectedControllers.push(controllerSerialNumber);
        handleControllerSelectionChange(newSelectedControllers);
      }
    }else{
      if (selectedControllers.includes(controllerSerialNumber)) {
        let newSelectedControllers = selectedControllers.filter(e => e !== controllerSerialNumber);
        handleControllerSelectionChange(newSelectedControllers);
      }
    }
  }

  const getControllerSerialNumbers = (controllers) => {
    if (!Array.isArray(controllers)){
      return [];
    }
    //console.log('[OptimiserSelection] ids:' + controllers.map((optimiser) => {return optimiser.id}).join(','));
    return controllers.map((controller) => {return controller.id});
    // let optimiserIds = [];
    // for (let index in controllers) {
    //   if (!controllers.hasOwnProperty(index)){ continue; }
    //
    //   optimiserIds.push(controllers[index].id);
    // }
    // console.log('[OptimiserSelection]');
    // return optimiserIds;
  };





  useEffect(() => {
    let dataUrl = config.cqsolaDataApi + '/optimiser/list';
    let params = {
      'filter[install_id]': installid,
      'filter[from]': moment(from).unix(),
      'filter[to]':   moment(to).unix(),
      'limit': 100,
      'page': 0 //getpage here
    };
    if (searchQuery !== ''){
      params['filter[search_query]'] = searchQuery;
    }
    var queryString = Object.keys(params).map(function(key) {
      return key + '=' + params[key]
    }).join('&');

    fetch(dataUrl + '?' + queryString,{
      credentials: 'include',
    })
      .then(res => res.json())
      .then(
        foundControllers => {
          setControllers({data: (Array.isArray(foundControllers))? foundControllers: [] , isLoaded: true});
          chartDataRepo.registerOptimiserIdsForInstallId(installid,(Array.isArray(foundControllers))? getControllerSerialNumbers(foundControllers): [] );
        });
  }, [from, to, installid, searchQuery]);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search Controllers"

          onChange={ event => {
            setSearchQuery(event.target.value)
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="combined_string"
              onChange={controllerSelectionChange}
              defaultChecked={selectedControllers.includes('combined_string')}
            />
          }
          label="String Total"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="median_controller"
              onChange={controllerSelectionChange}
              defaultChecked={selectedControllers.includes('median_controller')}
            />
          }
          label="Median Controller Output"
        />
        <Divider />
        <Suspense fallback={<FormControlLabel
          control={
            <Checkbox
              color="primary"
              defaultChecked //
              disabled={true}
            />
          }
          label="..."
        />}>
          <Controllers
            controllers={controllers.data}
            selectedControllers={(getControllerSerialNumbers(controllers.data) === selectedControllers)? null : selectedControllers}
            handleControllerSelectionChange={controllerSelectionChange}
          />

        </Suspense>
      </CardContent>
    </Card>
  );
};

ControllerSelector.propTypes = {
  className: PropTypes.string,
  installid: PropTypes.string
};

export default ControllerSelector;
