import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { ToggleButton } from '@material-ui/lab';
import { SearchInput } from 'components';
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  quoteText: {
    fontWeight: 300,
    paddingRight: theme.spacing(2),
    textDecoration: 'underline'
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
   // marginBottom: theme.spacing(1)
  }
}));



const DeviceEventLogsToolbar = props => {
  const { className, query, setQuery, deviceEventLogTypes, setDeviceEventLogTypes, ...rest } = props;

  const classes = useStyles();
  const handleOnChange = event => {
    event.preventDefault();
    setQuery(event.target.value);
  };


  const toggleLogType = (logType) => {
    let found = (deviceEventLogTypes.indexOf(logType) > -1)
    let newLogTypes = [...deviceEventLogTypes];
    if (found) {
      newLogTypes = newLogTypes.filter((deviceEventLogType) => {
        return deviceEventLogType !== logType
      })
    }else {
      newLogTypes[newLogTypes.length] = logType;
    }
    setDeviceEventLogTypes(newLogTypes);

  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >

      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search Event Logs"
          value={query}
          onChange={handleOnChange}
        />
        <ToggleButton
          value="check"
          selected={deviceEventLogTypes.indexOf('critical') > -1}
          onChange={() => {
            toggleLogType('critical');
          }}
        >
          <ErrorOutlineOutlinedIcon color={'error'} />
        </ToggleButton>
        <ToggleButton
          value="check"
          selected={deviceEventLogTypes.indexOf('warning') > -1}
          onChange={() => {
            toggleLogType('warning');
          }}
        >
          <ReportProblemOutlinedIcon htmlColor={'orange'} />
        </ToggleButton>
        <ToggleButton
          value="check"
          selected={deviceEventLogTypes.indexOf('info') > -1}
          onChange={() => {
            toggleLogType('info');
          }}
        >
          <InfoOutlinedIcon htmlColor={'#1e88e5'}/>
        </ToggleButton>
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  color="default"*/}

        {/*  className={classes.button}*/}
        {/*  startIcon={<ErrorOutlineOutlinedIcon color={'error'} />}*/}
        {/*>Critical</Button>*/}
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  color="default"*/}
        {/*  className={classes.button}*/}
        {/*  startIcon={<ReportProblemOutlinedIcon htmlColor={'orange'} />}*/}
        {/*>Warning</Button>*/}
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  color="default"*/}
        {/*  className={classes.button}*/}
        {/*  startIcon={<InfoOutlinedIcon htmlColor={'#1e88e5'}/>}*/}
        {/*>Info</Button>*/}
      </div>
    </div>
  );
};

DeviceEventLogsToolbar.propTypes = {
  className: PropTypes.string
};

export default DeviceEventLogsToolbar;
