import React, {createElement } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { withStyles} from '@material-ui/styles';
//import {Button, LinearProgress, Typography} from '@material-ui/core';

//import { SearchInput } from 'components';
import {createChart} from 'lightweight-charts'
import md5 from 'md5';
import randomColor from 'randomcolor';

//import Skeleton from '@material-ui/lab/Skeleton';
import GraphSkeletonLoader from '../GraphSkeletonLoader'
//import {WattageGraph} from '../WattageGraph/WattageGraph';
//import {random, uniqueId} from 'underscore';

const styles = theme => ({
  root: {},
  chartContainer:{
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)

  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
});

class Series {
  constructor(id, data, type, options, label, markers) {
    this.id =  id;
    this.data = data;
    this.type = type;
    this.options = options;
    this.label = label;
    this.chartSeries = null;
    this.markers = markers;
    this.dataHash = '';
    this.markersHash = '';
  }

  getDataHash(){
    if (this.dataHash !== ''){
      return this.dataHash;
    }
    this.dataHash = md5(JSON.stringify(this.data));
    return this.dataHash;
  }
  getMarkerHash(){
    if (this.markersHash !== ''){
      return this.markersHash;
    }
    this.markersHash = md5(JSON.stringify(this.markers));
    return this.markersHash;
  }


  isValid(){
    if (this.data          == null ||
        this.data[0]       == null ||
        this.data[0].time  == null ||
        this.data[0].value == null){
      return false;
    }
    return typeof this.data[Symbol.iterator] === 'function';
  }

  updateData(data){
    // console.log('[LightWeightChart - Series] - update: first new data element: ' + JSON.stringify(data[0]));
    // console.log('[LightWeightChart - Series] - update: last new data element: ' + JSON.stringify(data[data.length-1]));
    //
    // console.log('[LightWeightChart - Series] - update: current element count: ' + JSON.stringify(this.data.length));
    // console.log('[LightWeightChart - Series] - update: new element count: ' + JSON.stringify(data.length));


    // console.log(JSON.stringify(data));
    // console.log(JSON.stringify(this.data));


    this.data = data;
    this.dataHash = JSON.stringify(this.data);
    // var markers = [];
    //
    // markers.push({ time: data[data.length - 48].time, position: 'belowBar', color: '#f68410', shape: 'circle', text: 'Testing' });
    // this.chartSeries.setMarkers(markers);
    this.chartSeries.setData(data);


  }
  updateMarkers(markers){
    this.markers = markers;
    this.markersHash = JSON.stringify(markers);
    this.chartSeries.setMarkers(markers);
  }
  addToChart(chart){
    switch (this.type) {
      case 'line':
      default:
        this.chartSeries = chart.addLineSeries(this.options);
        this.chartSeries.setData(this.data);
        if (this.markers != null) {
          this.chartSeries.setMarkers(this.markers);
        }

        // this.chartSeries.setMarkers([
        //   {
        //     time: this.data[parseInt(this.data.length/2)].time,
        //     position: 'aboveBar',
        //     color: '#f68410',
        //     shape: 'circle',
        //     text: 'TurnedOff',
        //     size: 1
        //   },
        // ]);
        // chart.subscribeCrosshairMove(param => {
        //   console.log(param.hoveredMarkerId);
        // });
        //
        // chart.subscribeClick(param => {
        //   console.log(param.hoveredMarkerId);
        // });
    }


  }
  removeFromChart(chart){
    // checks for null and undefined
    if (this.chartSeries == null) {
      return;
    }
    // if (this.chartSeries._series == null) {
    //   return;
    // }
    if (!typeof (this.chartSeries[Symbol.iterator] === 'function')){
      return;
    }

    chart.removeSeries(this.chartSeries);
    this.chartSeries = null;
  }

  static create(seriesData){

    let id = seriesData.label;
    if (typeof id === 'undefined') {
      let md5 = window.require('md5');
      id = md5(JSON.stringify(seriesData));
    }
    let options = { ...seriesData.options };
    if (options === false){
      options = {};
    }
    if (options.color == null){
      if (window.seriesColours == null){
        window.seriesColours = {};
      }
      if (window.seriesColours[id] == null){
        let colourBaseInt = Math.floor(Math.random() * Math.floor(4));
        let colourBase = 'purple';
        switch(colourBaseInt){
          case 0:
            colourBase = 'purple';
            break;
          case 1:
            colourBase = 'blue';
            break;
          case 2:
            colourBase = 'pink';
            break;
          // case 3:
          //   colourBase = 'orange';
          //   break;
          // case 4:
          //   colourBase = 'red';
          //   break;
          default:
            break;
        }
        window.seriesColours[id] = randomColor({
          luminosity: 'dark',
          hue: colourBase,
          alpha: 0.8,
          format: 'rgba' // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
        });
      }
      options.color = window.seriesColours[id];
    }
    let series = new Series(
      id,
      seriesData.data,
      seriesData.type,
      options,
      seriesData.label,
      (seriesData.markers !== null)? seriesData.markers : []
    );
    if (!series.isValid()){
      //throw error
    }

    // var markers = [];
    // markers.push({ time: seriesData.data[seriesData.data.length - 50].time, position: 'aboveBar', color: '#f68410', shape: 'circle', text: 'D' });
    // series.chartSeries.setMarkers(markers);

    return series;
  }

}

export class LightWeightChart extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;// React.createRef();
    this.colours = {};
    this.series = {};
    this.props = {
      chartOptions: null,
      setChartInstanceRef: null,
      chartDataLoaded: false,
      chartData: null,
      viewPointFrom: null,
      viewPointTo: null
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    console.log('[LightWeightChart] - shouldComponentUpdate');
    return true;
  }



  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('[LightWeightChart] - Did Update');


    let chart = this.chart;

    if (chart== null) {
      return
    }

    if (prevProps.chartData === this.props.chartData ){
      return;
    }

    if (typeof this.props.onVisibleTimeRangeChange == 'function'){
      this.chart.timeScale().unsubscribeVisibleTimeRangeChange(this.props.onVisibleTimeRangeChange);
    }
    //if series are the same, return

    //is the new data the same timeframe & install ID?
    //if yes, add data to graph
    // if no we need to re-render the graph completely
    //let chartOptions = this.props.chartOptions;
    let chartData = this.props.chartData;

    let newSeries = [];
    let newSeriesIds = [];
    for (let dataSeriesIndex in chartData) {
      if (!Object.prototype.hasOwnProperty.call(chartData, dataSeriesIndex)) {
        continue;
      }
      let series = Series.create(chartData[dataSeriesIndex]);
      newSeries[newSeries.length] = series;
      newSeriesIds[newSeriesIds.length] = series.id;
    }

    let seriesIdsToRemove = [];
    for (let currentDataSeriesIndex in this.series) {
      if (!Object.prototype.hasOwnProperty.call(this.series, currentDataSeriesIndex)) {
        continue;
      }
      if (!newSeriesIds.includes(currentDataSeriesIndex)){
        seriesIdsToRemove[seriesIdsToRemove.length] = currentDataSeriesIndex;
      }
    }

    for (let i =0; i < seriesIdsToRemove.length; i++) {
      if (!Object.prototype.hasOwnProperty.call(this.series, seriesIdsToRemove[i])) {
        continue;
      }
      this.series[seriesIdsToRemove[i]].removeFromChart(chart);
      delete this.series[seriesIdsToRemove[i]];
    }

    for (let dataSeriesIndex in newSeries) {
      if (!Object.prototype.hasOwnProperty.call(newSeries, dataSeriesIndex)) {
        continue;
      }
      let series = newSeries[dataSeriesIndex];

      if (this.series[series.id] != null){
        if (this.series[series.id].getDataHash() !== series.getDataHash()) {
          this.series[series.id].updateData(series.data)
        }
        //if (this.series[series.id].getMarkerHash() !== series.getMarkerHash()) {
        this.series[series.id].updateMarkers(series.markers)
        //}
      }
      else {
        this.series[series.id] = series;
        this.series[series.id].addToChart(chart);
      }
    }

    if (this.props.viewPointFrom != null && this.props.viewPointTo != null ) {
      chart.timeScale().setVisibleRange({
        from: this.props.viewPointFrom.getTime() / 1000,
        to: this.props.viewPointTo.getTime() / 1000,
      });
    }

    if (typeof this.props.onUpdateChartFinish == 'function'){
      this.props.onUpdateChartFinish({
        chart: this.chart
      })
    }
    if (typeof this.props.onVisibleTimeRangeChange == 'function'){
      this.chart.timeScale().subscribeVisibleTimeRangeChange(this.props.onVisibleTimeRangeChange);
    }
  }


  componentDidMount() {
    console.log('[LightWeightChart] - Mounted');
  }

  createNewSeries(seriesData){
    let series = Series.create(seriesData);
    series.addToChart(this.chart);
    this.series[series.id] = series;

  }

  loadChart(div) {

    if (div == null) {
      return;
    }
    if (this.chart !== null) {
      return;
    }

    let chartOptions = this.props.chartOptions;
    let chartData = this.props.chartData;
    //let div = chartDiv.current;
    // textInput must be declared here so the ref can refer to it



    div.innerhtml = '';

    //let height = chartOptions.height;
    // if (div.offsetHeight > 600){
    //   height = div.offsetHeight
    // }
    //chartOptions.height = height;
    let width = chartOptions.width;

    if (div.offsetWidth > 800) {
      width = div.offsetWidth
    }
    //chartOptions.height =  height;

    chartOptions.width = width;
    //
    let existingChart = false;
    let chartElement = null;
    for (let childIndex in div.childNodes) {
      if (!Object.prototype.hasOwnProperty.call(div.childNodes, childIndex)) {
        continue;
      }
      chartElement = div.childNodes[childIndex];
      existingChart = (typeof div.childNodes[childIndex] === typeof createElement('canvas'))
      //ideally we'd get the memory of the current chart and add to it
      //TODO: don't remove and replace, seems to work, but ideally it'd be the same graph with updated data
      if (existingChart) {
        div.removeChild(chartElement)
        break;
      }
    }

    this.chart = createChart(div, chartOptions);

    for (let dataSeriesIndex in chartData) {
      if (! Object.prototype.hasOwnProperty.call(chartData, dataSeriesIndex)) {
        continue;
      }
      this.createNewSeries(chartData[dataSeriesIndex]);

    }
    this.chart.applyOptions({
      priceScale: {
        scaleMargins: {
          top: 0.50,
          bottom: 0.1,
        },
      },
    });
    this.chart.timeScale().fitContent();


    //don't like this code below... the graph library currently
    //does not have an event when it's finished loading points on the graph,
    //becasuse of that we have to allow enough time (which could be any amount of time based on the cpu)
    //to mark this as finished loading. need a better way to do this.
    setTimeout(() => {

      // if (typeof this.props.onResize == 'function'){
      //   this.props.onResize(div.offsetWidth, div.offsetHeight)
      // }
      if (typeof this.props.onLoadChartFinish == 'function'){
        this.props.onLoadChartFinish({
          chart: this.chart,
          chartElement: div,
          width: div.offsetWidth,
          height: div.offsetHeight
        });
      }
      if (typeof this.props.onVisibleTimeRangeChange == 'function'){
        this.chart.timeScale().subscribeVisibleTimeRangeChange(this.props.onVisibleTimeRangeChange);
      }
      window.onresize = () => {
        this.chart.resize(div.offsetWidth, div.offsetHeight);
        if (typeof this.props.onResize == 'function'){
          this.props.onResize(div.offsetWidth, div.offsetHeight)
        }
      };
    }, 1200);



  };

  render() {
    // const {setChartInstanceRef, chartDataLoaded, chartData, chartOptions, className, classes, ...rest} = this.props;
    //const setChartInstanceRef = this.props.setChartInstanceRef;
    const chartDataLoaded = this.props.chartDataLoaded;
    //const chartData = this.props.chartData;
    const chartOptions = this.props.chartOptions;
    //const className = this.props.className;
    const classes = this.props.classes;


    if (chartDataLoaded === false) {
      return <GraphSkeletonLoader width={chartOptions.width} height={chartOptions.height}/>
    }

    //let md5 = require('md5');
    //let dataHash = md5(JSON.stringify(chartData));

    //
    // let uniqueId = function () {
    //   return dataHash;
    // };


    return (

      <div className={classes.chartContainer}>
        <div key={1} ref={this.loadChart.bind(this)}/>
      </div>
    );
  }
}

LightWeightChart.propTypes = {
  chartDataLoaded: PropTypes.bool,
  className: PropTypes.string,
  onResize: PropTypes.func,
  onVisibleTimeRangeChange: PropTypes.func,
  onLoadChartFinish: PropTypes.func,
  onUpdateChartFinish: PropTypes.func,
  viewPointFrom: PropTypes.instanceOf(Date),
  viewPointTo:  PropTypes.instanceOf(Date)
};

export default withStyles(styles)(LightWeightChart);

//export default LightWeightChart;
