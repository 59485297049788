//todo: possibly change this to a context later?
import PropTypes from 'prop-types';
import moment from 'moment';
import md5 from 'md5';
import Papa from 'papaparse'

import download from '../helpers/download'
import parseDateRFC3339 from './parseDateRFC3339';
import { config } from '../config';
const initialState = {
  //data is a memory storeage for everything within the window
  //when the user refreshs this will need to be grabbed again
  //however, we may change this overtime to store into the localstorage
  //example state is:
  //data['BRI547']['20120602']['OS10001'][] => { time: 12314654698, value: 24.0 }

  data : {},
  isLoaded: false,
  query: null

};
window.chartData = {};
window.chartData.state = initialState;
window.chartData.timestampsCache = {};
window.chartData.timeZoneCache = {};
window.chartData.controllerSerialNumbersCache = {};

const actions = {
  add: 'ADD',
  load: 'LOAD',
  load_force: 'LOAD_FORCE',
  reset: 'RESET'
};


const results = {
  miss: 'MISS',
  pass: 'PASS',
  hit: 'HIT',
};

const mode = {
  inline: 'inline',
  download: 'download',
  stream: 'stream',
};



const registerControllerSerialNumbersForInstallId = (installId, controllerSerialNumbers) => {
  window.chartData.controllerSerialNumbersCache[installId] = controllerSerialNumbers
};

const gatherDaysWithinTimeFrame = (startDate, stopDate) => {
  let dateArray = [];
  let currentDate = moment(startDate).clone();
  let endDate = moment(stopDate).clone();
  while (currentDate <= endDate) {
    dateArray.push( moment(currentDate).clone().format('YYYYMMDD') )
    currentDate = moment(currentDate).clone().add(1, 'days');
  }
  return dateArray;
};

const _doesCacheExist = (query) => {

  if (
    typeof window.chartData.state === 'undefined' ||
    typeof window.chartData.state.data === 'undefined'
  ){
    console.log('[Cache Miss]: No Data Cached');
    return false;
  }
  let currentChartState = window.chartData.state.data;

  if (typeof currentChartState[query.dataType] == 'undefined') {
    console.log('[Cache Miss]: ' + query.dataType);
    return false;
  }

  let installId = query.filters.installId;
  if (typeof currentChartState[query.dataType][installId] == 'undefined') {
    console.log('[Cache Miss]: ' + query.dataType + ' ' + installId);
    return false;
  }
  return true;
};

// function _getSortedOptimiserIds(mergedData) {
//   let sortedOptimiserIds = [];
//   for (let optimiserId in mergedData) {
//     if (!mergedData.hasOwnProperty(optimiserId)) {
//       continue;
//     }
//     sortedOptimiserIds.push({optimiserId: optimiserId, cachedPoints: mergedData[optimiserId].length})
//   }
//   sortedOptimiserIds.sort(function (a, b) {
//     if (a.cachedPoints < b.cachedPoints) return -1;
//     if (a.cachedPoints > b.cachedPoints) return 1;
//     return 0;
//   });
//   return sortedOptimiserIds;
// }

const _getTimestamps = (mergedData, query) => {
  // try for a match with all optimisers with time stamps
  //order current cache by least filled to most filled
  //create timestamps based on least filled, and attempt fit between all curves


  let from = query.filters.from.unix(); //unix timestamp in seconds
  let to = query.filters.to.unix();     //unix timestamp in seconds
  // if ((to-from) > query.limit){
  //   for (let i = from; i < to; i++) {
  //     timestampsToDisplay.add(i);
  //   }
  // }

  let nthRecord = Math.ceil((to-from) / query.limit);//to / query.limit;
  let timestampsToDisplay = new Set();
  for (let i = from; i < to; i++) {
    //if (i % query.limit === 0) {

    let mod = i % nthRecord;
    if (mod < 1 && mod > -1) {
    //for (let i = 0; i < to; i++) { //fitting code
      //let mod = i % nthRecord;
      //if (mod < 1 && mod > -1) {
      timestampsToDisplay.add(i);
      //}
    }

  }
  return timestampsToDisplay;
  //
  // let datapointCount = query.limit;
  // let sortedOptimiserIds = _getSortedOptimiserIds(mergedData);
  //
  // let maxAttempts = 3;
  // let attempts = 0;
  //
  // for (let optimiserIdIndex in sortedOptimiserIds){
  //   if (!sortedOptimiserIds.hasOwnProperty(optimiserIdIndex)) {
  //     continue;
  //   }
  //   if (attempts >= maxAttempts){
  //     return false;
  //   }
  //   let optimiserId = sortedOptimiserIds[optimiserIdIndex].optimiserId;
  //   if (optimiserId == null){
  //     continue;
  //   }
  //
  //   //fit timestamps to foundCacheData
  //   let cacheData = mergedData[optimiserId];
  //   let timestampsToDisplay = new Set();
  //   for (let i = 0; i < cacheData.length; i++) {
  //     if (i % datapointCount === 0) {
  //       let nthRecord = cacheData.length / datapointCount;
  //       for (let i = 0; i < cacheData.length; i++) {
  //         let mod = i % nthRecord;
  //         if (mod < 1 && mod > -1) {
  //           timestampsToDisplay.add(cacheData[i].time);
  //         }
  //       }
  //     }
  //   }
  //   let allDatapointsFound = true;
  //   for (let optimiserId in mergedData){
  //     //do all the timestamps exist in the merged data?
  //     let foundTimestampsForOptimiser = true;
  //     for (let timestamp in timestampsToDisplay){
  //       //find in merged data mergedData[optimiserId]
  //       let foundTimestamp = false;
  //       for (let index in mergedData[optimiserId]){
  //         if (mergedData[optimiserId][index].time === timestamp){
  //           foundTimestamp = true;
  //           break;
  //         }
  //       }
  //       if (!foundTimestamp){
  //         foundTimestampsForOptimiser = false;
  //         break;
  //       }
  //     }
  //     if (!foundTimestampsForOptimiser) {
  //       allDatapointsFound = false;
  //       break;
  //     }
  //   }
  //   if (allDatapointsFound === true){
  //     return timestampsToDisplay;
  //   }
  //   attempts++;
  // }
  // return false;

}

const getTimestamps = (mergedData, query) => {
  if (typeof query.limit === 'undefined'){
    query.limit = 1200;
  }
  // let timestampCache = {
  //   ...query,
  //   filters: {
  //     ...query.filters,
  //     optimiserIds: ''
  //   }
  // };
  let cachekey = md5(JSON.stringify(query) );

  if (typeof window.chartData.timestampsCache[cachekey] !== 'undefined'){
    return window.chartData.timestampsCache[cachekey];
  }
  let timestamps =  _getTimestamps(mergedData, query);
  if (timestamps === false){
    return false;
  }
  window.chartData.timestampsCache[cachekey] = timestamps
  return window.chartData.timestampsCache[cachekey];

};

const tryFetchDataFromCache = (query) => {

  if (!_doesCacheExist(query)){
    return {data: null, result: results.miss};
  }
  let currentChartState = window.chartData.state.data;
  let installId = query.filters.installId;

  let datesFound = gatherDaysWithinTimeFrame(query.filters.from, query.filters.to);
  if (datesFound.length < 1){
    //bad query, can't serve from cache
    return {data: null, result: results.miss};
  }
  let mergedData = {};

  let maximumDatapointsInQuery = query.filters.to.unix() - query.filters.from.unix();
  let maximumDatapointsPerDay = maximumDatapointsInQuery / datesFound.length;

  //Minimum Datapoints needed to fulfil the query request with useful data.
  //Will provide a cache miss if the minimum datapoints aren't available.
  //Will need to fetch that data from the server.
  let minimumDatapointsPerDay = 0;
  if (query.limit > 0) {
    minimumDatapointsPerDay = query.limit / datesFound.length;
  }
  //if the minimum points needed are bigger than the maximum Datapoint available,
  //then the cache is valid.
  if (minimumDatapointsPerDay > maximumDatapointsPerDay){
    minimumDatapointsPerDay = maximumDatapointsPerDay;
  }

  for (let dateIndex in datesFound) {
    if (!datesFound.hasOwnProperty(dateIndex)) {
      continue;
    }
    let dayCacheKey = datesFound[dateIndex];


    if (typeof currentChartState[query.dataType][installId][dayCacheKey] == 'undefined') {
      console.log('[Cache Miss]: ' + query.dataType + ' '  + installId + ' ' + dayCacheKey);
      return {data: null, result: results.miss};
    }

    let controllerIds = query.filters.optimiserIds;
    if (typeof window.chartData.controllerSerialNumbersCache[installId] !== 'undefined' &&
        query.filters.optimiserIds.length < 1 )
    {
      controllerIds = window.chartData.controllerSerialNumbersCache[installId];
    }



    for (let controllerIdIndex in controllerIds) {
      if (!controllerIds.hasOwnProperty(controllerIdIndex)) {
        continue;
      }
      let controllerId = (controllerIds[controllerIdIndex]).trim();
      if (controllerId === '') {
        mergedData = [].concat(mergedData, Object.values(currentChartState[query.dataType][installId][dayCacheKey]));
        continue;
      }
      if (currentChartState[query.dataType][installId][dayCacheKey][controllerId] === 'undefined' ||
        currentChartState[query.dataType][installId][dayCacheKey][controllerId] == null
      ) {
        console.log('[Cache Miss]: ' + query.dataType + ' '  + installId + ' ' + dayCacheKey + ' ' + controllerId);
        return {data: null, result: results.miss};
      }

      if (currentChartState[query.dataType][installId][dayCacheKey][controllerId].length < minimumDatapointsPerDay){
        console.log('[Cache Miss]: ' + query.dataType + ' '  + installId + ' ' + dayCacheKey + ' ' + controllerId + ' - Not Enough Datapoints');
        return {data: null, result: results.miss};
      }

      if (typeof mergedData[controllerId] == 'undefined') {
        mergedData[controllerId] = [];
      }
      let optimiserData =
        Object.values(currentChartState[query.dataType][installId][dayCacheKey][controllerId])
          .filter(function(datapoint){
            return datapoint.time >=  query.filters.from.unix() && datapoint.time <=  query.filters.to.unix();
          });
      mergedData[controllerId] = [].concat(mergedData[controllerId], optimiserData);
    }
  }


  let timestampsToDisplay = getTimestamps(mergedData, query);
  if (!timestampsToDisplay){
    console.log('[Cache Miss 0x3310]: ' + query.dataType + ' '  + installId + ' Not Enough Datapoints');
    return {data: null, result: results.miss};
  }


  let dataPoints = {};
  //establish time entries to keep
  for (let controllerSerialNumber in mergedData) {
    if (!Object.prototype.hasOwnProperty.call(mergedData, controllerSerialNumber)) {
      continue;
    }

    // if (timestampsToDisplay.length < 1) {
    //   for (let i = 0; i < cacheData.length; i++) {
    //     if (i % query.limit === 0) {
    //       let nthRecord = cacheData.length / query.limit;
    //       for (let i = 0; i < cacheData.length; i++) {
    //         let mod = i % nthRecord;
    //         if (mod < 1 && mod > -1) {
    //           timestampsToDisplay.push(cacheData[i].time);
    //           if (typeof dataPoints[controllerSerialNumber] == 'undefined') {
    //             dataPoints[controllerSerialNumber] = [];
    //           }
    //           dataPoints[controllerSerialNumber].push(cacheData[i])
    //         }
    //       }
    //     }
    //   }
    // }
    // else {
    let failed = false;
    let cacheData = mergedData[controllerSerialNumber];
    let skippedPoints = 0;
    let skippedPointsTolerance = 10// maximumDatapointsInQuery * 0.025; //2.5% tolerance for unmatched points
    let timestampMatchTolerance = maximumDatapointsInQuery * 0.005 //0.5% tolerance // +- 5 seconds is acceptable to the timestamp to display
    for(let timestampToDisplay of timestampsToDisplay){
      let foundTimestamp = false;
      for (let i = 0; i < cacheData.length; i++) {
        let timestampWithinTolerance = false;

        timestampWithinTolerance = (timestampToDisplay > (cacheData[i].time - timestampMatchTolerance) &&
          timestampToDisplay < (cacheData[i].time + timestampMatchTolerance));

        if (timestampWithinTolerance){
          if (typeof dataPoints[controllerSerialNumber] == 'undefined') {
            dataPoints[controllerSerialNumber] = [];
          }
          dataPoints[controllerSerialNumber].push(cacheData[i]);
          foundTimestamp = true;
          break; //found datapoint in cache, move to next timestamp
        }
      }
      if (!foundTimestamp) {
        skippedPoints++;
        if (skippedPoints > skippedPointsTolerance) {
          failed = true;
          break;
        }
      }
    }
    if (failed){
      continue;
    }
    //attempt to find in cache
    //



    // for (let i = 0; i < cacheData.length; i++) {
    //
    //   if (timestampsToDisplay.has(cacheData[i].time)) {
    //     if (typeof dataPoints[controllerSerialNumber] == 'undefined') {
    //       dataPoints[controllerSerialNumber] = [];
    //     }
    //
    //     dataPoints[controllerSerialNumber].push(cacheData[i])
    //   }
    //}
    //}
  }

  let dataPointCountValidation = {};

  for (let controllerSerialNumber in dataPoints) {
    if (!Object.prototype.hasOwnProperty.call(dataPoints, controllerSerialNumber)){ continue; }
    if (dataPoints[controllerSerialNumber].length >= timestampsToDisplay.size &&
        timestampsToDisplay.size > query.limit * 0.95) {
      dataPointCountValidation[controllerSerialNumber] = true;
    }
  }
  let enoughDataPointsForAllControllers = false;
  if (Object.prototype.hasOwnProperty.call(query.filters, 'optimiserIds') &&
    Array.isArray(query.filters.optimiserIds) &&
    query.filters.optimiserIds.length > 0
  ) {

    let reducedCountValidation = Object.values(query.filters.optimiserIds).reduce((total, next) => {
      total.valid = total.valid && (Object.prototype.hasOwnProperty.call(dataPointCountValidation, next) && dataPointCountValidation[next]);
      return total;
    }, {valid: true});

    enoughDataPointsForAllControllers = reducedCountValidation.valid;
  }else {
    enoughDataPointsForAllControllers = true;
  }
  if (!enoughDataPointsForAllControllers){
    console.log('[Cache Pass 0x3320]: ' + query.dataType + ' '  + installId + ' Not Enough Datapoints');
    return {data: dataPoints, result: results.miss};
  }
  return {data: dataPoints, result: results.hit};
  //return mergedData;

};

/////////
// function load - loads the query and returns data to the callback.
// params:
//   query = {
//     dataType: string [watts, volts, amps, temperature, wattsTotal, voltsTotal, ampsTotal ],
//     mode: string [inline, download, stream]
//     filters: {
//       installId: string,
//       from: Date,
//       to: Date,
//       optimiserIds: []string,
//     },
//     limit: int
//   }//
//   callback = called at the end of processing, this should be given fi you need to use the data from the load (and not just store it for cached data later)
//   useCache = use in-memory cache to fetch results or fallback directly ot server
const load = (query, callback = (data) => { return data; }, useCache = true) => {

  console.log('[ChartDataRepo Load]: query.filters.controllerIds:' + query.filters.optimiserIds +' query.from:' + query.filters.from + ' query.to:' + query.filters.to + 'dataType: ' + query.dataType);




  query.filters.from = moment(query.filters.from);
  query.filters.to   = moment(query.filters.to);

  if (query.mode == null){
    query.mode = mode.inline;
  }
  switch(query.mode){
    case mode.download:
      useCache = false;
      break;
    case mode.stream:
      //not implemented // ignore
      break;
    case mode.inline:
    default:
      break;
  }

  if (query.filters.from.isAfter(query.filters.to)){
    console.log('[ChartDataRepo Load]: Bad Query: query.from:' + query.filters.from + ' query.to:' + query.filters.to + 'dataType: ' + query.dataType);
    return;
  }
  let cacheResults = { result: null, data: null };
  if (useCache) {
    cacheResults = tryFetchDataFromCache(query);
    switch(cacheResults.result){
      case results.hit:
        console.log('[ChartDataRepo Load]: Cache Hit');

        let optimiserIds = Object.keys(cacheResults.data);
        console.log('[ChartDataRepo Load] - Received chart data from old query. Stored as cache but didn\'t update chart');

        console.log('[ChartDataRepo Load] - ' + optimiserIds.join(','));

        //cache data is enough to show and we don't fetch more
        return callback({ isLoaded: true, query: query, data: cacheResults.data, cacheResult: cacheResults.result});
      case results.pass:
        //cached data is enough to show and but not enough points, we go fetch more
        callback({ isLoaded: true, query: query, data: cacheResults.data, cacheResult: cacheResults.result});
        break;
      case results.miss:
      default:
        console.log('[ChartDataRepo Load]: Cache Miss');
        //cata data is not enough to show, and we get fetch more
        break;
    }

  }
  let queryLimit = parseInt(query.limit);
  if (isNaN(queryLimit)) {
    queryLimit = -1;
  }

  let params = {
    'data_type': query.dataType,
    'filter[install_id]': query.filters.installId,
    'filter[from]': query.filters.from.format(),
    'filter[to]': query.filters.to.format(),
    'filter[controller_serial_numbers]': query.filters.optimiserIds,
    'mode': query.mode,
    'limit': queryLimit
  };

  let queryString = Object.keys(params).map(function(key) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&');
  // // Defining key
  //

  let dataUrl = config.cqsolaDataApi + '/comms-unit/graph?' + queryString;


  let collectedData = {};
  let cacheKeysStored = {};
  if (typeof window.chartData.state.data[query.dataType] === 'undefined') {
    window.chartData.state.data[query.dataType] = {};
  }
  if (typeof window.chartData.state.data[query.dataType][query.filters.installId] === 'undefined') {
    window.chartData.state.data[query.dataType][query.filters.installId] = {};
  }

  switch(query.mode){
    case mode.download:
      fetch(dataUrl, {
        method: 'get',
        credentials: 'include',
      })
        .then((res) => res.blob())
        .then((resBlob) => {
          callback({isLoaded: true, query: query, data: resBlob,  cacheResult: cacheResults.pass});
          let dateFormat = 'YYYY-MM-DD-hhmmss';
          let filename = 'cqsola-' + query.filters.installId + '-' + query.dataType +'-' + query.filters.from.format(dateFormat) + '-' + query.filters.to.format(dateFormat) + '.csv';
          download(resBlob, filename, 'text/csv' );
        });
      return;
    case mode.stream:
      //not implemented // ignore
      break;
    case mode.inline:
    default:
      break;
  }



  Papa.parse(dataUrl, {
    worker: true,
    download: true,
    //downloadRequestHeaders: authHeader(),
    withCredentials: true,
    header: true,
    fastMode: true,
    step: function(row) {
      if (row == null || typeof row.data === 'undefined'){
        return;
      }
      if (row.data.timestamp  === ''){
        return;
      }
      let unixTimestamp = parseDateRFC3339(row.data.timestamp);
      let date = new Date(unixTimestamp * 1000);
      //   const RFC3339datePattern = /^([0-9]+)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])[Tt]([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60)(\.[0-9]+)?(([Zz])|([\+|\-]([01][0-9]|2[0-3]):[0-5][0-9]))$/;
      //
      //   const [RFC3339, year, month, day, hour, minute, second, usingZ, offset] = RFC3339datePattern.exec(row.data.timestamp);
      //   let offsetOverride = parseInt(offset);
      //   //js time months start at 0 -> 11, so we need to -1 off to get the right timestmap)
      // //  let browserOffset = new Date().getTimezoneOffset() / 60;
      //
      //   let unixTimestamp = Math.round((new Date(year, month-1, day, hour, minute, second)).getTime() / 1000);
      //
      //   // let unixTimestamp = Math.round((new Date(year, month-1, day, hour, minute, second)).getTime() / 1000);
      //
      //
      //
      //   let date = new Date(unixTimestamp * 1000);
      let dayCacheKey = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0')  + date.getDate().toString().padStart(2, '0');

      if (typeof window.chartData.state.data[query.dataType][query.filters.installId][dayCacheKey] == 'undefined'){
        window.chartData.state.data[query.dataType][query.filters.installId][dayCacheKey] = {}
      }


      for (let controllerSerialNumber in row.data) {
        if (!row.data.hasOwnProperty(controllerSerialNumber) || controllerSerialNumber === 'timestamp') continue;

        if (typeof window.chartData.state.data[query.dataType][query.filters.installId][dayCacheKey][controllerSerialNumber] == 'undefined') {
          window.chartData.state.data[query.dataType][query.filters.installId][dayCacheKey][controllerSerialNumber] = {}
        }
        cacheKeysStored[query.dataType + '_' + query.filters.installId + '_' + dayCacheKey + '_' + controllerSerialNumber] = true

        let datapoint = {
          time: unixTimestamp,
          value: parseFloat(row.data[controllerSerialNumber])
        };
        if (!Object.prototype.hasOwnProperty.call(window.chartData.state.data[query.dataType][query.filters.installId][dayCacheKey][controllerSerialNumber], unixTimestamp )){
          window.chartData.state.data[query.dataType][query.filters.installId][dayCacheKey][controllerSerialNumber][unixTimestamp] = datapoint;
        }
        if (typeof collectedData[controllerSerialNumber] === 'undefined'){
          collectedData[controllerSerialNumber] = [];
        }
        collectedData[controllerSerialNumber].push(datapoint);
      }
    },
    complete: function() {
      for (let cacheKey in cacheKeysStored)
      {
        if (!cacheKeysStored.hasOwnProperty(cacheKey)) continue;
        console.log('[Cache Store]: ' + cacheKey);
      }
      return callback({isLoaded: true, query: query, data: collectedData,  cacheResult: cacheResults.miss});
    }
  });


};
const toIsoString = (date, commsunitTimeZone) => {


  let tzo = +commsunitTimeZone*60,//-date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
      return (num < 10 ? '0' : '') + num;
    };
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
}

const prepareParamsFromQuery = (query, commsunitTimeZone, useCache) => {
  //let dataProvider = new DataProvider();

  return new Promise((resolve, reject) => {
    query.filters.from = moment(query.filters.from);
    query.filters.to   = moment(query.filters.to);

    if (query.mode == null){
      query.mode = mode.inline;
    }
    switch(query.mode){
      case mode.download:
        useCache = false;
        break;
      case mode.stream:
        //not implemented // ignore
        break;
      case mode.inline:
      default:
        break;
    }

    if (query.filters.from.isAfter(query.filters.to)){
      console.log('[ChartDataRepo Load]: Bad Query: query.from:' + query.filters.from + ' query.to:' + query.filters.to + 'dataType: ' + query.dataType);
      return false;
    }

    let queryLimit = parseInt(query.limit);
    if (isNaN(queryLimit)) {
      queryLimit = -1;
    }


    // let getCommsUnitTimeZoneOffset = (installId) => {
    //   const DefaultTimeZoneOffset = 10;
    //   return new Promise( (resolveTimeZoneGet, rejectTimeZoneGet) => {
    //     //fetch from server
    //     if (!Object.prototype.hasOwnProperty.call(window.chartData.timeZoneCache, installId)){
    //
    //       let listQuery = new Query({ filter: {query: installId} });
    //       resolveTimeZoneGet(10)
    //       // dataProvider.CommsUnit().list(listQuery)
    //       //   .then((commsUnitResponse) => {
    //       //     for (let commsUnit in commsUnitResponse.data){
    //       //       window.chartData.timeZoneCache[installId] = commsUnit.time_zone
    //       //     }
    //       //     // setSolarStrings(commsUnitResponse.data);
    //       //     // setRowTotal(commsUnitResponse.meta.total);
    //       //
    //       //     //window.chartData.timeZoneCache[installId] = 10
    //       //     if (!Object.prototype.hasOwnProperty.call(window.chartData.timeZoneCache, installId)) {
    //       //       resolveTimeZoneGet(DefaultTimeZoneOffset)
    //       //     }else {
    //       //       resolveTimeZoneGet(window.chartData.timeZoneCache[installId])
    //       //     }
    //       //
    //       //   }).catch( err => {
    //       //     rejectTimeZoneGet(err)
    //       //   });
    //
    //     }
    //     else {
    //       resolveTimeZoneGet(window.chartData.timeZoneCache[installId])
    //     }
    //
    //   })
    // }

    //the below is tied into the way the react-timeseries-graph works,
    //the graphing system doesn't account for timezones and
    //always produces the timezone that the browser is in.
    //this causes problems to overseas customers, where the browser
    //is in a different timezone to the comms unit.
    //
    //To avoid this, we always submit the correct commsunit timezone with
    //requests, but this means replacing the browser timezone, but keeping the
    //time.
    // getCommsUnitTimeZoneOffset(query.filters.installId).then(commsunitTimeZone => {
    //
    //   let from = new Date(query.filters.from.toDate().getTime())// - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));
    //   let to = new Date(query.filters.to.toDate().getTime())//  - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));
    //
    //   resolve({
    //     'data_type': query.dataType,
    //     'filter[install_id]': query.filters.installId,
    //     'filter[from]': toIsoString(from, commsunitTimeZone),
    //     'filter[to]':   toIsoString(to, commsunitTimeZone),
    //     'filter[controller_serial_numbers]': query.filters.optimiserIds,
    //     'mode': query.mode,
    //     'limit': queryLimit
    //   });
    // });
   // let commsunitTimeZone = 10;
    let from = new Date(query.filters.from.toDate().getTime())// - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));
    let to = new Date(query.filters.to.toDate().getTime())//  - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));

    resolve({
      'data_type': query.dataType,
      'filter[install_id]': query.filters.installId,
      'filter[from]': toIsoString(from, commsunitTimeZone),
      'filter[to]':   toIsoString(to, commsunitTimeZone),
      'filter[controller_serial_numbers]': query.filters.optimiserIds,
      'mode': query.mode,
      'limit': queryLimit
    });
  })

}

const loadCSV = (query, timezoneOffset, useCache = true) => {

  return new Promise((resolve, reject) => { 
    prepareParamsFromQuery(query, timezoneOffset, useCache)
      .then(params => {

        if (params === false) {
          return;
        }
        let queryString = Object.keys(params).map(function(key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        // // Defining key
        //
        let dataUrl = config.cqsolaDataApi + '/comms-unit/graph?' + queryString;

        
        switch(query.mode){
          case mode.download:
            fetch(dataUrl, {
              method: 'get',
              credentials: 'include',
            })
              .then((res) => res.blob())
              .then((resBlob) => {
                let dateFormat = 'YYYY-MM-DD-hhmmss';
                let filename = 'cqsola-' + query.filters.installId + '-' + query.dataType +'-' + query.filters.from.format(dateFormat) + '-' + query.filters.to.format(dateFormat) + '.csv';
                download(resBlob, filename, 'text/csv' );
              });
            return;
          case mode.stream:
            //not implemented // ignore
            break;
          case mode.inline:
          default:
            break;
        }



        let collectedData = [];
        Papa.parse(dataUrl, {
          worker: true,
          download: true,
          //downloadRequestHeaders: authHeader(),
          withCredentials: true,
          header: false,
          fastMode: true,
          step: function(row) {
            if (row == null || typeof row.data === 'undefined'){
              return;
            }
            // if (row.data.timestamp  === ''){
            //   return;
            // }

            //let unixTimestamp = parseDateRFC3339(row.data[0]);
            //let date = new Date(unixTimestamp * 1000);
            collectedData.push(row.data)
            //   const RFC3339datePattern = /^([0-9]+)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])[Tt]([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60)(\.[0-9]+)?(([Zz])|([\+|\-]([01][0-9]|2[0-3]):[0-5][0-9]))$/;

          },
          complete: function() {
            resolve({isLoaded: true, query: query, data: collectedData,  cacheResult: results.miss});
          }
        });
      }).catch(err => {
        reject(err)
      })
   
  });
 
};

const reset = (callback) => {
  window.chartData.state = initialState;
  return callback({ ...window.chartData.state });
};


const ChartDataRepo = () => {

};

ChartDataRepo.propTypes = {
  actions: PropTypes.oneOf(Object.keys(actions)),
  load: PropTypes.func,
  reset: PropTypes.func,
  registerOptimiserIdsForInstallId: PropTypes.func
};

ChartDataRepo.actions = actions;
ChartDataRepo.results = results;
ChartDataRepo.mode = mode;

ChartDataRepo.load = load;
ChartDataRepo.loadCSV = loadCSV;
ChartDataRepo.reset = reset;
ChartDataRepo.registerOptimiserIdsForInstallId = registerControllerSerialNumbersForInstallId;


export default ChartDataRepo;

