export default {
  Warnings: {
    CapsLockOn: 'Caps Lock is on',
    ApiAccessUserLimit: 'Api Access will have the same access and limits as the current user',
    ApiAccessTokenAgeConsiderRenewal: 'Api Access token more than 6 months old, consider deleting and re-creating a new access token',
    ApiAccessTokenAgeNeedsRenewal:  'Api Access token more than 12 months old, deleting and re-creating a new access token is highly recommended'
  },
  Errors: {
    ServerUnavailable: 'Something went wrong, our services aren\'t functioning as they should. Please try refreshing the page or again later',
    UnExpectedResponse: 'Our Server has given us an unexpected response',
    ClaimKeyInvalid: 'Invalid Claim Key'
  }
};
