import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

//import {DateRangePickerInput} from "@material-ui/pickers/DateRangePicker/DateRangePickerInput";
import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const ReportFilters = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [ formState, setFormState ] = React.useState({
    values: {
      'baseline_type': 'median',
      'underperforming': 65,
      'expected': 85,
      'healthy': 95,
    },
    errors: {},
    touched: {},
  });
  const hasError = field =>
      formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/*<div className={classes.row}>*/}
      {/*  <span className={classes.spacer} />*/}
      {/*  <Button*/}
      {/*    color="primary"*/}
      {/*    variant="contained"*/}
      {/*  >*/}
      {/*    Add insight*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div className={classes.row}>
        <IconButton
          aria-label="set-date-range"
          //onClick={(event) => {
          //  setPopOverAnchorElement(event.currentTarget);
          // setOpen(!open);
          //}}
          title="Set Date Range"
        >
          <DateRangeIcon
            name="Calandar"
            size={1}
          />
        </IconButton>
        {/*<DateRangePicker*/}
        {/*  open={}*/}
        {/*/>*/}
      </div>
      <div className={classes.row}>
        <TextField
            className={classes.textField}
            error={hasError('underperforming')}
            fullWidth
            helperText={
              hasError('underperforming') ? formState.errors.underperforming[0] : null
            }
            label="Under Performing Threshold"
            name="underperforming"
            onChange={handleChange}
            required
            value={formState.values.underperforming}
            variant="outlined"
        />

        <TextField
            className={classes.textField}
            error={hasError('expected')}
            fullWidth
            helperText={
              hasError('expected') ? formState.errors.expected[0] : null
            }
            label="Expected Threshold"
            name="expected"
            onChange={handleChange}
            required
            value={formState.values.expected}
            variant="outlined"
        />
        <TextField
            className={classes.textField}
            error={hasError('healthy')}
            fullWidth
            helperText={
              hasError('healthy') ? formState.errors.healthy[0] : null
            }
            label="Healthy Threshold"
            name="healthy"
            onChange={handleChange}
            required
            value={formState.values.healthy}
            variant="outlined"
        />
        <IconButton
            aria-label="set-date-range"
            //onClick={(event) => {
            //  setPopOverAnchorElement(event.currentTarget);
            // setOpen(!open);
            //}}
            title="Thresholds"
        >

        </IconButton>
        {/*<DateRangePicker*/}
        {/*  open={}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

ReportFilters.propTypes = {
  className: PropTypes.string
};

export default ReportFilters;
