import React, {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import md5 from 'md5';

import {DataService} from "../../../../../../App";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;


  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);


  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
  });
  const [account, setAccount] = useState({
    is_current_user_owner: false
  });



  useEffect(() => {
    //see this fix: https://stackoverflow.com/questions/56442582/react-hooks-cant-perform-a-react-state-update-on-an-unmounted-component
    let isCancelled = false;

    dataProvider.User().getCurrentUser().then((foundUser) => {
      if (!isCancelled) {
        setCurrentUser(foundUser);
      }
    });


    return () => {
      isCancelled = true;
    };

  }, [dataProvider]);


  useEffect(() => {
    //see this fix: https://stackoverflow.com/questions/56442582/react-hooks-cant-perform-a-react-state-update-on-an-unmounted-component
    let isCancelled = false;

    dataProvider.Account().getCurrentAccount().then((currentAccount) => {
      if (!isCancelled) {
        setAccount(currentAccount);
      }
    });

    return () => {
      isCancelled = true;
    };

  }, [dataProvider]);
  //let userInSession = UserService.getCurrentSession();
  let email = currentUser.email;
  let defaultAvatar = '/images/avatars/empty-user-icon.png';
  let size = '400';
  let gravatarParams = { 'd' : defaultAvatar, 's':size};
  let queryString = Object.keys(gravatarParams).map(function(key) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(gravatarParams[key])
  }).join('&');

  let gravatarUrl = 'https://www.gravatar.com/avatar/' + md5(email) + '?';
  gravatarUrl += queryString;

  const user = {
    name: currentUser.first_name + ' ' + currentUser.last_name,
    avatar: gravatarUrl,
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Profile image from gravatar.com"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/settings"
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{(account.is_current_user_owner)? 'Account Owner': 'Administrator'}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
