import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import md5 from 'md5';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from '@material-ui/core';

import validate from 'validate.js';
import ReactFlagsSelect from 'react-flags-select';


import Countries from '../../../../helpers/countries';

import googleLibPhoneNumber from 'google-libphonenumber';

import { loadReCaptcha } from 'react-recaptcha-v3'
import {config} from '../../../../config';
import withWidth from '@material-ui/core/withWidth';
import {DataService} from '../../../../App';


loadReCaptcha(config.reCaptchaSiteKey, () => {});


validate.validators.mobileNumberValidator = function(value, options, key, attributes) {
  let isValidMobile = false;
  try {
    let countryCode = 'AU';
    if (Object.prototype.hasOwnProperty.call(attributes,'country') && attributes.country !== null ){
      countryCode = attributes.country;
    }
    const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
    isValidMobile = phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, countryCode), countryCode);
  }catch{
    isValidMobile = false;
  }
  if (isValidMobile){
    return;
  } else {
    return 'is not a valid mobile number';
  }
};



const schema = {
  firstName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 32
    }
  },
  country: {
    presence: {allowEmpty: true, message: 'is required'},
    exclusion: {
      within: {'---': '---'},
      message: '^Country is Required'
    }
  },
  mobileNumber: {
    mobileNumberValidator: {isValidNumber: true},
    presence: { allowEmpty: false, message: 'is required' },
  },
}



const useStyles =  makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grid: {
    height: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  countryDropDownButton: {
    height: theme.spacing(6.75),
    '&::after': {
      display: 'none'
    }
  },
}));

const AccountDetails = props => {
  const { className, user, ...rest } = props;

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const classes = useStyles();
  //let defaultCountryCode = 'AU';
  //const [selectedCountry, setSelectedCountry] = useState(defaultCountryCode);
  // const [values, setValues] = useState(user);


  const userToFormValues = user => {
    return {
      firstName:  user.first_name,
      lastName: user.last_name,
      email: user.email,
      country: user.country_code.toUpperCase(),
      countryCode: user.country_code.toUpperCase(),
      mobileNumber: user.mobile_number,
      mfaProvider: user.mfa_provider
    }
  }
  const [formState, setFormState] = useState({
    isValid: false,
    values: userToFormValues(user),
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false
    }
  });

 // const [isSavingAccount, setIsSavingAccount] = useState(false);
 // const [showSuccessTick, setShowSuccessTick] = useState(false);

  const handleUserSaveSubmit = event => {
    //setIsSavingAccount(true);
    event.preventDefault();
    dataProvider.User().saveCurrentUser(
      formState.values.firstName,
      formState.values.lastName,
      formState.values.email,
      formState.values.country,
      formState.values.mobileNumber,
      'Australia/Brisbane'
    ).then( response => {
      //user saved
     // setIsSavingAccount(false);
     // setShowSuccessTick(true);
     // setTimeout(() => {setShowSuccessTick(false); }, 5000);
    }).catch( error => {
      //showError Message
      //showErro
    })
  };

  useEffect(() => {
    let newValues =  userToFormValues(user);
    setFormState(formState => ({
      ...formState,
      values: newValues
    }));

  }, [user]);


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };




  return (
    <Card
      {...rest}
    >
      <form
        onSubmit={handleUserSaveSubmit}
      >
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            className={classes.grid}
            container
            spacing={3}
          >
            <Grid
              className={classes.content}
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('firstName')}
                fullWidth
                helperText={
                  hasError('firstName') ? formState.errors.firstName[0] : null
                }
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={formState.values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('lastName')}
                fullWidth
                helperText={
                  hasError('lastName') ? formState.errors.lastName[0] : null
                }
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={formState.values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('email')}
                fullWidth
                helperText={
                  hasError('email') ? formState.errors.email[0] : null
                }
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={formState.values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              {/*<Grid*/}
              {/*  item*/}
              {/*  md={6}*/}
              {/*  xs={12}*/}
              {/*>*/}
              {/*  <TextField*/}
              {/*    fullWidth*/}
              {/*    label="Select State"*/}
              {/*    margin="dense"*/}
              {/*    name="state"*/}
              {/*    onChange={handleChange}*/}
              {/*    required*/}
              {/*    select*/}
              {/*    // eslint-disable-next-line react/jsx-sort-props*/}
              {/*    SelectProps={{ native: true }}*/}
              {/*    value={formState.values.state}*/}
              {/*    variant="outlined"*/}
              {/*  >*/}
              {/*    {states.map(option => (*/}
              {/*      <option*/}
              {/*        key={option.value}*/}
              {/*        value={option.value}*/}
              {/*      >*/}
              {/*        {option.label}*/}
              {/*      </option>*/}
              {/*    ))}*/}
              {/*  </TextField>*/}
              {/*</Grid>*/}
              <FormControl
                // className={classes.textField}
                className={classes.textField}
                error={hasError('country')}
                fullWidth
                variant="outlined"
              >
                <InputLabel id="sign-up-country-label">Country</InputLabel>
                <Select


                  label="Country"
                  labelId="sign-up-country-label"
                  name="country"
                  native
                  onChange={handleChange}
                  value={formState.values.country || '---' }
                  variant="outlined"
                >
                  <option
                    key={'--default--'}
                    value={'---'}>{'---'}</option>
                  {Countries.map(country =>
                    <option
                      key={md5(country.Name)}
                      value={country.Code}>{country.Name}</option>)}

                  {/*{Countries.map(country =>  <MenuItem key={country.Code} value={country.Code}>{country.Name}</MenuItem>)}*/}

                </Select>
                <FormHelperText id="input-country-helper-text">{hasError('country') ? formState.errors.country[0] : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Grid

                // className={classes.nameGrid}
                container
                spacing={ 3 }
              >
                <Grid
                  item
                  lg={2}
                  xs={4}
                >
                  <ReactFlagsSelect
                    selected={formState.values.country}
                    className={classes.textField}
                    alignOptionsToRight
                    selectButtonClassName={classes.countryDropDownButton}
                    fullWidth={true}
                    error={hasError('countryCode')}
                    onSelect={(code) => {
                      formState.values['countryCode'] = code;
                      setFormState(formState => ({
                        ...formState,
                        values: formState.values
                      }));
                      // setSelectedCountry(code);
                    }}
                    countries={Countries.map(item => item.Code)}
                    placeholder=" "
                    customLabels={
                      Countries.reduce((acc, item) => {
                        acc[item.Code] = {primary: '+' + item.SMSPrefix };
                        return acc;
                      }, {})
                    }
                  />
                </Grid>
                <Grid
                  className={classes.content}
                  item
                  lg={10}
                  xs={8}
                >
                  <TextField
                    className={classes.textField}
                    error={hasError('mobileNumber')}
                    fullWidth
                    helperText={
                      hasError('mobileNumber') ? formState.errors.mobileNumber[0] : null
                    }
                    label="Mobile Number"
                    name="mobileNumber"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.mobileNumber || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*<Grid*/}
            {/*  item*/}
            {/*  md={6}*/}
            {/*  xs={12}*/}
            {/*>*/}
            {/*  <FormControl*/}
            {/*    className={classes.textField}*/}
            {/*    error={hasError('countryCode')}*/}
            {/*    fullWidth*/}
            {/*    helperText={*/}
            {/*      hasError('countryCode') ? formState.errors.countryCode[0] : null*/}
            {/*    }*/}
            {/*    variant="outlined"*/}
            {/*  >*/}
            {/*    <InputLabel id="sign-up-country-label">Country</InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="sign-up-country-label"*/}
            {/*      native*/}
            {/*      helperText={*/}
            {/*        hasError('countryCode') ? formState.errors.countryCode[0] : null*/}
            {/*      }*/}
            {/*      label="Country"*/}
            {/*      name="countryCode"*/}
            {/*      onChange={handleChange}*/}
            {/*      value={formState.values.country_code || ''}*/}
            {/*      variant="outlined"*/}
            {/*    >*/}
            {/*      {Countries.map(country =>  <option value={country.Code}>{country.Name}</option>)}*/}

            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}

            {/*<Grid*/}
            {/*  item*/}
            {/*  md={6}*/}
            {/*  xs={12}*/}
            {/*>*/}
            {/*  <TextField*/}
            {/*    className={classes.textField}*/}
            {/*    error={hasError('mfaProvider')}*/}
            {/*    fullWidth*/}
            {/*    helperText={*/}
            {/*      hasError('mfaProvider') ? formState.errors.mfaProvider[0] : null*/}
            {/*    }*/}
            {/*    label="2FA Provider"*/}
            {/*    name="mfaProvider"*/}
            {/*    onChange={handleChange}*/}
            {/*    required*/}
            {/*    select*/}
            {/*    // eslint-disable-next-line react/jsx-sort-props*/}
            {/*    SelectProps={{ native: true }}*/}
            {/*    value={formState.values.mfaProvider}*/}
            {/*    variant="outlined"*/}
            {/*  >*/}
            {/*    {primary2FAProvider.map(option => (*/}
            {/*      <option*/}
            {/*        key={option.value}*/}
            {/*        value={option.value}*/}
            {/*      >*/}
            {/*        {option.label}*/}
            {/*      </option>*/}
            {/*    ))}*/}
            {/*  </TextField>*/}
            {/*</Grid>*/}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default withWidth()(withRouter(AccountDetails));
