
import {config} from '"../../config';
import Translations from '../../helpers/translations';


class User {
    username: '';
    email: '';
    first_name: '';
    last_name: '';
    mobile_number: '';
    is_active: true;
    created_at: '1970-01-01T00:00:00.000Z';
    updated_at: '1970-01-01T00:00:00.000Z';
    enable_2fa: false;
    country_code: '';
    timezone: '';

    constructor(obj) {
      obj && Object.assign(this, obj);
    }

  // let user = {
  //   username: '',
  //   email: '',
  //   first_name: '',
  //   last_name: '',
  //   mobile_number: '',
  //   is_active: true,
  //   created_at: '1970-01-01T00:00:00.000Z',
  //   updated_at: '1970-01-01T00:00:00.000Z',
  //   enable_2fa: false,
  //   country_code: '',
  //   timezone: '',
  // };
}

const apiUrl = config.cqsolaDataApi;
export default class UserService  {

  constructor(config) {
    if (config == null){
      config = {};
    }
    this.config = config;
  }

  getCurrentSession() {
    let localStorageSessionData = localStorage.getItem('user');
    if (!localStorageSessionData) {
      return null
    }
    let user = JSON.parse(localStorageSessionData);
    if (user && user.bearer_token_expiry) {
      return user;
    }
    return null
  }

  isAuthenticated() {
    let session = this.getCurrentSession();
    if (!session) {
      return false;
    }
    this.StartRefreshTokenWatcher().catch((err) => { this.logout() });
    let refreshTokenTimeInSeconds = parseInt(session.bearer_token_expiry) - Math.floor(Date.now() / 1000);
    return refreshTokenTimeInSeconds > 0
  };

  refreshToken() {
    let session = this.getCurrentSession();
    let service = this;
    return new Promise((resolve, reject) => {
      if (!session) {
        reject();
        return false;
      }
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      };
      return fetch(`${apiUrl}/refresh-token`, requestOptions)
        .then(service.config.afterFetch)
        .then(res => res.json())
        .then(response => {
          resolve(response);
        })
        .catch((err) => {
          //force logout
          reject(err);
        });
    });

  }

  login(emailAddress, password, reCaptchaToken) {
    let userService = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({email: emailAddress, password: password, recaptcha_token: reCaptchaToken})
      };
      return fetch(`${apiUrl}/login`, requestOptions)
        .then(res => res.json())
        .then(response => {

          if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
            reject(response.error);
            return;
          }
          userService.handleSuccessfulNewAuthToken(response, emailAddress)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch(error => {
          reject(error);
        });
    })

  };


  signUpBegin(
    emailAddress,
    firstName,
    lastName,
    countryCode,
    mobileNumber,
    password,
    passwordConfirm,
    agreedToTerms,
    inviteToken,
    reCaptchaToken,
  ) {
    let params = {
      email_address: emailAddress,
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      country_code: countryCode,
      password: password,
      password_confirm: passwordConfirm,
      recaptcha_token: reCaptchaToken,
      agreed_to_terms: agreedToTerms
    };
    if (typeof inviteToken == 'string' && inviteToken !== ''){
      params['invite_token'] = inviteToken;
    }
    let userService = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(params)
      };
      return fetch(`${apiUrl}/user/sign-up/begin`, requestOptions)
        .then(res => res.json())
        .then(response => {
          // eslint-disable-next-line no-prototype-builtins
          if (response.hasOwnProperty('error')
            && response.error != null
          ) {
            reject(response.error);
            return;
          }
          userService.handleSuccessfulNewAuthToken(response, emailAddress)
            .then((authResponse) => {
              resolve(authResponse);
            })
            .catch(error => {
              reject(error);
            })
        })
        .catch(error => {
          reject(error);
        });
    });
  };


  signUpTokenIsValid(token): Promise<boolean> {
    let service = this;
    let dataUrl = `${apiUrl}/user/sign-up/token-verify`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'post',
        body: JSON.stringify({token: token}),
        credentials: 'include',
      })
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch current user details');
          }
        })
        .then(function (response) {
          if (response.hasOwnProperty('error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            resolve(response.is_valid);
          }
        })
        .catch((error) => {
          reject(error);
        })

    });
  }


  signUpVerifyConfirmMFA(recaptchaToken, signUpToken, mfaToken): Promise<boolean>{
    //need to set temporary credentials at the positive confirmation on serverside
    let service = this;
    return new Promise((resolve, reject) => {
      let dataUrl = `${apiUrl}/user/sign-up/mfa-verify`;
      fetch(dataUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          recaptcha_token: recaptchaToken,
          sign_up_token: signUpToken,
          mfa_token: mfaToken,
        })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Could not verify, please check and try again');
            case 405:
              throw new Error('Too many attempts made. Please try again in 15min')
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(function (response) {
          service.handleSuccessfulNewAuthToken(response, 'forgotpassword')
            .then(() => {
              if (response.hasOwnProperty('error') && response.error != null) {
                reject(new Error(response.error));
              } else {
                resolve(response.is_valid);
              }
            })
            .catch((error) => {
              reject(error);
            });

        })
        .catch((error) => {
          reject(error);
        })

    });
  };


  handleSuccessfulNewAuthToken(response, userEmail) {
    return new Promise(((resolve, reject) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //     localStorage.setItem('user', JSON.stringify(user));
      // Redirect          *string            `json:"redirect"`
      // BearerToken       *string            `json:"bearer_token"`
      // BearerTokenExpiry *int64             `json:"bearer_token_expiry"`
      // Error             *map[string]string `json:"error"`
      //


      // if (response.error != null) {
      //   if (failureCallback != null) {
      //     failureCallback(response.error);
      //   }
      //   return;
      // }
      // //refresh 15 seconds before we the current token expires
      // let refreshTokenTimeInSeconds = parseInt(response.bearer_token_expiry) - Math.floor(Date.now() / 1000) - 15;
      // if (refreshTokenTimeInSeconds < 0) {
      //   //session has been invalidated
      //   UserService.logout();
      //   return;
      // }
      //
      let _redirect = '/';
      if (response != null &&
        Object.prototype.hasOwnProperty.call(response, 'redirect') !== false &&
        response.redirect != null
      ) {
        //the api is calling for a redirect, so the submitted cookies
        //need a 2fa verification
        if (response.redirect === '/v1/login/verify') {
          //this "/login/verify" is the path to MfaVerify View
          _redirect = '/login/verify';
        }
      }
      this.StartSession(userEmail, response, _redirect)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      // if (_redirect === '/') {
      //   UserService.getCurrentUser()
      //     .then((user) => {
      //       console.log(user);
      //       // if (!user.enable_2fa || user.mobile_number === ''){
      //       //   _redirect = '/login/setup-mobile-2fa';
      //       // }
      //       UserService.StartSession(userEmail, response, _redirect)
      //         .then((response) => {
      //           resolve(response);
      //         })
      //         .catch((err) => {
      //           reject(err);
      //         })
      //
      //     })
      //     .catch((err) => {
      //       reject(err);
      //       console.error(err);
      //     })
      //   //see if we need to setup 2fa, and direct to screen if needed.
      // }
      // else {
      //   UserService.StartSession(userEmail, response, _redirect)
      //     .then((response) => {
      //       resolve(response);
      //     })
      //     .catch((err) => {
      //       reject(err);
      //     });
      // }
    }));

  };

  setSessionInLocalStorage = (_username, _bearTokenExpiry) => {
    let userData = {
      username: _username,
      bearer_token_expiry: _bearTokenExpiry,
    };
    localStorage.setItem('user', JSON.stringify(userData));
  };

  StartRefreshTokenWatcher() {

    return new Promise((resolve, reject) => {
      if (window.tokenWatcherActive !== true){
        let user = JSON.parse(localStorage.getItem('user'));
        let refreshTokenTimeInSeconds = (58 * 60) ; //14 minutes (allow for upto 2min diff in server to client clock)
        //let refreshTokenTimeInSeconds =15 ; //seconds under 15minutes
        //try 3 times before failing.
        let failed = 0;
        window.tokenWatcherActive = true;
        const _refreshToken = () => {
          this.refreshToken().then((response) => {
            this.setSessionInLocalStorage(user.username, response.bearer_token_expiry);
            setTimeout(_refreshToken, refreshTokenTimeInSeconds * 1000);
          }).catch(() => {
            failed++;
            if (failed > 3) {
              //fail softly here and logout.
              reject(new Error('Attempts to start the user session failed 3 times, stopping attempts.'));
              return;
            } else {
              _refreshToken();
            }
          });
        };
        setTimeout(_refreshToken, refreshTokenTimeInSeconds * 1000);
      }
    });

  }

  StartSession(username, response, _redirect) {
    return new Promise((resolve, reject) => {
      this.setSessionInLocalStorage(username, response.bearer_token_expiry);
      this.StartRefreshTokenWatcher().catch((err) => { this.logout() });
      resolve({redirect: _redirect});
    });

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let dataUrl = `${apiUrl}/logout`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  loginResendMFA(email, mfaToken): Promise<boolean>{
    let userService = this;
    //need to set temporary credentials at the positive confirmation on serverside
    return new Promise((resolve, reject) => {
      let dataUrl = `${apiUrl}/login/verify`;
      fetch(dataUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          mfa_token: mfaToken,
        })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 404:
              throw new Error('Could not verify, please check and try again');
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(function (response) {
          userService.handleSuccessfulNewAuthToken(response, 'mfa_login')
            .then(() => {
              if (response.hasOwnProperty('error') && response.error != null) {
                reject(new Error(response.error));
              } else {
                resolve(response.is_valid);
              }
            })
            .catch((error) => {
              reject(error);
            });

        })
        .catch((error) => {
          reject(error);
        })

    });
  };


  loginConfirmMFA(mfaToken): Promise<boolean>{
    //need to set temporary credentials at the positive confirmation on serverside
    let userService = this;
    return new Promise((resolve, reject) => {
      let dataUrl = `${apiUrl}/login/verify`;
      fetch(dataUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          mfa_token: mfaToken,
        })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Could not verify, please check and try again');
            case 405:
              throw new Error('Too many attempts made. Please try again in 15min')
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(function (response) {
          userService.handleSuccessfulNewAuthToken(response, 'mfa_login')
            .then(() => {
              if (response.hasOwnProperty('error') && response.error != null) {
                reject(new Error(response.error));
              } else {
                resolve(response.bearer_token != null);
              }
            })
            .catch((error) => {
              reject(error);
            });

        })
        .catch((error) => {
          reject(error);
        })

    });
  };


  forgotPasswordResendMFA(recaptchaToken, forgotPasswordToken): Promise<boolean>{
    //need to set temporary credentials at the positive confirmation on serverside
    let dataUrl = `${apiUrl}/forgot-password/mfa-resend`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          recaptcha_token: recaptchaToken,
          forgot_password_token: forgotPasswordToken,
        })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not resend MFA');
          }
        })
        .then(function (response) {
          if (response.hasOwnProperty('error') && response.error != null) {
            reject(response.error);
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        })

    });
  };

  forgotPasswordConfirmMFA(recaptchaToken, forgotPasswordToken, mfaToken): Promise<boolean>{
    //need to set temporary credentials at the positive confirmation on serverside
    let userService = this;
    return new Promise((resolve, reject) => {
      let dataUrl = `${apiUrl}/forgot-password/mfa-verify`;
      fetch(dataUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          recaptcha_token: recaptchaToken,
          forgot_password_token: forgotPasswordToken,
          mfa_token: mfaToken,
        })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Could not verify, please check and try again');
            case 405:
              throw new Error('Too many attempts made. Please try again in 15min')
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(function (response) {
          userService.handleSuccessfulNewAuthToken(response, 'forgotpassword')
            .then(() => {
              if (response.hasOwnProperty('error') && response.error != null) {
                reject(new Error(response.error));
              } else {
                resolve(response.is_valid);
              }
            })
            .catch((error) => {
              reject(error);
            });

        })
        .catch((error) => {
          reject(error);
        })

    });
  };


  //reset password requires
  //  - a valid session cookie,
  //  - a valid reCaptchaToken,
  //  - a valid mfaVerificationToken
  //  - either forgot-password token or the current password
  resetPassword(
    reCaptchaToken,
    password,
    passwordConfirmation,
    mfaVerificationToken,
    forgotPasswordToken,
    currentPassword
  ): Promise {

    // on success, will send out MFA token via mobforgotPasswordile and expect that confirmation
    // in the next step to send reset email token
    let dataUrl = `${apiUrl}/user/reset-password`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          recaptcha_token: reCaptchaToken,
          password: password,
          password_confirm: passwordConfirmation,
          forgot_password_token: forgotPasswordToken,
          mfa_verify: mfaVerificationToken,
          current_password: currentPassword
        })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
            case 400:
              return response.json();
            case 403:
              return response.json();
            default:
              throw new Error('Could not start reset password');
          }
        })
        .then(function (forgotPasswordResponse) {
          if (forgotPasswordResponse.hasOwnProperty('error') && forgotPasswordResponse.error != null) {
            let errorText = '';
            switch(true) {
              case forgotPasswordResponse.error.hasOwnProperty('unauthorized'):
                errorText = forgotPasswordResponse.error.unauthorized;
                break;
              case forgotPasswordResponse.error.hasOwnProperty('internal_error'):
                errorText = forgotPasswordResponse.error.internal_error;
                break;
              default:
                errorText = forgotPasswordResponse.error.toString();
                break;

            }
            reject(new Error(errorText));
          } else {
            resolve(forgotPasswordResponse.result);
          }
        })
        .catch((error) => {
          reject(error);
        })

    });
  }

  forgotPassword(reCaptchaToken, emailAddress, mobileNumberFragment): Promise  {

    // on success, will send out MFA token via mobile and expect that confirmation
    // in the next step to send reset email token
    let dataUrl = `${apiUrl}/forgot-password/begin`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          recaptcha_token: reCaptchaToken,
          email: emailAddress,
          mobile_number_fragment: mobileNumberFragment
        })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not start forgot password process');
          }
        })
        .then(function (forgotPasswordResponse) {
          if (forgotPasswordResponse.hasOwnProperty('error') && forgotPasswordResponse.error != null) {
            reject(new Error(forgotPasswordResponse.error));
          } else {
            resolve(forgotPasswordResponse.result);
          }
        })
        .catch((error) => {
          reject(error);
        })

    });
  };

  forgotPasswordTokenIsValid(token): Promise<boolean> {
    let dataUrl = `${apiUrl}/forgot-password/token-verify`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'post',
        body: JSON.stringify({token: token}),
        credentials: 'include',
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch current user details');
          }
        })
        .then(function (response) {
          if (response.hasOwnProperty('error') && response.error != null) {
            reject(response.error);
          } else {
            resolve(response.is_valid);
          }
        })
        .catch((error) => {
          reject(error);
        })

    });
  }

  getCurrentUser(): Promise<User> {
    // let user = {
    //   username: '',
    //   email: '',
    //   first_name: '',
    //   last_name: '',
    //   mobile_number: '',
    //   is_active: true,
    //   created_at: '1970-01-01T00:00:00.000Z',
    //   updated_at: '1970-01-01T00:00:00.000Z',
    //   enable_2fa: false,
    //   country_code: '',
    //   timezone: '',
    // };


    return new Promise((resolve, reject) => {
      // const userSelflocalStorageKey = 'user_self'
      // let userSelfData = window.localStorage.getItem(userSelflocalStorageKey);
      // if (userSelfData != null  ) {
      //   let userSelf = new User(JSON.parse(userSelfData));
      //   let sessionUserData = UserService.getCurrentSession();
      //   if (sessionUserData.email !== userSelf.email){
      //     window.localStorage.setItem(userSelflocalStorageKey, null);
      //   } else {
      //     resolve(userSelf);
      //     return;
      //   }
      // }
      let dataUrl = `${apiUrl}/user/self`;
      let service = this;
      fetch(dataUrl, {
        method: 'get',
        credentials: 'include',
      })
        .then((response) => { return service.config.afterFetch(response);})
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch current user details');
          }
        })
        .then(function (response) {

          if (response.hasOwnProperty('error') && response.error != null) {
            reject(response.error);
          } else {
            //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
            resolve(new User(response.user));
          }
        })
        .catch((error) => {
          reject(error);
        })

    });

  }


  saveCurrentUser(
    firstName,
    lastName,
    email,
    countryCode,
    mobileNumber,
    timeZone
  ): Promise<User> {
    let userData = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      country_code: countryCode,
      timezone: timeZone,
    };
    let dataUrl = `${apiUrl}/user/self`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ user: userData })
      })
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not save current user details');
          }
        })
        .then(function (userSelfResponse) {
          if (userSelfResponse.hasOwnProperty('error') && userSelfResponse.error != null) {
            reject(new Error(userSelfResponse.error));
          } else {
            resolve(new User(userSelfResponse.user));
          }
        })
        .catch((error) => {
          reject(error);
        })

    });

  }
}

export {UserService, User};
