import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardActions,
  Divider,
  Button,
  Typography
} from '@material-ui/core';

import validate from 'validate.js';

import withWidth from '@material-ui/core/withWidth';
import ConfirmDialogWithPasswordVerify from '../../../../components/ConfirmDialogWithPasswordVerify';
import moment from 'moment'
import {DataService} from '../../../../App';


const schema = {
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 128
    }
  },

}

const action = {NONE: 'none', DELETE: 'delete', CANCEL: 'cancel'};

const useStyles =  makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },

  deleteButton: {
    backgroundColor: theme.palette.error.dark,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grid: {
    height: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  countryDropDownButton: {
    height: theme.spacing(6.75),
    '&::after': {
      display: 'none'
    }
  },
}));
const AccountDelete = props => {
  const {
    reCaptchaToken, reCaptchaForceReset, setReCaptchaForceReset } =
      props;
  const dataProvider = React.useContext(DataService);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  // const [confirmTransferOpen, setConfirmTransferOpen] = React.useState(false);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [waitingOnNetworkRequest, setWaitingOnNetworkRequest] = useState(false);
  const [userAction, setUserAction] = useState(action.NONE);

  const [isDeletePending, setIsDeletePending] = useState(false);
  const [deleteAt, setDeleteAt] = useState(moment().add(24, 'hour'));
  const classes = useStyles();


  useEffect(()=> {

    dataProvider.Account().deleteCurrentAccountStatus()
      .then(accountStatus => {
        setIsDeletePending(accountStatus.is_delete_pending)
        setDeleteAt(accountStatus.delete_at)
      })
      .catch(err => {})
  }, [dataProvider])

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: ''
    },
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false,
      captchaResetCallback: (token) => {}
    }
  });

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // const reCaptchaRef = React.useRef();
  //
  // const verifyReCaptchaCallback = (recaptchaToken) => {
  //   setFormState(formState => ({
  //     ...formState,
  //     reCaptcha: {
  //       ...formState.reCaptcha,
  //       token: recaptchaToken
  //     },
  //   }));
  //   formState.reCaptcha.captchaResetCallback(recaptchaToken)
  // };
  //
  // useEffect(() => {
  //   if (reCaptchaRef.current) {
  //     reCaptchaRef.current.execute();
  //   }
  // }, [ formState.reCaptcha.forceReset]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);


  useEffect(() => {
    //if formstate is valid
    if ( reCaptchaToken !== null && !waitingOnNetworkRequest ) {
      switch(userAction){
        case action.DELETE:
          let errors = validate(formState.values, schema);
          if (errors != null){
            //error validating
            return;
          }
          setWaitingOnNetworkRequest(true);
          dataProvider.Account().deleteCurrentAccountStart(formState.values.password, reCaptchaToken).then((response) => {
            //mark as delete scheduled
            setConfirmOpen(false);
            setIsDeletePending(true);
          }).catch(error => {
            let errors = {
              password: [error.message]
            };
            setFormState(formState => ({
              ...formState,
              isValid: errors ? false : true,
              errors: errors || {}
            }));
          }).finally(() => {
            setUserAction(action.NONE);
            setWaitingOnNetworkRequest(false);
          });
          break;
        case action.CANCEL:
          setWaitingOnNetworkRequest(true);
          dataProvider.Account().deleteCurrentAccountCancel(reCaptchaToken).then((response) => {
            //mark as delete scheduled
            setIsDeletePending(false);
          }).catch(error => {
            //show cancel error
          }).finally(() => {
            setUserAction(action.NONE);
            setWaitingOnNetworkRequest(false);
          });
          break;
        default:
          break;
      }

    }
// eslint-disable-next-line
  }, [reCaptchaToken, formState.values, userAction ]);

  return (
    <>
      <Card      >
        <CardHeader
          subheader="Delete Account and remove all data"
          title="Delete Account"
        />
        <Divider />
        <CardActions>
          {(isDeletePending)?
            <>
              <Button
                color="primary"
                onClick={() => {
                  setUserAction(action.CANCEL);
                  setReCaptchaForceReset(!reCaptchaForceReset);
                }}
                variant="contained"
              >
                Cancel Delete Account
              </Button>
              <Typography>
                Pending Delete by { deleteAt.format('DD/MM/YYYY h:mm:ss a')}
              </Typography>
            </>
            :
            <Button
              className={classes.deleteButton}
              color="primary"
              onClick={() => {setConfirmOpen(true)}}
              variant="contained"
            >
              Delete Account
            </Button>
          }

          <ConfirmDialogWithPasswordVerify
            hasError={hasError('password')}
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            onConfirm={(password) => {
              setFormState(formState => ({
                ...formState,
                values: {
                  ...formState.values,
                  password: password
                },
                touched: {
                  ...formState.touched,
                  password: true
                }
              }));
              //sets password and forces recaptcha to refresh. This triggers useEffect above
              setUserAction(action.DELETE);
              setReCaptchaForceReset(!reCaptchaForceReset);
            }}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            title="Delete Account"
            validationText={'Current Password'}
          >
            <Typography
              variant="body1"
            >
              Are you sure you want to delete account?
            </Typography>
          </ConfirmDialogWithPasswordVerify>

        </CardActions>
      </Card>
    </>
  );
};

AccountDelete.propTypes = {
  className: PropTypes.string,
  account: PropTypes.object,
  reCaptchaToken: PropTypes.string,
  reCaptchaForceReset: PropTypes.bool,
  setReCaptchaForceReset: PropTypes.func
};

export default withWidth()(withRouter(AccountDelete));
