import {config} from '"../../config';
import Translations from '../../helpers/translations';
//import {CommsUnit, CommsUnitList} from "../CommsUnitService/CommsUnitService";

//import parseDateRFC3339 from '../../helpers/parseDateRFC3339';

class DeviceEventLog {
    id: ""
    install_id: ""
    serial_number: ""
    detected_at: null
    severity: ""
    log_type: ""
    message: ""
    created_at: null

    constructor(obj) {
      obj && Object.assign(this, obj);
    }


}
class DeviceEventLogList {
  data: Array<DeviceEventLog>;
  meta: {};
}

const apiUrl = config.cqsolaDataApi;
export default class DeviceEventLogService {

  constructor(config) {
    if (config == null){
      config = {};
    }
    this.config = config;
  }

  list(installId, from, to, query): Promise<DeviceEventLogList> {

    let dataUrl = `${apiUrl}/device-event-log/list`
    let service = this;
    if (query == null ){
      query = {};
    }
    if (!Object.prototype.hasOwnProperty.call(query, 'filter')){
      query.filter = {};
    }

    let toIsoString = (date) => {


      let tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
          return (num < 10 ? '0' : '') + num;
        };
      return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
    }

    let filter = {
      install_id: installId,
      detected_at: {
        from: toIsoString(from),
        to:  toIsoString(to),
      },
    };

    query.filter = {
      ...query.filter,
      ...filter
    }

    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch device event logs');
          }
        })
        .then(function (response) {
          // let expectedResponseFields = [
          //   'error',
          //   'meta',
          //   'comms_units'
          // ];
          //
          // let isValidResponse = !expectedResponseFields.map((field) => {
          //   return Object.prototype.hasOwnProperty.call(response, field)
          // }).includes(false);
          //
          // if (!isValidResponse){
          //   reject(Translations.Errors.UnExpectedResponse);
          // }
          if (response.hasOwnProperty('error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            //validate response
            if (!Array.isArray(response.device_event_logs)){
              reject(new Error(Translations.Errors.UnExpectedResponse));
            }
            let output = new DeviceEventLogList();
            output.data = [];
            for (let i =0;i<response.device_event_logs.length; i++){
              //response.device_event_logs[i].detected_at = parseDateRFC3339(response.device_event_logs[i].detected_at )
              //response.device_event_logs[i].created_at  = parseDateRFC3339(response.device_event_logs[i].created_at )

              output.data[i] =new DeviceEventLog(response.device_event_logs[i])
            }
            output.meta = response.meta;
            resolve(output);
          }
        })
        .catch((error) => {
          reject(error);
        })

    });

  }
  //
  // static register = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };
  //
  // static get = function (): Promise<CommsUnit> {
  //   return new Promise((resolve, reject) => {
  //     // const userSelflocalStorageKey = 'user_self'
  //     // let userSelfData = window.localStorage.getItem(userSelflocalStorageKey);
  //     // if (userSelfData != null  ) {
  //     //   let userSelf = new User(JSON.parse(userSelfData));
  //     //   let sessionUserData = UserService.getCurrentSession();
  //     //   if (sessionUserData.email !== userSelf.email){
  //     //     window.localStorage.setItem(userSelflocalStorageKey, null);
  //     //   } else {
  //     //     resolve(userSelf);
  //     //     return;
  //     //   }
  //     // }
  //     let dataUrl = `${apiUrl}/user/self`;
  //     fetch(dataUrl, {
  //       method: 'get',
  //       credentials: 'include',
  //     })
  //       .then((response) => {
  //         switch (response.status) {
  //           case 200:
  //             return response.json();
  //           case 403:
  //             throw new Error('Unauthorised to Access Resource');
  //           default:
  //             throw new Error('Could not fetch current user details');
  //         }
  //       })
  //       .then(function (userSelfResponse) {
  //         if (userSelfResponse.hasOwnProperty('error') && userSelfResponse.error != null) {
  //           reject(new Error(userSelfResponse.error));
  //         } else {
  //           //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
  //           resolve(new User(userSelfResponse.user));
  //         }
  //       })
  //       .catch((error) => {
  //         reject(new Error(error));
  //       })
  //
  //   });
  // }
  //
  // static release = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };


}

export {DeviceEventLogService};
