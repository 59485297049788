import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Typography
} from '@material-ui/core';

import validate from 'validate.js';

import { loadReCaptcha } from 'react-recaptcha-v3'
import {config} from '../../../../config';
import withWidth from '@material-ui/core/withWidth';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {Alert} from '@material-ui/lab';
import Translations from '../../../../helpers/translations';

//import {SessionService} from '../../../../data/SessionService/SessionService';
import {UserApiToken} from '../../../../data/UserApiTokenService/UserApiTokenService';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import ConfirmDialogWithPasswordVerify from '../../../../components/ConfirmDialogWithPasswordVerify';
import {DataService} from '../../../../App';

loadReCaptcha(config.reCaptchaSiteKey, () => {});

const ipv4RegexExp =/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/[0-9]?[0-9])?$/
const ipv6RegexExp = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(\/[0-9]?[0-9]?[0-9])?/gi;

const validateIp = (ipAddress) => {
  switch(true){
    case ipAddress === '127.0.0.1':
    case ipAddress === '127.0.0.1/0':
    case ipAddress === '127.0.0.1/8':
    case ipAddress === '127.0.0.1/16':
    case ipAddress === '127.0.0.1/24':
    case ipAddress === '127.0.0.1/32':
    case ipAddress === '0.0.0.0':
    case ipAddress === '0.0.0.0/0':
    case ipAddress === '0.0.0.0/8':
    case ipAddress === '0.0.0.0/16':
    case ipAddress === '0.0.0.0/24':
    case ipAddress === '0.0.0.0/32':
    case ipAddress === '::1':
    case ipAddress === '::1/128':
      throw new Error('loopback addresses not valid');
    case ipv4RegexExp.test(ipAddress):
      break;
    case ipv6RegexExp.test(ipAddress):
      break;
    default:
      throw new Error('is not valid');
  }
}
validate.validators.ipValidator = function(value, options, key, attributes) {
  let isValidIpRoute = false;

  try {
    validateIp(value);
    isValidIpRoute = true;
  }
  catch(err) {
    isValidIpRoute = false;
    return err.message;
  }
  if (isValidIpRoute){
    return;
  } else {
    return 'is not valid';
  }
}
validate.validators.ipListValidator = function(value, options, key, attributes) {
  let isValidIpRoute = false;

  try {
    let ipAddresses = value.split(',');
    for (let i = 0; i < ipAddresses.length; i++) {
      validateIp(ipAddresses[i])
    }
    isValidIpRoute = true;
  }
  catch(err) {
    isValidIpRoute = false;
    return err.message;
  }
  if (isValidIpRoute){
    return;
  } else {
    return 'is not valid';
  }
};

const initialSchema = {
  name: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 32
    }
  },
  isActive: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  restrictToIp: {
    presence: {allowEmpty: false, message: 'is required'},
    ipListValidator: {isValidIpRoute: true},
  },
  notes: {
    presence: false,
  },
}

let schema = {...initialSchema};



const useStyles =  makeStyles(theme => ({
  // root: {
  //   backgroundColor: theme.palette.background.default,
  //   height: '100%'
  // },

  root: {
    padding: theme.spacing(4),
  },
  deleteButton: {
    backgroundColor: theme.palette.error.dark,
  },
  topHeader: {
    marginTop: theme.spacing(2),
  },
  addMyIpBtn: {
    marginTop: theme.spacing(2),
    marginLeft:  theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grid: {
    height: '100%'
  },
  addIpBtn: {
    marginTop: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  countryDropDownButton: {
    height: theme.spacing(6.75),
    '&::after': {
      display: 'none'
    }
  },
}));

const ApiAccessDetails = props => {
  const { className, userApiToken, ...rest } = props;

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const classes = useStyles();

  const [ipAddressList, setIpAddressList] = useState([]);

  const [currentIpAddress, setCurrentIpAddress] = useState([]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmOnceOffOpen, setConfirmOnceOffOpen] = useState(false);

  const [onceOffPassword, setOnceOffPassword] = useState('');

  const [onSaveErr, setOnSaveErr] = useState('');


  const formRef = React.createRef();


  useEffect(() => {
    dataProvider.Session().getSessionContext().then(context => {
      setCurrentIpAddress(context.ip);
    })

  }, [dataProvider, userApiToken]);


  const userApiTokenToFormValues = _userApiToken => {
    _userApiToken =  _userApiToken ?? {};

    return {
      id:           (Object.prototype.hasOwnProperty.call(_userApiToken, 'id'))?             _userApiToken.id : null,
      name:         (Object.prototype.hasOwnProperty.call(_userApiToken, 'name'))?           _userApiToken.name : null,
      restrictToIp: (Object.prototype.hasOwnProperty.call(_userApiToken, 'restrict_to_ip'))? _userApiToken.restrict_to_ip : '', //'127.0.0.1,::1',
      isActive:     (Object.prototype.hasOwnProperty.call(_userApiToken, 'is_active'))?      Number(_userApiToken.is_active) : 1,
      notes:        (Object.prototype.hasOwnProperty.call(_userApiToken, 'notes'))?          _userApiToken.notes : null,
      updatedAt:    (Object.prototype.hasOwnProperty.call(_userApiToken, 'updated_at'))?     _userApiToken.updated_at : Date.now(),
      createdAt:    (Object.prototype.hasOwnProperty.call(_userApiToken, 'created_at'))?     _userApiToken.created_at : Date.now(),
    }
  }
  const [formState, setFormState] = useState({
    isValid: false,
    values: userApiTokenToFormValues(userApiToken ),
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false
    }
  });

  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const isUUID = (candidate) => {
    switch (candidate){
      case null:
      case '':
        return false;
      default:
        return regexExp.test(candidate);
    }
  }

  const handleDeleteConfirm = () => {
    if (!isUUID(formState.values.id)){
      return;
    }
    dataProvider.UserApiToken().delete(formState.values.id)
      .then( result => {
        props.history.push('/api-access/list');
      })
      .catch( (err) => {
        setOnSaveErr('Error Saving Token');
      })
      .finally( () => {
        setConfirmOpen(false);
      })
  }

  const submitApiAccessForm = password => {

    let tokenToSave = new UserApiToken({
      id: formState.values.id,
      name: formState.values.name,
      restrict_to_ip: formState.values.restrictToIp,
      is_active: Number(formState.values.isActive) === 1,
      notes: formState.values.notes
    });

    dataProvider.UserApiToken().save(tokenToSave, password)
      .then( result => {
        //for new tokens, show the token secret once.
        if (Object.prototype.hasOwnProperty.call(result, 'token_secret') &&
          result.token_secret !== null &&
          result.token_secret.length > 0) {
          setOnceOffPassword(result.token_secret);
          setConfirmOnceOffOpen(true);
        } else {
          props.history.push('/api-access/list');
        }
      })
      .catch( (err) => {
        setOnSaveErr('Error Saving Token');
      })
      .finally( () => {
        setConfirmOpen(false);
      })
  }

  const handleApiAccessSubmit = event => {
    event.preventDefault();
  };

  useEffect(() => {
    let newValues = userApiTokenToFormValues(userApiToken);
    setFormState(formState => ({
      ...formState,
      values: newValues
    }));
    setIpAddressList(newValues.restrictToIp.split(','));
  }, [userApiToken ]);

  useEffect(() => {
    const errors = validate(formState.values, schema);


    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const handleChange = event => {
    event.persist();

    //collect ip address and serialise
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  const renderTokenAgeWarning = (apiTokenCreatedAt) => {
    if (!(apiTokenCreatedAt instanceof Date && !isNaN(apiTokenCreatedAt))){
      return <></>
    }
    let OneYearAgo = new Date(Date.now());
    OneYearAgo.setMonth(OneYearAgo.getMonth() - 12);

    let SixMonthsAgo = new Date(Date.now());
    SixMonthsAgo.setMonth(SixMonthsAgo.getMonth() - 6);

    switch (true) {
      case OneYearAgo > apiTokenCreatedAt:
        return <Alert
          severity="error"
          style={{ marginTop: '1rem' }}
        >{Translations.Warnings.ApiAccessTokenAgeNeedsRenewal}</Alert>;
      case SixMonthsAgo > apiTokenCreatedAt:
        return <Alert
          severity="warning"
          style={{ marginTop: '1rem' }}
        >{Translations.Warnings.ApiAccessTokenAgeConsiderRenewal}</Alert>;
      default:
        return <></>;
    }
  }

  useEffect(() => {

    let newValues = {
      ...formState.values,
      restrictToIp: ipAddressList.join(',')
    };

    let newTouched = { ...formState.touched };

    for (let newValueIndex in newValues) {
      if (!Object.prototype.hasOwnProperty.call(newValues, newValueIndex)){
        continue;
      }
      let indexString  = newValueIndex.toString();
      let restrictToIpValueKey = 'restrictToIp';
      if (indexString.substring(0,restrictToIpValueKey.length) === restrictToIpValueKey){
        let restrictToIpFieldId = parseInt(indexString.substring(restrictToIpValueKey.length, indexString.length))
        if ( restrictToIpFieldId < 0) {
          continue;
        }
        if (restrictToIpFieldId > ipAddressList.length -1) {
          delete newValues[newValueIndex]
          delete newTouched[newValueIndex];
        }
      }
    }

    schema = {...initialSchema};
    for (let i=0;i<ipAddressList.length;i++) {
      schema['restrictToIp' + i.toString()] = {
        presence: {allowEmpty: false, message: 'is required'},
        ipValidator: {isValidIpRoute: true},
      };
    }



    for (let i =0; i < ipAddressList.length; i++){
      newValues['restrictToIp' + i.toString()] = ipAddressList[i];
    }
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      values: newValues,
      touched: newTouched,
      errors: errors || {}
    }));

  },
  // eslint-disable-next-line
  [ipAddressList]);

  // handle click event of the Remove button
  const removeIpAddress = index  => {
    const list = [...ipAddressList];
    list.splice(index, 1);
    setIpAddressList(list);

  };
  // handle input change
  const handleIpAddressInputChange = (e, index) => {

    //const { name, value } = e.target;
    const value = e.target.value;
    const list = [...ipAddressList];
    list[index] = value.replace(/"|,|;|_|`|~|!|@|#|\$|%|\^|&|\*|\(|\)|-|_|\+|=|{|}|\[|]|'|\?|>|<|\||\\/g,'');//.replace(/,_;\[\]\(\)!@#\$%\^&*-_=+\*-'"|\\<>?/g, '');

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        ['restrictToIp' + index.toString()]: value
      },
      touched: {
        ...formState.touched,
        ['restrictToIp' +  index.toString()]: true
      }
    }));

    setIpAddressList(list);

  };

  // handle click event of the Add button
  const addIpAddress = () => {
    setIpAddressList([...ipAddressList, '']);

  };

  // handle click event of the Add button
  const addCurrentIpAddress = () => {

    setIpAddressList([...ipAddressList, currentIpAddress  + '/32']);

  };


  const renderIpAddressRow = (ipAddress, index) => {

    return <>
      <Grid
        alignContent="center"
        // className={classes.grid}
        alignItems="center"
        className={className}
        container
        direction="row"
        justify="flex-end"
        // spacing={2}
      >
        <Grid
          item
          lg={8}
          xs={8}
        >
          <TextField
            className={classes.textField}
            error={hasError('restrictToIp' + index.toString())}
            fullWidth
            helperText={
              hasError('restrictToIp' + index.toString()) ? formState.errors['restrictToIp' + index.toString()][0] : null
            }
            label=""
            name={'restrictToIp' + index.toString()}
            onChange={e => handleIpAddressInputChange(e, index)}
            type="text"
            value={ipAddress || ''}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          lg={4}
          xs={4}
        >
          <IconButton
            aria-label="delete"
            onClick={() => removeIpAddress(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  };


  return (
    <Card
      {...rest}
    >
      <form
        onSubmit={handleApiAccessSubmit}
        ref={formRef}
      >
        <CardHeader
          subheader="Integrate your solar data with your systems"
          title="Api Access"
        />
        <Divider />
        <CardContent>

          <Grid
            className={classes.grid}
            container
            spacing={3}
          >
            <Grid
              className={classes.content}
              item
              lg={12}
              xs={12}
            >
              <Alert
                severity="warning"
                style={{ marginTop: '1rem' }}
              >{Translations.Warnings.ApiAccessUserLimit}</Alert>
              {renderTokenAgeWarning(formState.values.createdAt)}
            </Grid>
            {(formState.values.id) ?
              <Grid
                className={classes.content}
                item
                lg={12}
                xs={12}
              >
                <Typography variant={'body2'}>Api Key Id</Typography>
                <Typography variant={'subtitle2'}>
                  {formState.values.id || ''}
                </Typography>
              </Grid>
              :
              <></>
            }

            <Grid
              className={classes.content}
              item
              lg={12}
              xs={12}
            >

              <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                  hasError('name') ? formState.errors.name[0] : null
                }
                label="Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.content}
              item
              lg={12}
              xs={12}
            >

              <FormControl
                className={classes.textField}
                error={hasError('isActive')}
                fullWidth
                helperText={
                  hasError('isActive') ? formState.errors.isActive[0] : null
                }
                variant="outlined"
              >
                <InputLabel id="api-access-edit-is-active-label">Is Active</InputLabel>
                <Select
                  helperText={
                    hasError('isActive') ? formState.errors.isActive[0] : null
                  }
                  label="Is Active"
                  labelId="api-access-edit-is-active-label"
                  name="isActive"
                  native
                  onChange={handleChange}

                  value={formState.values.isActive}
                  variant="outlined"
                >
                  <option
                    key={'active'}
                    value={1}
                  >Active</option>
                  <option
                    key={'inactive'}
                    value={0}
                  >Inactive</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              className={classes.content}
              item
              lg={12}
              xs={12}
            >

              {/*{JSON.stringify(formState.values)}*/}
              {/*{JSON.stringify(formState.touched)}*/}
              {/*{JSON.stringify(schema)}*/}

              <Typography variant={'body2'}>Created At</Typography>
              <Typography variant={'subtitle2'}>
                {moment(formState.values.createdAt).format('DD/MM/YYYY hh:mm A Z')}
              </Typography>


            </Grid>
            <Grid
              className={classes.content}
              item
              lg={12}
              xs={12}
            >

              <Typography variant={'body2'}>Last Updated At</Typography>
              <Typography variant={'subtitle2'}>
                {moment(formState.values.updatedAt).format('DD/MM/YYYY hh:mm A Z')}
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
            >

              <Typography
                className={classes.topHeader}
                variant={'body2'}
              >
                Restrict To Ip/s
              </Typography>
              {
                ipAddressList.map( (ipAddress, i) => {
                  return renderIpAddressRow(ipAddress, i)
                })
              }
              <Button
                className={classes.addIpBtn}
                color="primary"
                onClick={addIpAddress}
                variant="contained"
              >
                Add Ip
              </Button>
              <Button
                className={classes.addMyIpBtn}
                color="primary"
                disabled={ipAddressList.filter(ip => ip === currentIpAddress || ip ===  currentIpAddress + '/32').length > 0}
                onClick={addCurrentIpAddress}
                variant="contained"
              >
                Add My Ip
              </Button>
              {hasError('restrictToIp') ?
                <Alert
                  severity="error"
                  style={{ marginTop: '1rem' }}
                >{formState.errors.restrictToIp[0]}</Alert>
                : <></>}

            </Grid>

            <Grid
              className={classes.content}
              item
              lg={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('notes')}
                fullWidth
                helperText={
                  hasError('notes') ? formState.errors.notes[0] : null
                }
                label="Notes"
                multiline
                name="notes"
                onChange={handleChange}
                type="text"
                value={formState.values.notes || '\r\r\r'}
                variant="outlined"
              />
            </Grid>

          </Grid>

        </CardContent>
        <Divider />
        <CardActions >
          {(onSaveErr !== '')? <><Alert
            severity="error"
            style={{ marginTop: '1rem' }}
          >{onSaveErr}</Alert><br/></>: <></>}

          <Button
            color="primary"
            onClick={event => {
              setOnSaveErr('');
              let valuesToSave = { ...formState.values};
              valuesToSave['restrictToIp'] = formState.values.restrictToIp.split(',').map(ipAddressCandidate  => ipAddressCandidate.trim() ).filter( ipAddressCandidate => ipAddressCandidate !== '').join(',');

              const errors = validate(valuesToSave, initialSchema);
              if (errors) {
                setFormState(formState => ({
                  ...formState,
                  values: valuesToSave,
                  touched: {
                    ...formState.touched,
                    name: true,
                    restrictToIp: true,
                  },
                  isValid: errors ? false : true,
                  errors: errors || {}
                }));
                return;
              }
              setConfirmOpen(true)
            }}
            variant="contained"
          >
            Save
          </Button>
          <Button
            className={classes.deleteButton}
            color="primary"
            onClick={event => {setConfirmDeleteOpen(true)}}
            variant="contained"
          >
            Delete
          </Button>
        </CardActions>
      </form>
      <ConfirmDialog
        onConfirm={handleDeleteConfirm}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        title="Delete Api Access"
      >
        <Typography
          variant="body1"
        >
          Are you sure?
        </Typography>
        <Typography
          variant="subtitle2"
        >
          Deleting Api Token will also remove access from any program setup to use this token.
        </Typography>
      </ConfirmDialog>
      <ConfirmDialogWithPasswordVerify
        hasError={hasError('password')}
        helperText={
          hasError('password') ? formState.errors.password[0] : null
        }
        onConfirm={(password) => {
          submitApiAccessForm(password);
          // formRef.submit();
          //form.submit();
        }}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="Api Access Change"
        validationText={'Current Password'}
      >
        <Typography
          variant="body1"
        >
          Enter your password to continue.
        </Typography>
      </ConfirmDialogWithPasswordVerify>
      <ConfirmDialog
        disableBackdropClick
        disableNoButton
        hasError={hasError('password')}
        helperText={
          hasError('password') ? formState.errors.password[0] : null
        }
        onConfirm={() => {
          setConfirmOnceOffOpen(false);
          props.history.push('/api-access/list');
        }}
        open={confirmOnceOffOpen}
        setOpen={setConfirmOnceOffOpen}
        title="Api Access"
        validationText={'Current Password'}
      >
        <Typography
          variant="body1"
        >
          This api password will only be shown once, please copy it now and store it securely. It will not be shown again.
        </Typography>
        <Typography
          variant="h4"
        >
          {onceOffPassword}
        </Typography>
      </ConfirmDialog>
    </Card>

  );
};

ApiAccessDetails.propTypes = {
  className: PropTypes.string
};

export default withWidth()(withRouter(ApiAccessDetails));
