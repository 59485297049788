import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { AccountDetails, AccountTransfer, AccountDelete } from './components';
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3';
import {config} from '../../config';
import {DataService} from '../../App';


loadReCaptcha(config.reCaptchaSiteKey, () => {});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = () => {
  const classes = useStyles();
  const dataProvider = React.useContext(DataService);
  let [account, setAccount] = useState({});
  useEffect(() => {
    dataProvider.Account().getCurrentAccount().then((account) => {
      setAccount(account);
    }).catch( error => {

    })
  }, [dataProvider]);

  const reCaptchaRef = React.useRef();
  let [reCaptchaForceReset, setReCaptchaForceReset] = useState(false);
  //let [reCaptchaCallback, setReCaptchaCallback] = useState((token) => {});
  let [reCaptchaToken, setReCaptchaToken] = useState('');
  useEffect(() => {
    if (reCaptchaRef.current) {
      reCaptchaRef.current.execute();
    }
  }, [reCaptchaForceReset]);
  //
  const verifyReCaptchaCallback = (_recaptchaToken) => {
    setReCaptchaToken(_recaptchaToken)
  };


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={7}
          xs={12}
        >
          <AccountDetails account={account} />
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
        >
          <AccountTransfer
            account={account}
            reCaptchaToken={reCaptchaToken}
            //setReCaptchaCallback={setReCaptchaCallback}
            reCaptchaForceReset={reCaptchaForceReset}
            setReCaptchaForceReset={setReCaptchaForceReset}
          />
        </Grid>
        {/*<Grid*/}
        {/*  item*/}
        {/*  md={7}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <AccountBackup account={account} />*/}
        {/*</Grid>*/}
        <Grid
          item
          md={7}
          xs={12}
        >
          <AccountDelete
            account={account}
            reCaptchaToken={reCaptchaToken}
            //setReCaptchaCallback={setReCaptchaCallback}
            reCaptchaForceReset={reCaptchaForceReset}
            setReCaptchaForceReset={setReCaptchaForceReset}
          />
        </Grid>
      </Grid>
      <ReCaptcha
        ref={ reCaptchaRef }
        sitekey={config.reCaptchaSiteKey}
        action='account_transfer'
        verifyCallback={verifyReCaptchaCallback}
      />
    </div>

  );
};

export default Settings;
