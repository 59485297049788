import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,Grid, Typography, Avatar
} from '@material-ui/core';
import moment from 'moment';
import {config} from '../../../../config';
import BoltIcon from "@material-ui/icons/OfflineBolt";


const useStyles = makeStyles(theme => ({
  root: {
    //height: '100%'
    height: theme.spacing(12),
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));


const StartFetchLatestEnergyData = (from, to) => {

  return new Promise((resolve, reject) => {
    let params = {
      // "data_type": query.dataType,
      'filter[from]': moment(from).format(),
      'filter[to]': moment(to).format(),
      'data_type': 'watts',
      'group_by': 'day',
      'totals': 'peak',
      'limit': 10000000
    };


    let queryString = Object.keys(params).map(function(key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');

    let dataUrl = config.cqsolaDataApi + '/installation/total?' + queryString;

    fetch(dataUrl, {
      method: 'get',
      credentials: 'include',
    })
      .then(function(res) {
        return res.json();
      })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });



};

const TotalDailyEnergy = props => {
  const { className, ...rest } = props;

  const classes = useStyles();




  let now =  new Date(Date.now());
  let newFromTime = new Date(
      now.getFullYear() , //-20% of the diff rounded.
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0,
      0
  );
  let newToTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      0
  );

  // eslint-disable-next-line
  const [endDate, setEndDate] = useState(newToTime);
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(newFromTime);

  const [total, setTotal] = useState({"total" : "-", "units": "kWhr"});


  useEffect(() => {
    StartFetchLatestEnergyData(startDate, endDate)
      .then(energyData => {
        if (Array.isArray(energyData) && energyData.length > 0){
          setTotal(energyData[0])
        }
      })
  }, [startDate, endDate]);

  return (
      <Card
          {...rest}
          className={clsx(classes.root, className)}
      >
        <CardContent>
          <Grid
              container
              justify="space-between"
          >
            <Grid item>
              <Typography
                  className={classes.title}
                  color="inherit"
                  gutterBottom
                  variant="body2"
              >
                ENERGY TODAY
              </Typography>
              <Typography
                  color="inherit"
                  variant="h3"
              >
                {total.total} {total.units}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <BoltIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
};

TotalDailyEnergy.propTypes = {
  className: PropTypes.string
};

export default TotalDailyEnergy;
