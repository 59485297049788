import {config} from '"../../config';
import Translations from '../../helpers/translations';
import moment from 'moment'




class Account {
    name: '';
    legal_name: '';
    primary_email: '';
    is_current_user_owner: false;
    created_at: '1970-01-01T00:00:00.000Z';
    updated_at: '1970-01-01T00:00:00.000Z';

    constructor(obj) {
      obj && Object.assign(this, obj);
    }

}

class AccountInvite {

  id: '';
  email: '';
  status: '';
  invited_by: '';
  created_at: '';

  statusOptions: {
    unclaimed: 'Unclaimed'
  };

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

const apiUrl = config.cqsolaDataApi;
export default class AccountService {

  constructor(config) {
    if (config == null){
      config = {};
    }
    this.config = config;
  }


  deleteCurrentAccountStatus() {

    let service = this;
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/account/delete/status`;
      fetch(dataUrl, {
        method: 'GET',
        credentials: 'include',
      })
        .then(service.config.afterFetch)
        .then((response) => {
          switch (response.status) {
            case 200:
            case 404:
              return response.json();
            case 403:
              throw new Error('Unauthorised to delete account');
            default:
              throw new Error('Could not delete account');
          }
        })
        .then(function (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            response.delete_at = moment(response.delete_at)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });

  }


  deleteCurrentAccountCancel(reCaptchaToken) {
    let service = this;
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/account/delete/cancel`;
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          recaptcha_token: reCaptchaToken
        })
      })
        .then(service.config.afterFetch)
        .then((response) => {
          if (Object.prototype.hasOwnProperty.call(this.errorHandlers, response.status) && typeof this.errorHandlers[response.status] === 'function') {
            this.errorHandlers[response.status]();
          }
          return response;
        })
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to delete account');
            default:
              throw new Error('Could not cancel delete request for account');
          }
        })
        .then(function (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });

  }


  deleteCurrentAccountStart(currentPassword, reCaptchaToken) {
    let service = this;
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/account/delete/start`;
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          current_password: currentPassword,
          recaptcha_token: reCaptchaToken
        })
      })
        .then(service.config.afterFetch)
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to delete account');
            default:
              throw new Error('Could not delete account');
          }
        })
        .then(function (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });

  }


  saveCurrentAccount(name, legalName, primaryEmail): Promise<Account> {
    let service = this;
    return new Promise((resolve, reject) => {

      let accountData = {
        name: name,
        legal_name: legalName,
        primary_email: primaryEmail
      };
      let dataUrl = `${apiUrl}/account/self`;
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ account: accountData })
      })
        .then(service.config.afterFetch)
        .then((response) => {
          switch (response.status) {
            case 200:
            case 400:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch current user details');
          }
        })
        .then(function (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
            resolve(new Account(response.account));
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });

  }


  getCurrentAccount(): Promise<Account> {

    let service = this;
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/account/self`;
      fetch(dataUrl, {
        method: 'get',
        credentials: 'include',
      })
        .then(service.config.afterFetch)
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch current user details');
          }
        })
        .then(function (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
            resolve(new Account(response.account));
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });

  }

  //
  // static saveCurrentAccount = function (
  //   firstName,
  //   lastName,
  //   email,
  //   countryCode,
  //   mobileNumber,
  //   timeZone
  // ): Promise<User> {
  //   let userData = {
  //     email: email,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     timezone: timeZone,
  //   };
  //   let dataUrl = `${apiUrl}/user/self`;
  //   return new Promise((resolve, reject) => {
  //     fetch(dataUrl, {
  //       method: 'POST',
  //       credentials: 'include',
  //       body: JSON.stringify({ user: userData })
  //     })
  //       .then( (response) => {
  //         switch (response.status){
  //           case 200:
  //             return response.json();
  //           case 403:
  //             throw new Error('Unauthorised to Access Resource');
  //           default:
  //             throw new Error('Could not save current user details');
  //         }
  //       })
  //       .then(function (userSelfResponse) {
  //         if (userSelfResponse.hasOwnProperty('error') && userSelfResponse.error != null) {
  //           reject(new Error(userSelfResponse.error));
  //         } else {
  //           resolve(new User(userSelfResponse.user));
  //         }
  //       })
  //       .catch((error) => {
  //         reject(new Error(error));
  //       })
  //
  //   });
  //
  // }
  inviteToJoinAccount(email) {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ email: email })
      };
      let dataUrl = `${apiUrl}/account/invite/send`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            case 404:
              throw new Error('Could not verify, please check and try again');
            case 409:
              throw new Error('Email Address already has outstanding invite, revoke to re-send');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  removeUserFromAccount(userEmail) {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ email: userEmail })
      };
      let dataUrl = `${apiUrl}/account/user/remove`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            case 404:
              throw new Error('Could not verify, please check and try again');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  revokeInviteToJoinAccount(inviteId) {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ invite_id: inviteId })
      };
      let dataUrl = `${apiUrl}/account/invite/revoke`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 404:
              throw new Error('Could not verify, please check and try again');
            case 403:
              throw new Error('Unauthorised to Access Resource');
            case 409:
              throw new Error('Email Address already has outstanding invite, revoke to re-send');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  acceptInviteToJoinAccount(token) {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({token: token})
      };
      let dataUrl = `${apiUrl}/account/invite/accept`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json();
            case 404:
              throw new Error('Invite token not found, please check and try again');
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  transferAccountOwnership(transferToEmail, currentPassword, recaptchaToken) {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          transfer_to_email: transferToEmail,
          current_password: currentPassword,
          recaptcha_token: recaptchaToken
        })
      };
      let dataUrl = `${apiUrl}/account/transfer`;
      let status = 0;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then((response) => {
          status = response.status;
          switch (response.status) {
            case 403:
            case 200:
              return response.json();
            case 404:
              throw new Error('Not found, please check and try again');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then((response) => {
          let errorText = 'Unauthorised to Access Resource';
          switch (status) {
            case 403:
              if (
                Object.prototype.hasOwnProperty.call(response, 'error')  !== false &&
                Object.prototype.hasOwnProperty.call(response.error, 'user_forbidden') !== false &&
                response.error.user_forbidden != null
              ) {
                errorText = response.error.user_forbidden;
              }
              throw new Error(errorText);
            default:
              break;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {Query} query
   */
  listPendingInvitations(query) {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(query)
      };
      let dataUrl = `${apiUrl}/account/invite/list`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 404:
              throw new Error('Could not verify, please check and try again');
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
      // resolve({invites: [
      //   {id: "123", email: "test@test.com",   status:"Unclaimed", invited_by:"tony@test.com", created_at: now()},
      //   {id: "125", email: "tony@cqsola.com", status:"Revoked",   invited_by:"tony@test.com", created_at: now()},
      //   {id: "127", email: "tony@cqsola.com", status:"Expired",   invited_by:"tony@test.com", created_at: now()},
      //   {id: "129", email: "tony@cqsola.com", status:"Expired",   invited_by:"tony@test.com", created_at: now()},
      //   {id: "131", email: "tony@cqsola.com", status:"Expired",   invited_by:"tony@test.com", created_at: now()},
      //   ], meta: {total: 5}});
    });
  }
  /**
   *
   * @param {Query} query
   */
  listUsersInAccount(query) {
  //todo: add caching
    //let query = new Query({ filter: {page: page, page_size: pageSize} });
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(query)
      };
      let dataUrl = `${apiUrl}/user/list`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 404:
              throw new Error('Could not verify, please check and try again');
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


}

export {AccountService, Account, AccountInvite};
