// eslint-disable-next-line
const QuerySortOrderDesc = 'desc';
const QuerySortOrderAsc = 'asc';

class Query {

  filter: {
    query: '',
    page: 0,
    pageSize: -1
  };
  sort: {
    by: '',
    order: QuerySortOrderAsc
  };

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
export default Query;
