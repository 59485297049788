import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { options } from './chart';
import moment from 'moment';
import {config} from '../../../../config';
import parseDateRFC3339 from '../../../../helpers/parseDateRFC3339';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const StartFetchLatestEnergyData = (from, to) => {

  return new Promise((resolve, reject) => {
    let params = {
      // "data_type": query.dataType,
      'filter[from]': moment(from).format(),
      'filter[to]': moment(to).format(),
      'data_type': 'watts',
      'group_by': 'day',
      'totals': 'peak',
      'limit': 10000000
    };


    let queryString = Object.keys(params).map(function(key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');

    let dataUrl = config.cqsolaDataApi + '/installation/total?' + queryString;

    fetch(dataUrl, {
      method: 'get',
      credentials: 'include',
    })
      .then(function(res) {
        return res.json();
      })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });



};

const LatestEnergy = props => {
  const { className, ...rest } = props;

  const classes = useStyles();


  const [chartData, setChartData] = useState({});
  // eslint-disable-next-line
  const [endDate, setEndDate] =  useState(new Date(Date.now()));
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(new Date(endDate.getTime() - (60*60*24*7*1000))); // -1 day in ms , so we get today's data

  //
  const noDataMessage = {
    id: 'no-data-message',
    afterDraw: function (chart) {
      if (!(Object.prototype.hasOwnProperty.call(chart, 'data') &&
          Object.prototype.hasOwnProperty.call(chart.data, 'datasets') &&
          Array.isArray(chart.data.datasets) &&
          chart.data.datasets.length > 0 &&
          Object.prototype.hasOwnProperty.call(chart.data.datasets[0], 'data') &&
          Array.isArray(chart.data.datasets[0].data))
      ){
        return;
      }
      let allZeros = true;
      for(let i=0; i<chart.data.datasets[0].data.length-1; i++ ){
        if (chart.data.datasets[0].data[i] !== 0) {
          allZeros = false;
          break;
        }
      }
      if (chart.data.datasets[0].data.length === 0 || allZeros) {
        // No data is present
        let ctx = chart.chart.ctx;
        let width = chart.chart.width;
        let height = chart.chart.height
        chart.clear();

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        //ctx.font = '20px normal \'Helvetica Nueue\'';
        ctx.fillText('No data to display', width / 2, height / 2);
        ctx.restore();
      }

    }
  };


  useEffect(() => {
    StartFetchLatestEnergyData(startDate, endDate)
      .then(energyData => {
        let dataset = {
          label: 'This year',
          backgroundColor: palette.primary.main,
          fill: false,
          data: [],
          maxBarThickness: 10
        };
        const months = ['Jan', 'Feb', 'Mar','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let _labels = [];
        for (let i=0; i<energyData.length; i++) {
          dataset.data.push(energyData[i].total);
          let _date = new Date(parseDateRFC3339(energyData[i].to) * 1000);
          _labels.push(_date.getDate() + ' '  + months[_date.getMonth()]);
        }
        setChartData({
          labels: _labels,//['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],
          datasets: [
            dataset
          ]
        })
      })
  }, [startDate, endDate]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            size="small"
            variant="text"
          >
            Last 7 days
            {/*Last 7 days <ArrowDropDownIcon />*/}
          </Button>
        }
        title="Latest Daily Energy"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            plugins={[noDataMessage]}
            data={chartData}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        {/*<Button*/}
        {/*  color="primary"*/}
        {/*  size="small"*/}
        {/*  variant="text"*/}
        {/*>*/}
        {/*  Overview <ArrowRightIcon />*/}
        {/*</Button>*/}
      </CardActions>
    </Card>
  );
};

LatestEnergy.propTypes = {
  className: PropTypes.string
};

export default LatestEnergy;
