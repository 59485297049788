import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TablePagination
} from '@material-ui/core';

import {InsightDeviceType} from '../../../../types/insight';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 300,
    minHeight: 370
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

// const statusColors = {
//   delivered: 'success',
//   pending: 'info',
//   refunded: 'danger'
// };

const PowerControllerLinkList = props => {
  const { className, affectedDevices, from, to, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);


  //controllerIds
  //const [powerControllers] = useState(mockData);
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  let combinedData = [];
  let includeControllerIdColumn = false;
  for (let affectedDeviceIndex in affectedDevices){
    if (!affectedDevices.hasOwnProperty(affectedDeviceIndex))  continue;
    let affectedDevice = affectedDevices[affectedDeviceIndex];
    if (!affectedDevice.hasOwnProperty('device_id') ||
        !affectedDevice.hasOwnProperty('device_type')
    ){
      //corrupt data
      console.error("Affected Device is corrupt: " + JSON.stringify(affectedDevice));
      continue;
    }

    switch(affectedDevice.device_type){
      case InsightDeviceType.CQSolaController:
        includeControllerIdColumn = true;
        let ids = affectedDevice.device_id.split(":");
        if (ids.length < 2){
          console.error("Affected Device is corrupt: " + JSON.stringify(affectedDevice));
          continue;
        }
        combinedData.push(
          {
            installId: ids[0],
            controllerId: ids[1],
            location: "North East",
            group_tags: "Testing"
          }
        );
        break;
      case InsightDeviceType.CQSolaCommsUnit:
      default:
        combinedData.push(
          {
            installId: affectedDevice.device_id,
            controllerId: null,
            location: "North East",
            group_tags: "Testing"
          }
        );
        break;
    }
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Affected Controllers"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                { (includeControllerIdColumn)? <TableCell>Power Controller ID</TableCell> : "" }
                  <TableCell>String ID</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {combinedData.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(insightAffectedDevice => (

                  <TableRow
                    hover
                    key={insightAffectedDevice.controllerId}
                  >
                    {(includeControllerIdColumn) ?
                      <TableCell>
                        <Link
                          to={'/solar-string/detail/id/' + insightAffectedDevice.installId + '/' + btoa(JSON.stringify({
                            from: Math.floor(from.getTime() / 1000),
                            to: Math.floor(to.getTime() / 1000),
                            controllerIds: [insightAffectedDevice.controllerId]
                          }))}>{insightAffectedDevice.controllerId}</Link>
                      </TableCell> : ''
                    }
                    <TableCell> <Link
                      to={'/solar-string/detail/id/' + insightAffectedDevice.installId + '/' + btoa(JSON.stringify({
                        from: Math.floor(from.getTime() / 1000),
                        to: Math.floor(to.getTime() / 1000),
                        controllerIds: []
                      }))}>{insightAffectedDevice.installId}</Link></TableCell>
                    <TableCell>{insightAffectedDevice.group_tags}</TableCell>
                    <TableCell>{insightAffectedDevice.location}</TableCell>
                  </TableRow>
                ))
                }

              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={combinedData.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

PowerControllerLinkList.propTypes = {
  className: PropTypes.string
};

export default PowerControllerLinkList;
