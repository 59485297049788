import React, {useEffect, useState} from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
//  Badge,
  Hidden,
  IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
//import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';

import AccountSwitcher from '../../../../components/AccountSwitcher';

import {Account} from '../../../../data/AccountService/AccountService';
import {DataService} from '../../../../App';


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },

}));

const Topbar = props => {
  // eslint-disable-next-line no-unused-vars
  const { className, onSidebarOpen, history } = props;

  const classes = useStyles();
  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);
  const handleSignOut = event => {
    event.preventDefault();
    dataProvider.User().logout().then(() => {
      history.push('/');
    });
  };

  const [account, setAccount] = useState(new Account());

  useEffect(() => {
    dataProvider.Account().getCurrentAccount().then(account => {
      setAccount(account)
    }).catch(error => {
      console.error(error)
    });
  }, [dataProvider])

  // const [notifications] = useState([]);

  return (
    <AppBar

      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo.png"
            height={45}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <AccountSwitcher accounts={[account]}  />
          {/*<IconButton*/}
          {/*  className={classes.signOutButton}*/}
          {/*  color="inherit">*/}
          {/*  <Badge*/}
          {/*    badgeContent={notifications.length}*/}
          {/*    color="error"*/}
          {/*    variant="dot"*/}
          {/*  >*/}
          {/*    <NotificationsIcon />*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleSignOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  history: PropTypes.object
};
export default withRouter(Topbar);
//export default Topbar;
