// //this is global configuration for the app
//
// import { dotenv } from 'dotenv'
// dotenv.config()
// If NODE_ENV is not set,
// then this application will assume it's prod by default.
//   app.set('environment', envs('NODE_ENV', 'production'));
//
// // Usage examples:
// app.set('ga_account', envs('GA_UA'));
// app.set('nr_browser_key', envs('NEW_RELIC_BROWSER_KEY'));
// app.set('other', envs('SOME_OTHER_TOKEN'));
export const config = {
  //live
  cqsolaDataApi: process.env.REACT_APP_API_HOST,
  reCaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY

  //local
  // cqsolaDataApi: 'https://api.cqsola.dev.io:8084/v1',
  // reCaptchaSiteKey: '6LfxqcsaAAAAALkic6B-6mUEadXF0iPSojQm-bp2'
};

