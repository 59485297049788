import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';


import {DataService} from "../../App";



const ProtectedRouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);
  return (
    <Route {...rest} render={(matchProps) => (
      dataProvider.User().isAuthenticated() === true
        ?
        <Layout>
          <Component {...matchProps} />
        </Layout>
        : <Redirect to={{
          pathname: '/login',

          state: { from: props.location }
        }} />
    )} />
  );
};

ProtectedRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default ProtectedRouteWithLayout;
