import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination, Tooltip, TableSortLabel
} from '@material-ui/core';

import {fetchIcon} from '../../../../types/insight';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const InsightsTable = props => {
  const { className,
    insights,
    rowTotal,
    // setRowTotal,
    // selectedRows,
    // setSelectedRows,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedCommsUnit, setSelectedCommsUnit] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [page, setPage] = useState(0);

  //for (let insightIndex in insights){
  //  for (let installId in insights[insightIndex].affected_devices){
  //    insights[insightIndex].affected_devices[installId].length
  //  }
  //}

  const handleSelectAll = event => {
    const { insights } = props;

    let selectedCommsUnits;

    if (event.target.checked) {
      selectedCommsUnits = insights.map(commsunit => commsunit.id);
    } else {
      selectedCommsUnits = [];
    }

    setSelectedCommsUnit(selectedCommsUnits);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCommsUnit.indexOf(id);
    let newSelectedCommsUnits = [];

    if (selectedIndex === -1) {
      newSelectedCommsUnits = newSelectedCommsUnits.concat(selectedCommsUnit, id);
    } else if (selectedIndex === 0) {
      newSelectedCommsUnits = newSelectedCommsUnits.concat(selectedCommsUnit.slice(1));
    } else if (selectedIndex === selectedCommsUnit.length - 1) {
      newSelectedCommsUnits = newSelectedCommsUnits.concat(selectedCommsUnit.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCommsUnits = newSelectedCommsUnits.concat(
        selectedCommsUnit.slice(0, selectedIndex),
        selectedCommsUnit.slice(selectedIndex + 1)
      );
    }

    setSelectedCommsUnit(newSelectedCommsUnits);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCommsUnit.length === insights.length}
                      color="primary"
                      indeterminate={
                        selectedCommsUnit.length > 0 &&
                        selectedCommsUnit.length < insights.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell></TableCell>

                  <TableCell>Insight Detected</TableCell>
                  <TableCell sortDirection="desc">
                    <Tooltip
                      enterDelay={300}
                      title="Sort"
                    >
                      <TableSortLabel
                        active
                        direction="desc"
                      >
                        Detected At
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>Affected Strings</TableCell>
                  <TableCell>Affected Power Controllers</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Registration date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/*{insights.slice(page * rowsPerPage, (page * rowsPerPage) +rowsPerPage).map(insight => (*/}
                { insights.map(insight => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={insight.id}
                    selected={selectedCommsUnit.indexOf(insight.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCommsUnit.indexOf(insight.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, insight.id)}
                        value="true"
                      />
                    </TableCell>

                    <TableCell>
                      { fetchIcon(insight.type) }
                    </TableCell>
                    <TableCell>
                      <Link to={'/insight/detail/id/' + insight.id} >{insight.message}</Link>
                    </TableCell>
                    <TableCell>  {moment(insight.detected_at).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                    <TableCell>{ (insight.hasOwnProperty('affected_devices') && insight.affected_devices != null )? Object.keys(insight.affected_devices).length : 0 }</TableCell>
                    <TableCell>{ insight.affected_controllers_count }</TableCell>
                    <TableCell>{insight.status}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {/*{moment(insight.createdAt).format('DD/MM/YYYY')}*/}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={rowTotal}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

InsightsTable.propTypes = {
  className: PropTypes.string,
  insights: PropTypes.array.isRequired
};

export default InsightsTable;
