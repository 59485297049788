import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import { SolarRegisterToolbar, SolarStringsTable } from './components';
import Query from '../../data/Query';
import {DataService} from '../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    position: 'relative'
  },
  content: {
    marginTop: theme.spacing(2),
  },
  sidebar: {

  }
}));

const SolarStringList = () => {
  const classes = useStyles();
  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);


  const [solarStrings, setSolarStrings] = useState([]);

  // const [users, setUsers] = useState([]);
  // const [currentUser, setCurrentUser] = useState({});
  // const [currentAccount, setCurrentAccount] = useState({});
  const [query, setQuery] = useState('');
  //const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [rowTotal, setRowTotal] = useState(0);


  useEffect(() => {

    let listQuery = new Query({ filter: {query: query, page: page, page_size: rowsPerPage} });

    dataProvider.CommsUnit().list(listQuery)
      .then((commsUnitResponse) => {
        setSolarStrings(commsUnitResponse.data);
        setRowTotal(commsUnitResponse.meta.total);
      })
  }, [dataProvider, page, rowsPerPage, query, rowTotal])


  //mockData
  // useEffect(() => {
  //   CommsUnitService.list()
  //     .then((com) => {
  //       setSolarStrings(user)
  //     })
  // }, [])
  return (
    <div className={classes.root}>

      <SolarRegisterToolbar
        query={query}
        setQuery={(query) => { setPage(0); setQuery(query) }}
      />
      {/*<GroupsSidebar />*/}
      <div className={classes.content}>


        <SolarStringsTable
          solarStrings={solarStrings}
          rowTotal={rowTotal}
          //setRowTotal={setRowTotal}
          // selectedRows={selectedRows}
          // setSelectedRows={setSelectedRows}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>
  );
};

export default SolarStringList;
