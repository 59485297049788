import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  IconButton,
  Typography,
   Link, Button
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CircularProgress from '@material-ui/core/CircularProgress';
import MfaSmsVerify from '../../components/MfaSmsVerify';

import Translations from '../../helpers/translations';

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import withWidth from '@material-ui/core/withWidth';
import {config} from "../../config";
import {SidePlaneGridItem} from '../../layouts/Main/components';
import {DataService} from '../../App';

loadReCaptcha(config.reCaptchaSiteKey, () => {});

const schema = {
  password: {
    presence: {  allowEmpty: false, message: 'is required' },
    format: {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/,
      message: 'must contain at least 1 Capital, 1 Number, 1 lowercase and 1 symbol'
    },
    length: {
      minimum: 9
    }
  },
  password_confirm: {
    // You need to confirm your password
    presence: true,
    // and it needs to be equal to the other password
    equality: {
      attribute: 'password',
      message: '^The passwords do not match'
    }
  },
  mfa_verify : {
    presence: { allowEmpty: false, message: 'is required' },
  }
};




const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  nameGrid: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  expiredLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    minHeight: 436,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  resetPasswordButton: {
    margin: theme.spacing(2, 0)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  },
  MFATick: {
    display: 'block',
    animation: 'fade-out 5s'
  },
  MFATickHidden: {
    display: 'none'
  }
}));



function getSteps() {
  return ['SMS Verification'];
}



const SignUpVerify = props => {
  const { history } = props;

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  let token = new URLSearchParams(props.location.search).get('token');

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false,
      captchaResetCallback: (token) => {}
    }
  });


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));


  }, [formState.values]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [reSendAttempts, setReSendAttempts] = React.useState(0);
  const [ableToReSendMFA, setAbleToReSendMFA] = React.useState(true);
  const [showReSentTick, setShowReSentTick] = React.useState(false);

  const steps = getSteps();

  const confirmMFA = () => {
    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        forceReset: !formState.reCaptcha.forceReset,
        captchaResetCallback: (reCaptchaToken) => {
          dataProvider.User().signUpVerifyConfirmMFA(
            reCaptchaToken,
            token,
            formState.values['mfa_verify'])
            .then(() => {
              props.history.push('/dashboard')
            })
            .catch((error) => {

              if (error != null){
                let errorText = '';
                errorText = error.message;
                if (errorText === ''){
                  errorText = Translations.Errors.ServerUnavailable;
                }
                // incorrect password or user disabled
                let errors = {
                  mfa_verify: [errorText]
                };
                setLoadingNetworkRequest(false);
                setFormState(formState => ({
                  ...formState,
                  isValid: errors ? false : true,
                  errors: errors || {}
                }));

              }

            });
        }
      }
    }));
  }


  const MaxSMSRetries = 3;
  const resendMFA = () => {

    //refresh token
    if (reSendAttempts > MaxSMSRetries-1){
      setAbleToReSendMFA(false);
      return;
      //cause error
      //cannot retry
    }

    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        forceReset: !formState.reCaptcha.forceReset,
        captchaResetCallback: (reCaptchaToken) => {
          setReSendAttempts(() => reSendAttempts + 1);
          setAbleToReSendMFA(false);
          dataProvider.User().forgotPasswordResendMFA(
            reCaptchaToken,
            token)
            .then(() => {
              setShowReSentTick(true);
              setTimeout(() => {setShowReSentTick(false); }, 5000);
            })
            .catch((error) => {})
            .finally(() => {
              if (reSendAttempts < MaxSMSRetries-1){
                setTimeout(() => {
                  setAbleToReSendMFA(true);
                }, 5000)
              }
            });
        }
      }
    }));


  };

  const handleNextStep = (event) => {
    validateStep(activeStep)
      .then( () => {
        switch(activeStep){
          case 0:
            setLoadingNetworkRequest(true);
            confirmMFA();
            break;
          default:
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setLoadingNetworkRequest(false);
            break;
        }
      })
      .catch(() => {
        // show validation errors
      });

  };

  const validateStep = (step) => {
    return new Promise((resolve, reject) => {
      let schemaStub = {};
      switch (step) {
        case 0:
          schemaStub.mfa_verify  = schema.mfa_verify;
          break;
        // case 2:
        //   schemaStub.mfa_verify  = schema.mfa_verify;
        //   break;
        default:
          break;
      }
      const errors = validate(formState.values, schemaStub);

      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));

      if (errors) {
        reject(errors);
        return;
      }
      resolve();
    });

  };


  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBack = () => {
    history.goBack();
  };


  const [loadingNetworkRequest, setLoadingNetworkRequest] = React.useState(false);

  const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [hasValidatedToken, setHasValidatedToken] = React.useState(false);

  const handleResetPasswordSubmit = event => {
    event.preventDefault();
    //handlePasswordReset();
  };

  const reCaptchaRef = React.useRef();



  const verifyReCaptchaCallback = (recaptchaToken) => {
    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        token: recaptchaToken
      },
    }));
    formState.reCaptcha.captchaResetCallback(recaptchaToken)


  };

  useEffect(() => {
    dataProvider.User().signUpTokenIsValid(token)
      .then((isValid) => {
        setHasValidatedToken(true);
        setIsTokenValid(isValid);
      }).catch((err) => {
        //set error state,
        setHasValidatedToken(true);
        setIsTokenValid(false);
      });
  }, [dataProvider, token]);

  useEffect(() => {
    if (reCaptchaRef.current) {
      reCaptchaRef.current.execute();
    }
  }, [ formState.reCaptcha.forceReset]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>

      <Grid
        className={classes.grid}
        container
      >
        <SidePlaneGridItem />
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>

            <div className={classes.contentBody}>

              <form
                className={classes.form}
                onSubmit={handleResetPasswordSubmit}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Create new account
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Get started with your new solar equipment
                </Typography>
                {(!hasValidatedToken)? <>
                  <span className={classes.expiredLinkContainer} >
                    <CircularProgress size={'1rem'}/>
                    <Typography
                      style={{paddingLeft:'0.5rem', justifyContent: 'unset'}}
                      className={classes.contentBody}
                      variant='h5'
                    >Validating Token</Typography>
                  </span>
                </> : <>
                  {(isTokenValid)? <>
                    {(activeStep === steps.length) ? (

                      <div>
                        <Typography className={classes.instructions}>Password Successfully Reset</Typography>
                        <Typography
                          color="textSecondary"
                          variant="body1"
                        >
                          Click to proceed to dashboard{' '}
                          <Link
                            component={RouterLink}
                            to="/dashboard"
                            variant="h6"
                          >
                            Dashboard
                          </Link>
                        </Typography>
                      </div>
                    ) : (
                      <>
                        <MfaSmsVerify
                          errorText={hasError('mfa_verify') ? formState.errors.mfa_verify[0] : null}
                          hasError={hasError('mfa_verify')}
                          isDisabled={!ableToReSendMFA}
                          showSentTick={showReSentTick}
                          resendMFA={resendMFA}
                          onChange={handleChange}
                          value={formState.values.mfa_verify || ''}
                        />
                        <Button
                          className={classes.submitButton}
                          color="primary"
                          disabled={loadingNetworkRequest === true}
                          fullWidth
                          onClick={handleNextStep}
                          type='button'
                          variant='contained'
                        >
                          Next
                        </Button>
                      </> )}
                  </> : <>
                    <span className={classes.expiredLinkContainer} >
                      <NotInterestedIcon />
                      <Typography
                        className={classes.contentBody}
                        style={{paddingLeft:'0.5rem', justifyContent: 'unset'}}
                        variant="h5"
                      >Link has expired or is invalid</Typography>
                    </span>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Try again?{' '}
                      <Link
                        component={RouterLink}
                        to="/sign-up/begin"
                        variant="h6"
                      >
                        Restart Sign Up
                      </Link>
                    </Typography>
                  </>}

                </>}

                <ReCaptcha
                  ref={ reCaptchaRef }
                  sitekey={config.reCaptchaSiteKey}
                  action='user_sign_verify'
                  verifyCallback={verifyReCaptchaCallback}
                />

              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUpVerify.propTypes = {
  history: PropTypes.object
};



export default withWidth()(withRouter(SignUpVerify));
