import React from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
//import clsx from "clsx";
import {Account} from '../../data/AccountService/AccountService';

const useStyles = makeStyles(theme => ({
  accountDetails: {
    color: theme.palette.primary.light,
  },
}));

const AccountSwitcher = (props) => {
  //const { className, selectedAccount, accounts } = props;
  const className = props.className;
  const accounts = props.accounts;

  let initialSwitchState = false;

  //const [open, setOpen] = React.useState(initialSwitchState);
  const open = initialSwitchState;
  const classes = useStyles();

  // const handleClick = () => {
  //   setOpen(!open);
  // };
  //
  // let _accounts = [{name: 'Demo Account'}];

  let currentAccount = new Account({});
  if (Array.isArray(accounts) && accounts.length > 0){
    currentAccount = accounts[0];
  }

  let accountName = (currentAccount.name !== '')? currentAccount.name : currentAccount.legal_name;


  return (
    <>
      <div className={className}>
        <div class="details" style={{textAlign: 'right'}}>
          <Card style={{
            //not a great way to do this, should put this in a class and select from the theme palette
            backgroundColor: '#424242'
          }}>
            <CardContent >
              <Typography
                className={classes.accountDetails}
                variant="body2"
              >
                Account
              </Typography>
              <Typography
                className={classes.accountDetails}
                variant="caption">
                {accountName}
              </Typography>
            </CardContent>
          </Card>
        </div>

        {(accounts.length > 1)?
          <>
            {open ? <ExpandLess /> : <ExpandMore />}
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
            >
              {accounts.map(_account => (
                <Typography
                  className={classes.accountDetails}
                  varient={'body2'}>
                  {_account.name}
                </Typography>
              ))}
            </Collapse>
          </> :
          <></>
        }
      </div>
    </>
  );
};

export default AccountSwitcher;
