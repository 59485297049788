import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card
} from '@material-ui/core';
import {DeviceEventLogsToolbar, DeviceEventLogsTable} from './components'
import Query from '../../../../data/Query';
import {DataService} from '../../../../App';



const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  // inner: {
  //   minWidth: 800
  // },
  inner: {
    minWidth: 800,
    minHeight: "auto",
    [theme.breakpoints.up('lg')]: {
      minHeight: "400px",
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

// const statusColors = {
//   Info: 'primary',
//   Warning: 'warning',
//   Critical: 'danger'
// };

const DeviceEventLog = props => {
  const { className, installId, from, to, selectedControllers, ...rest } = props;

  const classes = useStyles();

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);
  const [deviceEventLogs, setDeviceEventLogs] = useState([]);
  const [query, setQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [rowTotal, setRowTotal] = useState(0);

  // const [deviceEventLogTotal, setDeviceEventLogTotal] = useState(0);


  const [deviceEventLogTypes, setDeviceEventLogTypes] = useState([
    // 'critical',
    // 'warning',
    // 'info',
  ]);


  useEffect(() => {

    let filter = {
      query: query,
      page: page,
      page_size: rowsPerPage,
    }
    if (selectedControllers.length > 0){
      filter.serial_numbers = selectedControllers;
    }
    if (deviceEventLogTypes.length > 0){
      filter.severity = deviceEventLogTypes;
    }
    let listQuery = new Query({ filter: filter });

    dataProvider.DeviceEventLog().list(installId, from, to, listQuery )
      .then((deviceEventLogList) => {
        setDeviceEventLogs(deviceEventLogList.data);
        setRowTotal(deviceEventLogList.meta.total);
      })
  }, [dataProvider, installId, page, from, to, rowsPerPage, selectedControllers, query, deviceEventLogTypes]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <DeviceEventLogsToolbar
        query={query}
        setQuery={(query) => { setPage(0); setQuery(query) }}
        deviceEventLogTypes={deviceEventLogTypes}
        setDeviceEventLogTypes={setDeviceEventLogTypes}
      />
      <div className={classes.content}>
        <DeviceEventLogsTable
          deviceEventLogs={deviceEventLogs}
          rowTotal={rowTotal}
          setRowTotal={setRowTotal}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </Card>

  );
};
DeviceEventLog.propTypes = {
  className: PropTypes.string,
  from: PropTypes.any,
  installId: PropTypes.string,
  selectedControllers: PropTypes.array,
  to: PropTypes.any,
};

export default DeviceEventLog;
