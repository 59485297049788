import {config} from '"../../config';
import Translations from '../../helpers/translations';


class Insight {
    id: "";
    affected_devices: "";
    detected_at: "";
    detected_from: "";
    detected_to: "";
    comparison_from: "";
    comparison_to: "";
    comparison_type: "";
    comparison_type_data: "";
    certainty: "";
    severity: "";
    type: "";
    message: "";
    account_id: "";
    created_at: "";
    created_by: "";
    created_by_user_id: "";

    constructor(obj) {
      if (obj) {

        let dateFields = [
          'detected_at',
          'detected_from',
          'detected_to',
          'comparison_from',
          'comparison_to',
        ];
        for (let i =0; i < dateFields.length; i++ ){

          if (Object.prototype.hasOwnProperty.call(obj, dateFields[i])){
          //  let parsedDateUnixTime = parseDateRFC3339( obj[dateFields[i]])
            obj[dateFields[i]] = new Date(obj[dateFields[i]]);
          }
          else {
            obj[dateFields[i]] = new Date(0);
          }
        }
        Object.assign(this, obj);
      }
    }


}


class InsightList {
    data: Array<Insight>;
    meta: {};
}

const apiUrl = config.cqsolaDataApi;
export default class InsightService {

  constructor(config) {
    if (config == null){
      config = {};
    }
    this.config = config;
  }
  //
  // isPermitted(installId): Promise<Boolean> {
  //   let service = this;
  //   return new Promise((resolve, reject) => {
  //     let query = new Query({
  //       filter: {
  //         query: installId,
  //         page: 0,
  //         pageSize: -1
  //       },
  //       sort: {
  //         by: '',
  //         order: 'asc'
  //       }
  //     });
  //     //todo: write dedicated route for this permission check
  //     //      list transfers a more data than needed, but will work
  //     //      all real permissions are handled server side, but this is
  //     //      for the purpose of graceful user experience.
  //     // query.filter.query = installId;
  //     service.list(query).then(insghtList => {
  //       //this is a query, there may be unexpeced results.
  //       // e.g. searching for BRI85 will also return BRI857, BRI858
  //       let found = false;
  //       for (let i=0; i < insghtList.data.length; i++){
  //         if (insghtList.data[i].install_id === installId){
  //           found = true;
  //           break;
  //         }
  //       }
  //       resolve(found);
  //     }).catch(err => {
  //       reject(err);
  //     })
  //   });
  // }


  //
  // static register = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };
  //
  get(id): Promise<Insight> {
    let dataUrl = config.cqsolaDataApi + '/insight/id/' +id;
    let service = this;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'get',
        credentials: 'include',
      })
        .then(service.config.afterFetch)
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch insight details');
          }
        })
        .then((response) => {
          switch(true){
            case (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null):
              reject(new Error(response.error));
              break;
            case (Object.prototype.hasOwnProperty.call(response,'insight') && response.insight != null):
              resolve(new Insight(response.insight));
              break;
            default:
              throw new Error('Unexpected response');
          }
        }).catch(err => {
          console.error(err)
          reject(err);
        });

    });
  }
  //
  // static release = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };

  // total(installId, startDate, endDate, controllerIds = ['combined_string']):  Promise<Number> {
  //   let params = {
  //     "filter[install_id]": installId,
  //     "filter[from]": moment(startDate).format(),
  //     "filter[to]": moment(endDate).format(),
  //     "filter[optimiser_ids]": controllerIds,
  //   };
  //   let service = this;
  //   return new Promise((resolve, reject) => {
  //
  //     let queryString = Object.keys(params).map(function(key) {
  //       return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  //     }).join('&');
  //
  //
  //     let dataUrl = config.cqsolaDataApi + '/comms-unit/total?' + queryString;
  //
  //     fetch(dataUrl, {
  //       method: 'get',
  //       credentials: 'include',
  //     })
  //       .then(service.config.afterFetch)
  //       .then( (response) => {
  //         switch (response.status){
  //           case 200:
  //             return response.json();
  //           case 403:
  //             throw new Error('Unauthorised to Access Resource');
  //           default:
  //             throw new Error('Could not fetch commsunits totals');
  //         }
  //       })
  //       .then((response) => {
  //         if (!Object.prototype.hasOwnProperty.call(response, 'total'))
  //         {
  //           reject(new Error('Malformed Response'));
  //           return;
  //         }
  //         resolve(response.total);
  //       })
  //       .catch((error) => {
  //         reject(new Error(error));
  //       })
  //   });
  //
  // }

  list(query): Promise<InsightList> {

    let dataUrl = `${apiUrl}/insight/list`;
    let service = this;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error('Could not fetch insghts');
          }
        })
        .then(function (response) {
          // let expectedResponseFields = [
          //   'error',
          //   'meta',
          //   'comms_units'
          // ];
          //
          // let isValidResponse = !expectedResponseFields.map((field) => {
          //   return Object.prototype.hasOwnProperty.call(response, field)
          // }).includes(false);
          //
          // if (!isValidResponse){
          //   reject(Translations.Errors.UnExpectedResponse);
          // }
          if (response.hasOwnProperty('error') && response.error != null) {
            reject(new Error(response.error));
          } else {
            //validate response
            if (!Array.isArray(response.insights)){
              reject(new Error(Translations.Errors.UnExpectedResponse));
            }
            let output = new InsightList();
            output.data = [];
            for (let i =0;i<response.insights.length; i++){
              output.data[i] =new Insight(response.insights[i])
            }
            output.meta = response.meta;
            resolve(output);
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });

  }
}

export {InsightService, Insight, InsightList};
