import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import { InsightsToolbar, InsightsTable } from './components';

import {DataService} from "../../App";
import Query from "../../data/Query";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const InsightList = () => {
  const classes = useStyles();

  const dataProvider = React.useContext(DataService);

  //const [insights, setInsights] = useState(mockData);
  const [insights, setInsights] = useState([]);

  const [query, setQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [rowTotal, setRowTotal] = useState(0);


  useEffect(() => {

    let listQuery = new Query({ filter: {query: query, page: page, page_size: rowsPerPage} });

    dataProvider.Insight().list(listQuery)
      .then((insightResponse) => {
        setInsights(insightResponse.data);
        setRowTotal(insightResponse.meta.total);
      })
  }, [dataProvider, page, rowsPerPage, query, rowTotal])


  //  useEffect(() => {


  // let params = {
  //   //"data_type": query.dataType,
  //   //"filter[install_id]": "BRI857",
  //   //"filter[from]": query.filters.from.format(),
  //   //"filter[to]": query.filters.to.format(),
  //   //"filter[optimiser_ids]": query.filters.optimiserIds,
  //   //"mode": query.mode,
  //   //"limit": queryLimit
  // };
  //
  // let queryString = Object.keys(params).map(function(key) {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  // }).join('&');
  //
  //
  //   let dataUrl = config.cqsolaDataApi + '/insight/list?' + queryString;
  //   fetch(dataUrl, {
  //   method: 'get',
  //   credentials: 'include'
  // })
  //   .then(function(res) {
  //     return res.json();
  //   })
  //   .then(function(insightResponse) {
  //     //let insightResponse = JSON.parse(_insightResponse);
  //     if (insightResponse.hasOwnProperty("error") && insightResponse.error != null){
  //       console.error(insightResponse.error);
  //     }
  //     else {
  //       //console.log(insightResponse)
  //       let insightsToSet = [];
  //       if (Array.isArray(insightResponse)){
  //         insightsToSet = insightResponse;
  //       }
  //       setInsights(insightsToSet)
  //     }
  //     //callback({isLoaded: true, query: query, data: resBlob,  cacheResult: cacheResults.pass});
  //     //let dateFormat = 'YYYY-MM-DD-hhmmss';
  //     //let filename = "cqsola-" + query.filters.installId + "-" + query.dataType +"-" + query.filters.from.format(dateFormat) + "-" + query.filters.to.format(dateFormat) + ".csv";
  //     //download(resBlob, filename, "text/csv" );
  //     //setInsights
  //
  //   });
  // }, [page]);


  return (
    <div className={classes.root}>
      <InsightsToolbar
        query={query}
        setQuery={(query) => { setPage(0); setQuery(query) }}
      />
      <div className={classes.content}>
        <InsightsTable
          insights={insights}
          rowTotal={rowTotal}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>
  );
};

export default InsightList;
