import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SolarStringsTable = props => {
  const { className,
    solarStrings,
    rowTotal,
    // setRowTotal,
    // selectedRows,
    // setSelectedRows,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    ...rest } = props;

  const classes = useStyles();

  //const [selectedCommsUnit, setSelectedCommsUnit] = useState([]);
  //const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [page, setPage] = useState(0);

  // const handleSelectAll = event => {
  //   const { solarStrings } = props;
  //
  //   let selectedCommsUnits;
  //
  //   if (event.target.checked) {
  //     selectedCommsUnits = solarStrings.map(commsunit => commsunit.id);
  //   } else {
  //     selectedCommsUnits = [];
  //   }
  //
  //   setSelectedRows(selectedCommsUnits);
  // };
  //
  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedRows.indexOf(id);
  //   let newSelectedRows = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelectedRows = newSelectedRows.concat(setSelectedRows, id);
  //   } else if (selectedIndex === 0) {
  //     newSelectedRows = newSelectedRows.concat(setSelectedRows.slice(1));
  //   } else if (selectedIndex === setSelectedRows.length - 1) {
  //     newSelectedRows = newSelectedRows.concat(setSelectedRows.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelectedRows = newSelectedRows.concat(
  //       setSelectedRows.slice(0, selectedIndex),
  //       setSelectedRows.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelectedRows(newSelectedCommsUnits);
  // };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox">*/}
                  {/*  <Checkbox*/}
                  {/*    checked={selectedCommsUnit.length === solarStrings.length}*/}
                  {/*    color="primary"*/}
                  {/*    indeterminate={*/}
                  {/*      selectedCommsUnit.length > 0 &&*/}
                  {/*      selectedCommsUnit.length < solarStrings.length*/}
                  {/*    }*/}
                  {/*    onChange={handleSelectAll}*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                  <TableCell>Solar String ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Last Seen</TableCell>
                  <TableCell>Group</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { /*solarStrings.slice(page, rowsPerPage).map(solarString => ( */ }
                { solarStrings.map(solarString => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    // key={solarString.id}
                    // selected={selectedCommsUnit.indexOf(solarString.id) !== -1}
                  >
                    {/*<TableCell padding="checkbox">*/}
                    {/*  <Checkbox*/}
                    {/*    checked={selectedCommsUnit.indexOf(solarString.id) !== -1}*/}
                    {/*    color="primary"*/}
                    {/*    onChange={event => handleSelectOne(event, solarString.id)}*/}
                    {/*    value="true"*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      <Link to={'/solar-string/detail/id/' + solarString.install_id} >{solarString.install_id}</Link>
                    </TableCell>
                    <TableCell>Healthy</TableCell>
                    <TableCell>{moment().format('DD/MM/YYYY hh:mm A')}</TableCell>
                    <TableCell>
                      {/*{moment(solarString.createdAt).format('DD/MM/YYYY')}*/}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={rowTotal}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

SolarStringsTable.propTypes = {
  className: PropTypes.string,
  solarStrings: PropTypes.array.isRequired
};

export default SolarStringsTable;
