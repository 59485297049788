import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from "../../../../components/ConfirmDialog";
import {DataService} from '../../../../App';



const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UserApiTokensTable = props => {

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);
  const { className,
    userApiTokens,
    rowTotal,
    setRowTotal,
    // selectedRows,
    // setSelectedRows,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    ...rest } = props;

  const classes = useStyles();


  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const isUUID = (candidate) => {
    switch (candidate){
      case null:
      case '':
        return false;
      default:
        return regexExp.test(candidate);
    }
  };


  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedTokenId, setSelectedTokenId] = React.useState('');
  const handleDeleteConfirm = () => {

    if (isUUID(selectedTokenId)) {
      dataProvider.UserApiToken().delete(selectedTokenId)
        .then(result => {
          setRowTotal(rowTotal - 1)
        })
        .catch((err) => {
        })
        .finally(() => {
          setConfirmOpen(false);
        })
    }
  }

  //const [selectedCommsUnit, setSelectedCommsUnit] = useState([]);
  //const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [page, setPage] = useState(0);

  // const handleSelectAll = event => {
  //   const { solarStrings } = props;
  //
  //   let selectedCommsUnits;
  //
  //   if (event.target.checked) {
  //     selectedCommsUnits = solarStrings.map(commsunit => commsunit.id);
  //   } else {
  //     selectedCommsUnits = [];
  //   }
  //
  //   setSelectedRows(selectedCommsUnits);
  // };
  //
  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedRows.indexOf(id);
  //   let newSelectedRows = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelectedRows = newSelectedRows.concat(setSelectedRows, id);
  //   } else if (selectedIndex === 0) {
  //     newSelectedRows = newSelectedRows.concat(setSelectedRows.slice(1));
  //   } else if (selectedIndex === setSelectedRows.length - 1) {
  //     newSelectedRows = newSelectedRows.concat(setSelectedRows.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelectedRows = newSelectedRows.concat(
  //       setSelectedRows.slice(0, selectedIndex),
  //       setSelectedRows.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelectedRows(newSelectedCommsUnits);
  // };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/*<TableCell padding="checkbox">*/}
                    {/*  <Checkbox*/}
                    {/*    checked={selectedCommsUnit.length === solarStrings.length}*/}
                    {/*    color="primary"*/}
                    {/*    indeterminate={*/}
                    {/*      selectedCommsUnit.length > 0 &&*/}
                    {/*      selectedCommsUnit.length < solarStrings.length*/}
                    {/*    }*/}
                    {/*    onChange={handleSelectAll}*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell>Api Key ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Restricted To IP</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { /*solarStrings.slice(page, rowsPerPage).map(solarString => ( */ }
                  { userApiTokens.map(apiToken => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      // key={apiToken.id}
                      // selected={selectedCommsUnit.indexOf(apiToken.id) !== -1}
                    >
                      {/*<TableCell padding="checkbox">*/}
                      {/*  <Checkbox*/}
                      {/*    checked={selectedCommsUnit.indexOf(apiToken.id) !== -1}*/}
                      {/*    color="primary"*/}
                      {/*    onChange={event => handleSelectOne(event, apiToken.id)}*/}
                      {/*    value="true"*/}
                      {/*  />*/}
                      {/*</TableCell>*/}
                      <TableCell>
                        <Link to={'/api-access/edit/id/' + apiToken.id} >{apiToken.id}</Link>
                      </TableCell>
                      <TableCell>
                        {apiToken.name}
                        {/*{JSON.stringify(apiToken)}*/}
                      </TableCell>
                      {/*<TableCell>*/}
                      {/*  {console.log(apiToken)}*/}
                      {/*</TableCell>*/}
                      <TableCell>{Number(apiToken.is_active) === 1 ? 'Active': 'Inactive'}</TableCell>
                      <TableCell>{apiToken.restrict_to_ip}</TableCell>
                      <TableCell>
                        {moment(apiToken.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setSelectedTokenId(apiToken.id);
                            setConfirmOpen(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={rowTotal}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <ConfirmDialog
        title="Delete Api Token"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDeleteConfirm}
      >
        <Typography
          variant="body1"
        >
          Are you sure?
        </Typography>
        <Typography
          variant="subtitle2"
        >
          Deleting Api Token will also remove access from any program setup to use this token.
        </Typography>
      </ConfirmDialog>
    </>
  );
};

UserApiTokensTable.propTypes = {
  className: PropTypes.string,
  userApiTokens: PropTypes.array.isRequired
};

export default UserApiTokensTable;
