import React from 'react';
import {  Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  //IconButton,
  Divider,
  Typography
} from '@material-ui/core';
// import LaptopMacIcon from '@material-ui/icons/LaptopMac';
// import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
// import RefreshIcon from '@material-ui/icons/Refresh';
// import TabletMacIcon from '@material-ui/icons/TabletMac';

import {DataService} from '../../../../App';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ModuleHealth = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const initalRatingsResponse = {
    ratings: {
      expected: 0,
      healthy: 0,
      under_performing: 0
    },
    total_modules: 0
  };
  const [data, setData] = React.useState({
    healthy: 0,
    expected: 0,
    under_performing: 0,
    raw: initalRatingsResponse
  })
  // const totalizer = {
  //   id: 'totalizer',
  //
  //   beforeUpdate: chart => {
  //     let totals = {}
  //     let utmost = 0
  //
  //     chart.data.datasets.forEach((dataset, datasetIndex) => {
  //       if (chart.isDatasetVisible(datasetIndex)) {
  //         utmost = datasetIndex
  //         dataset.data.forEach((value, index) => {
  //           totals[index] = (totals[index] || 0) + value
  //         })
  //       }
  //     })
  //
  //     chart.$totalizer = {
  //       totals: totals,
  //       utmost: utmost
  //     }
  //   }
  // }
  const noDataMessage = {
    id: 'no-data-message',
    afterDraw: function (chart) {
      if (!(Object.prototype.hasOwnProperty.call(chart, 'data') &&
          Object.prototype.hasOwnProperty.call(chart.data, 'datasets') &&
          Array.isArray(chart.data.datasets) &&
          chart.data.datasets.length > 0 &&
          Object.prototype.hasOwnProperty.call(chart.data.datasets[0], 'data') &&
          Array.isArray(chart.data.datasets[0].data))
      ){
        return;
      }
      if (chart.data.datasets[0].data.length === 0) {
        // No data is present
        let ctx = chart.chart.ctx;
        let width = chart.chart.width;
        let height = chart.chart.height
        chart.clear();

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        //ctx.font = '20px normal \'Helvetica Nueue\'';
        ctx.fillText('No data to display', width / 2, height / 2);
        ctx.restore();
      }

    }
  };

  const dataProvider = React.useContext(DataService);
  React.useEffect(() => {
    dataProvider.Statistics().getModuleHealthRatings()
      .then(response => {
        //response = Object
        // data:
        //     ratings:
        //         expected: 18
        // healthy: 3
        // under_performing: 1
        //     [[Prototype]]: Object
        // total_modules: 22
        //     [[Prototype]]: Object
        // error: null
        //     [[Prototype]]: Object
        let foundData = initalRatingsResponse;
        let totalModules = 1;

        if (Object.prototype.hasOwnProperty.call(response, 'data') &&
            typeof response.data === 'object'
        ){
          foundData = response.data;
        }

        if (Object.prototype.hasOwnProperty.call(foundData, 'total_modules') &&
            typeof foundData.total_modules === 'number'
        ){
          totalModules = foundData.total_modules;
        }

        if (!Object.prototype.hasOwnProperty.call(foundData, 'ratings')){
          foundData.ratings = initalRatingsResponse.ratings;
        }
        let healthyRating = Math.round( (foundData.ratings.healthy / totalModules) * 100 * 100) / 100;

        let expectedRating = Math.round((foundData.ratings.expected / totalModules) * 100 * 100) / 100;
        let underPerformingRating = Math.round( (foundData.ratings.under_performing / totalModules) * 100 * 100 )/ 100;

        if (Number.isNaN(healthyRating)){
          healthyRating = '-';
        }
        if (Number.isNaN(expectedRating)){
          expectedRating = '-';
        }
        if (Number.isNaN(underPerformingRating)){
          underPerformingRating = '-';
        }
        setData({
          healthy:  healthyRating,
          expected: expectedRating,
          under_performing:  underPerformingRating,
          raw: foundData
        })
        console.debug(response);
      })
      .catch(err => {
        console.log(err);
      });
  },
  // eslint-disable-next-line
  []);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const devices = [
    {
      title: 'Great',
      value: data.healthy ,
      // icon: <LaptopMacIcon />,
      color: theme.palette.warning.main
    },
    {
      title: 'Good',
      value: data.expected ,
      // icon: <TabletMacIcon />,
      color: theme.palette.primary.main
    },
    {
      title: 'Below Threshold',
      value: data.under_performing,
      // icon: <PhoneIphoneIcon />,
      color: theme.palette.error.main
    }
  ];
  const healthyLabelSingular = ' module within 10% of best module'
  const healthyLabelPlural = ' modules within 10% of best module'
  let healthyLabel = (data.raw.ratings.healthy > 1)? data.raw.ratings.healthy + healthyLabelPlural: data.raw.ratings.healthy + healthyLabelSingular;

  const expectedLabelSingular = ' module within 10-35% of best module'
  const expectedLabelPlural = ' modules within 10-35% of best module'
  let expectedLabel = (data.raw.ratings.expected > 1)? data.raw.ratings.expected + expectedLabelPlural: data.raw.ratings.expected + expectedLabelSingular;

  const underPerformingLabelSingular = ' module lower than 35% of best module';
  const underPerformingLabelPlural = ' modules lower than 35% of best module';
  let underPerformingLabel = (data.raw.ratings.under_performing > 1)? data.raw.ratings.under_performing + underPerformingLabelPlural: data.raw.ratings.under_performing + underPerformingLabelSingular;


  let _data = [data.healthy, data.expected, data.under_performing];
  if (data.healthy === '-' &&
      data.expected === '-' &&
      data.under_performing === '-' &&
      data.raw.total_modules === 0){
    _data = [];
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader

        title="Module Health"        // action={
          //   <IconButton size="small">
          //     <RefreshIcon />
          //   </IconButton>
          // }
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={{

              datasets: [
                {
                  data: _data,
                  backgroundColor: [
                    theme.palette.warning.main,
                    theme.palette.primary.main,
                    theme.palette.error.main
                  ],
                  borderWidth: 8,
                  borderColor: theme.palette.white,
                  hoverBorderColor: theme.palette.white
                }
              ],
              labels: [healthyLabel, expectedLabel, underPerformingLabel]
            }}
            options={options}
            plugins={[noDataMessage]}
          />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ModuleHealth.propTypes = {
  className: PropTypes.string
};

export default ModuleHealth;
