import React, { useState, useEffect } from 'react';
import {makeStyles} from '@material-ui/styles';
import { Grid, Typography} from '@material-ui/core';
//import {OptimiserSelector, CalendarsDateRangePicker, LatestInsights } from "./components";
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment';

// import parseDateRFC3339 from "../../helpers/parseDateRFC3339";

import {InsightDefaultValues} from '../../types/insight'



import {fetchIcon, fetchDescription, SeverityType} from '../../types/insight';
import { PowerControllerLinkList} from './components';

import WattagePreviewGraph from '../../components/WattagePreviewGraph';
import MapWidget from '../../components/MapWidget';

import {DataService} from '../../App';

// const defaultInsight = {
//   id: "",
//   affected_devices: [],
//   detected_at: new Date(0),
// };

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  wattagegraph: {
    order: 10,
  },
  optimiserSelector: {
    order: 20,
    marginTop: 'auto',
    [theme.breakpoints.up('lg')]: {
      order: 30,
      marginTop: '-35.5em'
    },
    // [theme.breakpoints.up('md')]: {
    //   order: 30,
    // }
  },
  insightGrid: {
    order: 30,
    [theme.breakpoints.up('lg')]: {
      order: 20,
    },
  },

}));

const InsightDetail = (props) => {
  const classes = useStyles();

  const dataProvider = React.useContext(DataService);
  let insightId = props.match.params.id;

  const [insight, setInsight] = useState(InsightDefaultValues);

  useEffect(() => {
    dataProvider.Insight()
      .get(insightId)
      .then(insight => {
        setInsight(insight)
      }).catch(err => {
        console.log(err);
      });
  }, [dataProvider, insightId]);



  const getAlertSeverity = (severity) => {
    switch (severity){
      case SeverityType.Info:
        return 'info';
      case SeverityType.Critical:
        return 'error';
      case SeverityType.Warning:
        return 'warning';
      default:
        break;
    }
    return 'info';

  }

  // let graphFrom = new Date(insight.detected_from.getTime());// - 2 * 60 * 60 * 1000);
  // let graphTo   = new Date( .getTime());// + 2 * 60 * 60 * 1000);
  //let installIds = Object.keys(insight.affected_devices);
  //attempt to verify installId, and return 404 if not found
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        spacing={4}
      >
        <Grid
          item
          lg={3}
          xs={12}
        >

          <Typography variant="h2">
            { fetchDescription(insight.type) } { fetchIcon(insight.type) }
          </Typography>
          <Typography
            className={classes.locationText}
            color="textSecondary"
            variant="body1"
          >
            { moment(insight.detected_at).format('MMMM Do YYYY, h:mm:ss a') }
          </Typography>
          <br/>
          {/*<Typography*/}
          {/*  className={classes.locationText}*/}
          {/*  color="textSecondary"*/}
          {/*  variant="body1"*/}
          {/*>*/}

          {/*  Certainty: {insight.certainty }%*/}
          {/*</Typography>*/}
          {/*<Typography*/}
          {/*  className={classes.locationText}*/}
          {/*  color="textSecondary"*/}
          {/*  variant="body1"*/}
          {/*>*/}

          {/*</Typography>*/}
          {/*<Typography*/}
          {/*  className={classes.locationText}*/}
          {/*  color="textSecondary"*/}
          {/*  variant="body1"*/}
          {/*>*/}

          {/*</Typography>*/}
          <Alert   severity={ getAlertSeverity(insight.severity) }>
            <AlertTitle>{insight.message }</AlertTitle>
            Certainty: {insight.certainty }% <br/>
            Detected By: {insight.generated_by }
          </Alert>
          {/*<Card>*/}
          {/*  <CardContent >*/}

          {/*  </CardContent>*/}
          {/*</Card>*/}
          <br/>
          <MapWidget  markers={ (insight.affected_devices != null) ? insight.affected_devices.map(affectedDevice => affectedDevice.location) : null } zoom={20} />
          {/*<img src="/images/map-placeholder.png" height={250} />*/}
          <br/>
          <Typography
            className={classes.locationText}
            color="textSecondary"
            variant="body1"
          >
            Reference: {insight.id }
          </Typography>



        </Grid>
        <Grid
          item
          lg={3}
          xs={12}
        >
          <WattagePreviewGraph
            key="BRI857"
            installid="BRI857"
            from={insight.detected_from}
            to={insight.detected_to}
            optimisers={[]}
            insights={[]}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}
        >
          <Typography variant="body">
            <PowerControllerLinkList affectedDevices={insight.affected_devices} from={insight.detected_from} to={insight.detected_to}/>
          </Typography>
        </Grid>

      </Grid>
    </div>
  );
};

export default InsightDetail;
