/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';

//import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';


import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular
  },
  sublist: {
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));


const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();



  function MenuItem(props) {
    let page = props.page;



    if ( page.hasOwnProperty('children') && page.children.length > 0) {
      return <SidebarNavMenu key={page.href} page={page} children={page.children} />;
    }
    return <SidebarNavMenuItem key={page.href} page={page} />;
  }

  function isMenuItemActive (page){
    //todo: this needs to be refactored to allow for urls with parameters:
    // e.g. /url/key/id/5 won't match /url/key
    // for now this works, so we'll fix this if it becomes a problem
    return (window.location.pathname === page.href);
  }

  function SidebarNavMenu(props) {
    let page = props.page;
    let children = props.children;

    let initialMenuState = isMenuItemActive(page);
    for (let _pageIndex in children) {
      if (!Object.prototype.hasOwnProperty.call(children, _pageIndex)) {
        continue;
      }
      if (isMenuItemActive(children[_pageIndex])) {
        initialMenuState = true;
        break;
      }
    }

    const [open, setOpen] = React.useState(initialMenuState);

    const handleClick = () => {
      setOpen(!open);
    };
    return <>
      <ListItem
        className={classes.item}
        disableGutters
        key={page.title}
        onClick={handleClick}
      >
        <Button
//          activeClassName ={classes.active}
          className={classes.button}
          //component={CustomRouterLink}
          //to={page.href}
        >
          <div className={classes.icon}>{page.icon}</div>
          {page.title}

        </Button>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open}
        timeout="auto"
        unmountOnExit
      >
        {children.map(_page => (
          <List
            {...rest}
            className={classes.sublist}
            key={_page.href}
            //className={clsx(classes.root, className)}
          >
            <SidebarNavMenuItem key={_page.href} page={_page} />
          </List>

        ))}
      </Collapse>
    </>;

  }


  const SidebarNavMenuItem = (props) => {
    let page = props.page;
    return <ListItem
      className={classes.item}
      disableGutters
      key={page.title}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        to={page.href}
      >
        <div className={classes.icon}>{page.icon}</div>
        {page.title}
      </Button>
    </ListItem>;

  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <MenuItem key={page.href}  page={page} />

      ))}
    </List>
  );
};



SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  key:  PropTypes.string
};

export default SidebarNav;
