import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelineIcon from  '@material-ui/icons/Timeline';
//import FlightIcon from '@material-ui/icons/Flight';
//import LightBulbIcon from  '@material-ui/icons/EmojiObjects';
//import DocumentIcon from '@material-ui/icons/Description';
import AccountBoxIcon from '@material-ui/icons/AccountBox';


import { Profile, SidebarNav } from './components';
import {DataService} from '../../../../App';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const dataProvider = React.useContext(DataService);

  const classes = useStyles();

  const [isAccountOwner, setIsAccountOwner] =  useState(false);

  useEffect(() => {
    dataProvider.Account().getCurrentAccount().then((account) => {
      setIsAccountOwner(account.is_current_user_owner);
    })
  }, [dataProvider])

  //todo: implmement full role / user system with acls on data api
  //for now ujst restrict to account owner.
  let pages = [];
  pages.push({
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
  });
  pages.push({
    title: 'Module Data',
    href: '/solar-string/list',
    icon: <TimelineIcon />
  });
  // pages.push({
  //   title: 'Drones',
  //   href: '/drone/list',
  //   icon: <FlightIcon />
  // });
  // pages.push({
  //   title: 'Insights',
  //   href: '/insight/list',
  //   icon: <LightBulbIcon />
  // });
  // pages.push({
  //   title: 'Reports',
  //   href: '/report/cleaning/list',
  //   icon: <DocumentIcon />,
  //   children: [
  //     {
  //       title: 'Panel Cleaning',
  //       href: '/report/cleaning/list',
  //     },
  //     {
  //       title: 'Maintenance',
  //       href: '/report/maintenance/list',
  //     },
  //   ]
  // });
  let accountChildPages = [];
  accountChildPages.push({
    title: 'Profile',
    href: '/account',
  });
  accountChildPages.push({
    title: 'Api Access',
    href: '/api-access/list',
  });
  if (isAccountOwner) {
    accountChildPages.push({
      title: 'Users',
      href: '/users',
    });
    // accountChildPages.push({
    //   title: 'Billing',
    //   href: '/billing',
    // });
    accountChildPages.push({
      title: 'Settings',
      href: '/settings',
    });
  }
  pages.push({
    title: 'Account',
    href: '/account',
    icon: <AccountBoxIcon />,
    children: accountChildPages
  });



  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        <Divider className={classes.divider} />
        <Profile />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
