//import {Fade, TextField, Typography} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    listStyleType: 'none',
  },
  subList: {
    listStyleType: 'none',
    marginLeft: theme.spacing(2),
  }
}));
const Terms = () => {
  const classes = useStyles();
  return (<>
    <Typography
      variant="h3"
    >CQSola Data Dashboard Terms of Use</Typography>
    <Typography
      variant="body1"
    ><div>
        {/* using unordered list so that in no circumstance does the numbering fail. Very important these numbers stay correct  */ }
        <ul className={classes.list} >
          <li>1. Introduction</li>
          <li>
            <ul className={classes.subList}>
              <li>1.1 <strong>CQSola Data Dashboard</strong>. The CQSola Data Dashboard (<strong>App</strong>) is licensed and operated by CQSola Pty Ltd ACN 632 445 955 (<strong>CQSola</strong>).</li>
              <li>1.2 <strong>Acceptance of these User Terms</strong>. By installing, accessing or using the App, you (<strong>you</strong>) warrant that you  are authorised as an Account Owner or End User under clause 1.3, and agree to the following terms and conditions (<strong>User Terms</strong>). If you do not agree to these User Terms, you must not install, access or use the App.</li>
              <li>1.3 <strong>Authorisation</strong>. You may install and access the App if you register an account on the App (<strong>Account</strong>). You are licensed to use the App for the purposes as set out in clause 2.1 if:
                <ul className={classes.subList}>
                  <li>(a) you are a company, partnership or other organisation that has linked its Account with its compatible CQSola hardware (<strong>Hardware</strong>)(<strong>Account Owner</strong>);</li>
                  <li>or</li>
                  <li>(b) you are an individual who is nominated as a user of the App by an Account Owner pursuant to clause  2.1(a)(iii) (<strong>End User</strong>).</li>
                </ul>
              </li>
              <li>1.4 <strong>Cancelling an account</strong>. You may cancel your Account and terminate this agreement at any time by logging into the App and electing to cancel your Account.</li>
              <li>1.5 <strong>Amendment</strong>. CQSola may amend these User Terms from time to time by at least 30 days' notice in writing.</li>
            </ul>
          </li>
          <li>2. Use of the App</li>
          <li>
            <ul className={classes.subList}>
              <li>2.1 <strong>Grant of Licence. </strong>Subject to ongoing compliance with these User Terms, CQSola grants a revocable, non-exclusive, non-transferable licence:
                <ul className={classes.subList}>
                  <li>(a) to Account Owners, to create an account and install, access and use the
                        App to:
                  <ul className={classes.subList}>
                    <li>
                            (i) access reports (<strong>Reports</strong>) based on data generated by
                            CQSola hardware (<strong>Hardware</strong>) located at the Account Owner's
                            premises;
                    </li>
                    <li>
                            (ii) copy or share Reports, provided that the Reports must not be
                            altered, and CQSola must be attributed as the author of the Reports;
                    </li>
                    <li>
                            (iii) add, remove and manage permissions for End Users, and
                    </li>
                  </ul>
                  </li>
                  <li>
                        (b) to End Users, to install and use the App to access, copy or share
                        Reports subject to the permissions granted to each End User by the Account
                        Owner, and clause 2.1(a)(ii).
                  </li>
                </ul>
              </li>
              <li> 2.2 <strong>Disclaimers</strong>. Account Owners and End Users agree that:
                <ul className={classes.subList}>
                  <li>
                        (a) the App is in beta testing, and its functionality may change as it is
                        updated from time to time;
                  </li>
                  <li>
                        (b) the Reports are intended to provide indicative information about
                        performance only. They are based on data generated by the Hardware, the
                        accuracy and completeness of which may be affected by many factors outside
                        CQSola's reasonable control;
                  </li>
                  <li>
                        (c) CQSola does not warrant, represent or guarantee the completeness,
                        accuracy, reliability, and currency of any data or information downloaded,
                        uploaded, or otherwise viewed or obtained through the use of the App;
                  </li>
                  <li>
                        (d) any data or information downloaded or otherwise viewed, obtained or
                        acquired through the use of the App is at your sole risk and, to the extent
                        permitted by law, CQSola will not be liable or responsible for any damage
                        to you or your property in connection with such data and information; and
                  </li>
                  <li>
                        (e) except as expressly provided in these User Terms, and to the extent
                        permitted by law:
                    <ul className={classes.subList}>
                      <li>
                            (i) the App and all Reports are provided on an "as is" and "as available"
                            basis; and
                      </li>
                      <li>
                            (ii) CQSola disclaims all conditions, representations, warranties and
                            guarantees, expressed or implied, including availability, uninterrupted or
                            error-free operation, currency, merchantability or fitness for a particular
                            purpose.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>3. Account Owner obligations</li>
          <li>
            <ul className={classes.subList}>
              <li>3.1 <strong>Warranties</strong>. The Account Owner warrants that:
                <ul className={classes.subList}>
                  <li>(a) the Account Owner and its End Users will use the App in good faith, and
                        in accordance with these User Terms;</li>
                  <li>(b) the information provided to CQSola by the Account Owner and its End
                        Users is true, complete, current and submitted in good faith; and</li>
                  <li>(c) the use of the App and any Reports by the Account Owner and any End
                        Users will comply with any applicable laws.</li>
                </ul>
              </li>
              <li>3.2 <strong>Prohibited Conduct</strong>.
                    Account Owners must not (and must ensure that its End Users do not):
              <ul className={classes.subList}>
                <li>(a) use the App in such a way that may lead to malfunctions or degradation
                        of performance of the App;</li>
                <li>(b) attempt to circumvent the security of the App;</li>
                <li>(c) mislead or harass others;</li>
                <li>(d) harvest information for purposes outside these User Terms, or allow or
                        facilitate anyone else to do so;</li>
                <li>(e) resell, redistribute or commercially exploit any part of the App or its
                        functionality without CQSola's prior written consent;</li>
                <li>(f) copy, decompile, or reverse-engineer any part of the App; or</li>
                <li>(g) adapt, modify, or create derivative works of the App or any Reports.</li>
              </ul>
              </li>
            </ul>
          </li>
          <li>4. CQSola rights</li>
          <li>
            <ul className={classes.subList}>
              <li>4.1 <strong>Subscription Fees. </strong>The App
                    is free to use and has no subscription fee. CQSola may introduce
                    subscription plans and fees at any time by at least 30 days' notice to
                    the Account Owner.</li>
              <li>4.2 <strong>Maintenance</strong>. From time to time, CQSola or a
                    third-party service provider may update or perform maintenance on the
                    underlying software or hosting environment and perform other routine
                    software or hardware maintenance. CQSola will use commercially reasonable
                    efforts to provide notice to Account Owners in advance of outages due to
                    such planned maintenance.</li>
              <li>4.3 <strong>CQSola rights relating to changes</strong>. CQSola retains the
                    right to enhance, modify or alter the operation of the App or the
                    underlying software or technology from time to time. CQSola may offer new
                    functionality and other services for a separate fee and subject to the
                    parties' agreement to separate terms and conditions.</li>
              <li>4.4 <strong>Suspension or termination by CQSola</strong>. CQSola may
                    immediately suspend or terminate the account of any Account Owner or End
                    User, at any time upon notice, for the following reasons:
              <ul className={classes.subList}>
                <li>(a) CQSola reasonably considers the Account Owner or any of its End Users
                        has breached these User Terms;</li>
                <li>(b) to comply with CQSola's obligations under any applicable laws, or where
                        requested by law enforcement or other government agencies;</li>
                <li>(c) suspected fraud, security breach or suspicious activity in relation to
                        any account; or</li>
                <li>(d) an Account Owner's account is inactive for more than 3 years, </li>
                <li>and any suspension or termination of the Account Owner's account
                        automatically applies to its related End User accounts.</li>
              </ul>
              </li>
            </ul>
          </li>
          <li>5. Intellectual Property Rights</li>
          <li>
            <ul className={classes.subList}>
              <li>5.1 <strong>App</strong>. The App consists of, and contains materials that
                    are owned by CQSola and its licensors. The App, Reports and associated
                    materials provided with the App are protected by intellectual property laws
                    and must not be used or copied by the Account Owner or its End Users except
                    as contemplated by these User Terms, or otherwise with the prior written
                    consent of CQSola.</li>
              <li>5.2 <strong>Data</strong>. CQSola retains all rights in the raw data
                    generated by the Hardware.</li>
              <li>5.3 <strong>Feedback</strong>. If a user (i.e. the Account owner or its End
                    Users) submit feedback or a suggestion for improving the App, that user
                    assigns to CQSola any Intellectual Property Rights which that user have in
                    that suggestion or feedback.</li>
            </ul>
          </li>
          <li>6. Privacy and confidentiality</li>
          <li>
            <ul className={classes.subList}>
              <li>6.1 <strong>CQSola responsibilities</strong>. CQSola will take reasonable
                    steps to preserve the security of any personal information it receives from
                    Account Owners and End Users. All personal information will be handled by
                    CQSola in accordance with its privacy policy, which can be viewed at
              <a href="https://www.cqsola.com.au/privacy-policy">
                <strong>https://www.cqsola.com.au/privacy-policy</strong>
              </a>.</li>
              <li>6.2 <strong>Confidentiality obligations</strong>. Account Owners and End
                    Users acknowledge and agree that information relating to the function or
                    operation of the App and associated intellectual property, screen or menu
                    hierarchies, techniques, algorithms, know-how, current and future, relating
                    to the App other than information that is known publically (    <strong>Confidential Information</strong>) is confidential information of
                    CQSola. Account Owners and End Users agree to keep confidential all
                    Confidential Information and not to use the Confidential Information except
                    to the extent necessary to use the App in accordance with these User Terms.</li>
            </ul>
          </li>
          <li>7. Limitation of liability and indemnity</li>
          <li>
            <ul className={classes.subList}>
              <li>7.1 <strong>General</strong>. Subject to clause 7.3, CQSola excludes any
                    and all liability to Account Owners and End Users however arising
                    (including negligence) for:
              <ul className={classes.subList}>
                <li>(a) consequential loss, including without limitation loss or unauthorised
                        disclosure of data, loss of opportunity, loss of revenue, loss of
                        anticipated profits or savings, loss of production, and inconvenience;</li>
                <li>(b) losses or expenses suffered or incurred in relation to reliance on any
                        Report, or any interruption in the operation of the App or an Excluded
                        Incident;</li>
                <li>(c) losses or expenses suffered or incurred as a result of CQSola's breach
                        of these User Terms to the extent the breach is caused by an event or
                        circumstances beyond CQSola's reasonable control;</li>
                <li>(d) CQSola's reasonable actions in denying anyone access to the App for
                        security reasons, non-payment or default; and</li>
                <li>(e) actions taken in relation to these User Terms in accordance with
                        CQSola's obligations at law or any order issued by a court of law or
                        relevant government authority, whether directly or indirectly arising in
                        connection with the App.</li>
              </ul>
              </li>
              <li>7.2 <strong>Maximum liability</strong>. To the extent permitted by law,
                    CQSola’s aggregate liability for all claims arising from these User
                    Terms, regardless of the nature of such claim and including under any
                    indemnity, must not exceed the greater of any subscription fees paid by
                    the Account Owner during the 12 months preceding the date of the event
                    giving rise to a claim (if any) and $100.00.</li>
              <li>7.3 <strong>Non-Excludable Rights</strong>. Nothing in these User Terms
                    is intended to have the effect of excluding the Australian Consumer
                    Law, as set out in Schedule 2 to the        <em>Competition and Consumer Act 2010</em> (Cth) and the corresponding
                    provisions of any fair trading legislation, or any other applicable law
                    that cannot be excluded, restricted or modified by agreement between
                    the Account Owner or End User, and CQSola (        <strong>Non-Excludable Rights</strong>). To the maximum extent
                    permitted by law, the liability of CQSola in respect of any breach of
                    or failure to comply with any Non-Excludable Right (which cannot be
                    excluded but which can be limited) is limited to CQSola doing one or
                    more of the following (at its election):
              <ul className={classes.subList}>
                <li>(a) if the breach is in respect of goods: the replacement of the goods or
                        the supply of equivalent goods; the repair of the goods; the payment of the
                        cost of replacing the goods or of acquiring equivalent goods; or the
                        payment of the cost of having the goods repaired; or</li>
                <li>(b) if the breach is in respect of services: the supply of the services
                        again; or the payment of the cost of having the services supplied again.</li>
              </ul>
              </li>
              <li>7.4 <strong>Indemnity</strong>. The Account Owner agrees to indemnify
                    CQSola against any claim or liability arising from or in relation to:
              <ul className={classes.subList}>
                <li>(a) any breach of its warranties or obligations under clauses 3.1 and 3.2;</li>
                <li>(b) breach of any law or infringement of any third party rights including
                        Intellectual Property rights,</li>
                <li>by the Account Owner or any of its End Users, except to the extent caused
                        by CQSola.</li>
              </ul>
              </li>
            </ul>
          </li>
          <li>8. General</li>
          <li>
            <ul className={classes.subList}>
              <li>
                    8.1 <strong>Force majeure</strong>. Notwithstanding anything else contained
                    in these Terms, CQSola will not be liable for any delay in or failure to
                    comply with these User Terms if such delay or failure is caused by
                    circumstances beyond CQSola's reasonable control, including without
                    limitation, fire, flood, act of God, strikes, lock outs, stoppage of work,
                    trade disputes or any act of war or terrorism.
              </li>
              <li>8.2 <strong>Notices</strong>. All notices under these User Terms must be in
                    writing. In the case of CQSola, notice may be given to the Account Owner by
                    email on or through the App.</li>
              <li>8.3 <strong>Survival</strong>. Where applicable clauses, 3, 5, 6, 7 and, 8
                    still survive expiry or termination of these Terms of Use.</li>
              <li>8.4 <strong>Assignment</strong>. The Account Owner must not assign its
                    rights or transfer its rights and obligations under these User Terms
                    without the prior written consent of CQSola. CQSola may assign its rights
                    or transfer its rights and obligations under these User Terms without the
                    Account Owner or End User's consent.</li>
              <li>8.5 <strong>Governing Law</strong>. This agreement is governed by the laws
                    in force in Queensland, Australia, without regard to conflict of law
                    principles. Each party irrevocably submits to the exclusive jurisdiction of
                    the courts of Queensland, Australia and courts of appeal from them.</li>
              <li>8.6 <strong>Severability</strong>. If any part of these User Terms is
                    determined to be invalid or unenforceable pursuant to applicable law, the
                    remainder of these User Terms must continue in effect.</li>
              <li>8.7 <strong>Waiver</strong>. No waiver by either party of any provision or
                    any breach of these User Terms constitutes a waiver of any other provision
                    or breach of these User Terms and no waiver must be effective unless made
                    in writing. </li>
            </ul>
          </li>
        </ul>
      </div></Typography>



  </>
  );
}
Terms.propTypes = {
  className: PropTypes.string
};

export default Terms;
