import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { AccountProfile, AccountDetails, Password } from './components';


import {DataService} from '../../App';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  width100: {
    width: '100%'
  }
}));

const Account = () => {
  const classes = useStyles();

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);
  const [account, setAccount] = useState({});

  const [user, setUser] = useState({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    mobile_number: '',
    is_active: true,
    created_at: '1970-01-01T00:00:00.000Z',
    updated_at: '1970-01-01T00:00:00.000Z',
    enable_2fa: false,
    country_code: '',
    timezone: '',


    //city: 'Los Angeles',
    //country: 'USA',
    //timezone: 'GTM-7',
    avatar: '/images/avatars/empty-user-icon.png'
  });

  useEffect(() => {
    dataProvider.User().getCurrentUser()
      .then((user) => {
        setUser(user)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider]);

  useEffect(() => {
    dataProvider.Account().getCurrentAccount()
      .then((account) => {
        setAccount(account)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider]);
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              className={classes.width100}
            >
              <AccountProfile account={account} user={user} />
            </Grid>
            <Grid
              item
              className={classes.width100}
            >
              <Password reCaptchaToken={''} />
            </Grid>

          </Grid>
        </Grid>

        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails
            user={user}
          />
        </Grid>

      </Grid>
    </div>
  );
};

export default Account;
