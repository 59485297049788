
import AccountService from './AccountService';
import CommsUnitService from './CommsUnitService';
import DeviceEventLogService from './DeviceEventLogService';
import DeviceRegistrationService from './DeviceRegistrationService';
import InsightService from './InsightService';
import SessionService from './SessionService';
import StatisticsService from './StatisticsService';
import UserApiTokenService from './UserApiTokenService';
import UserService from './UserService';



export default class DataProvider {

  constructor(props) {

    if (!Object.prototype.hasOwnProperty.call(props, 'errorHandlers')){
      props.errorHandlers = null;
    }
    this.config = {};


    this.config.errorHandlers =  (props.errorHandlers != null )? props.errorHandlers : this.generateErrorHandlers(props.history);

    this.config.afterFetch = this.afterFetchIntercept.bind(this);

    this.accountService = new AccountService(this.config);
    this.commsUnitService = new CommsUnitService(this.config);
    this.deviceEventLogService = new DeviceEventLogService(this.config);
    this.deviceRegistrationService = new DeviceRegistrationService(this.config);
    this.insightService = new InsightService(this.config);
    this.sessionService = new SessionService(this.config);
    this.statisticsService = new StatisticsService(this.config);
    this.userService = new UserService(this.config);
    this.userApiTokenService = new UserApiTokenService(this.config);
  }

  // static Init = function(config): DataProvider {
  //   if (window.__cqdata_data_service_instance == null){
  //     window.__cqdata_data_service_instance = new DataProvider(config);
  //   }
  // };

  // static GetInstance = function(): DataProvider {
  //   //todo: could do this with react context
  //   //but at the moment there are no react dependencies here
  //   if (window.__cqdata_data_service_instance == null){
  //     throw new Error('Data needs Initialisation. Please run Data.Init() with your config first');
  //   }
  //   return window.__cqdata_data_service_instance;
  // };

  afterFetchIntercept(response) {
    if (typeof response.status !== 'number'){
      return response;
    }
    if (Object.prototype.hasOwnProperty.call(this.config.errorHandlers, response.status) && typeof this.config.errorHandlers[response.status] === 'function') {
      this.config.errorHandlers[response.status]();
    }
    return response;
  }

  generateErrorHandlers(history) {
    return {
      403: (response) => {
        //redirect to login handler.
        //403's don't immediately mean to redirect to login. only 403's with unauthorized tokens.
        if (response == null){
          return;
        }
        if (!Object.prototype.hasOwnProperty.call(response, 'message')) {
          return;
        }
        switch(response.message) {
          case 'Invalid authorization token':
            history.push('/login');
            return;
          default:
            break;
        }
        return;
      }
    };
  }
  Account(): AccountService {
    return this.accountService;
  }

  CommsUnit(): CommsUnitService {
    return this.commsUnitService;
  }

  DeviceEventLog(): DeviceEventLogService {
    return this.deviceEventLogService;
  }

  DeviceRegistration(): DeviceRegistrationService {
    return this.deviceRegistrationService;
  }

  Insight(): InsightService {
    return this.insightService;
  }

  Session(): SessionService {
    return this.sessionService;
  }

  Statistics(): StatisticsService {
    return this.statisticsService;
  }


  User(): UserService {
    return this.userService;
  }

  UserApiToken(): UserApiTokenService {
    return this.userApiTokenService;
  }

}

export { default as DataProvider } from '.';
