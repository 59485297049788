import React, {useState, useEffect} from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Table, TableHead, TableRow, TableCell, TableBody, Divider, Avatar
} from '@material-ui/core';

import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {SearchInput} from '../../components';
import md5 from 'md5';
import Query from '../../data/Query';
import {DataService} from '../../App';

const useStyles = makeStyles(theme => ({
  root: {},
  spacer: {paddingTop: theme.spacing(2)},
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  selectUserContainer:{
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  avatar: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
}));

const getInitials = user => {
  return user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase();
}

const UserSelector = (props) => {
  const { className, selectedUser, setSelectedUser,  title, open, setOpen, onConfirm, excludeCurrentUser, ...rest  } = props;

  const dataProvider = React.useContext(DataService);

  const classes = useStyles();
  let toExcludeCurrentUser = true;
  if (excludeCurrentUser != null){
    toExcludeCurrentUser = (excludeCurrentUser === true);
  }


  //const [selectedUser, setSelectedUser] = useState(null);

  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [currentUserEmail, setCurrentUserEmail] = useState([]);

  useEffect( () => {
    dataProvider.User().getCurrentUser().then(user => {
      setCurrentUserEmail(user.email);
    });
  }, [dataProvider]);

  useEffect( () => {
    let listQuery = new Query({ filter: {query: query,  page: 0, page_size: 5} });
    dataProvider.Account().listUsersInAccount(listQuery).then(list => {
      if (toExcludeCurrentUser) {
        list.users = list.users.filter(user => user.email !== currentUserEmail);
      }
      setUsers(list.users);
    }).catch( error => {})
  }, [dataProvider, query, toExcludeCurrentUser, currentUserEmail])

  const handleSearchOnChange = event => {
    event.preventDefault();
    setQuery(event.target.value);
  };
  //let filteredUsers = users;
  // let filteredUsers = users.filter((user) => {
  //   return user.email.toLowerCase().includes(query.toLowerCase()) ||
  //     user.first_name.toLowerCase().includes(query.toLowerCase()) ||
  //     user.last_name.toLowerCase().includes(query.toLowerCase())
  // });

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        open={open}
        onClose={() => {setOpen(false); setSelectedUser(null); }}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog"><Typography variant="h4">{title}</Typography></DialogTitle>
        <DialogContent>
          <SearchInput
            className={classes.searchInput}
            placeholder="Search user"
            value={query}
            onChange={handleSearchOnChange}
          />
          <div className={classes.spacer} />
          <Table>
            <TableHead>
              <TableRow
              >

                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(users.length < 1)?
                <TableRow
                  className={classes.tableRow}
                  hover
                >
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No Users Found</Typography>
                  </TableCell>
                </TableRow>
                : <>
                  {users.map(user => (
                    <TableRow
                      className={classes.tableRow}
                      data-user-email={user.email}
                      hover
                      key={user.email}
                      selected={selectedUser != null && Object.prototype.hasOwnProperty.call(selectedUser, 'email') && selectedUser.email === user.email}
                      onClick ={(cell) => {

                        let email = cell.target.closest('tr').dataset.userEmail;
                        let foundUser = users.find(user => user.email === email);
                        if ( foundUser != null ) {
                          setSelectedUser(foundUser);
                        }
                      }}

                    >
                      <TableCell>
                        <Avatar
                          alt="Profile image from gravatar.com"
                          src={'https://www.gravatar.com/avatar/' + md5(user.email) + '?'}
                        >{getInitials(user)}</Avatar>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{user.first_name} {user.last_name}</Typography>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{'Admin'}</TableCell>
                    </TableRow>
                  ))}
                </> }
            </TableBody>
          </Table>
          <div className={classes.spacer} />
          <Divider />
          <div className={classes.selectUserContainer}>
            {(selectedUser !== null &&
              Object.prototype.hasOwnProperty.call(selectedUser, 'email') && selectedUser.email !== null &&
              Object.prototype.hasOwnProperty.call(selectedUser, 'first_name') && selectedUser.first_name !== null &&
              Object.prototype.hasOwnProperty.call(selectedUser, 'last_name') && selectedUser.last_name !== null
            )? <>
                <Avatar
                  alt="Profile image from gravatar.com"
                  className={classes.avatar}
                  src={'https://www.gravatar.com/avatar/' + md5(selectedUser.email) + '?'}
                >{selectedUser.first_name}</Avatar>
                <Typography variant="body1">{selectedUser.first_name} {selectedUser.last_name}</Typography>
              </>: <>
                <Avatar
                  className={classes.avatar} />
                <Typography variant="body1">No User Selected</Typography>
              </>}
          </div>

        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={selectedUser===null}
            onClick={() => {
              setOpen(false);
              onConfirm(selectedUser);
            }}
            color="default"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UserSelector;
