import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {Typography, TextField, Button} from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  nameGrid: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  expiredLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    minHeight: 436,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  resetPasswordButton: {
    margin: theme.spacing(2, 0)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  },
  MFATick: {
    display: 'block',
    animation: 'fade-out 5s'
  },
  MFATickHidden: {
    display: 'none'
  }
}));




const MfaSmsVerify = props => {
  //const className = props.className;
  //const style = props.style;
  const onChange = props.onChange;
  const hasError = props.hasError;
  const errorText = props.errorText;
  const value = props.value;
  const resendMFA = props.resendMFA;
  const isDisabled = props.isDisabled;
  const showSentTick = props.showSentTick;
  // const {
  //   className,
  //   onChange,
  //   style,
  //   hasError,
  //   errorText,
  //   value,
  //   resendMFA,
  //   isDisabled,
  //   showSentTick
  // } = props;

  const classes = useStyles();

  return (<>
    <span className={classes.expiredLinkContainer} >
      <PhoneIphoneIcon />
      <Typography
        className={classes.contentBody}
        style={{paddingLeft:'0.5rem', justifyContent: 'unset'}}
        variant='h5'
      >Check your Phone</Typography>
    </span>
    <Typography>
       To Confirm your identity, please enter in the code that was sent to your phone.
    </Typography>
    <TextField
      className={classes.textField}
      error={hasError}
      fullWidth
      helperText={
        errorText
      }
      label="Verification Number"
      name="mfa_verify"
      onChange={onChange}
      type="text"
      value={value}
      variant="outlined"
    />
    <span className={classes.expiredLinkContainer} >
      <Button
        // className={classes.submitButton}
        style={{ justifyContent: 'unset'}}
        disabled={isDisabled}
        onClick={resendMFA}
        type='button'
      >Send Again</Button>
      <CheckCircleOutlineIcon
        className={showSentTick? classes.MFATick : classes.MFATickHidden}
        style={{paddingLeft:'0.5rem', animationFillMode: 'forwards'}}
      />
    </span>
  </>
  );
};

MfaSmsVerify.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default MfaSmsVerify;
