import {config} from '"../../config';
import Translations from '../../helpers/translations';


class DeviceRegistration {
    id: "";
    install_id: "";
    group_tags: "";

    constructor(obj) {
      obj && Object.assign(this, obj);
    }


}

// eslint-disable-next-line
class DeviceRegistrationList {
  data: Array<DeviceRegistration>;
  meta: {};
}

const apiUrl = config.cqsolaDataApi;
export default class DeviceRegistrationService {

  constructor(config) {
    if (config == null){
      config = {};
    }
    this.config = config;
  }


  //
  // static register = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };
  //
  // static get = function (): Promise<CommsUnit> {
  //   return new Promise((resolve, reject) => {
  //     // const userSelflocalStorageKey = 'user_self'
  //     // let userSelfData = window.localStorage.getItem(userSelflocalStorageKey);
  //     // if (userSelfData != null  ) {
  //     //   let userSelf = new User(JSON.parse(userSelfData));
  //     //   let sessionUserData = UserService.getCurrentSession();
  //     //   if (sessionUserData.email !== userSelf.email){
  //     //     window.localStorage.setItem(userSelflocalStorageKey, null);
  //     //   } else {
  //     //     resolve(userSelf);
  //     //     return;
  //     //   }
  //     // }
  //     let dataUrl = `${apiUrl}/user/self`;
  //     fetch(dataUrl, {
  //       method: 'get',
  //       credentials: 'include',
  //     })
  //       .then((response) => {
  //         switch (response.status) {
  //           case 200:
  //             return response.json();
  //           case 403:
  //             throw new Error('Unauthorised to Access Resource');
  //           default:
  //             throw new Error('Could not fetch current user details');
  //         }
  //       })
  //       .then(function (userSelfResponse) {
  //         if (userSelfResponse.hasOwnProperty('error') && userSelfResponse.error != null) {
  //           reject(new Error(userSelfResponse.error));
  //         } else {
  //           //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
  //           resolve(new User(userSelfResponse.user));
  //         }
  //       })
  //       .catch((error) => {
  //         reject(new Error(error));
  //       })
  //
  //   });
  // }
  //
  // static release = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };

  claim(claimKey, tags): Promise<boolean> {
    let service = this;
    let dataUrl = `${apiUrl}/device-registration/claim`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({claim_key: claimKey})
      })
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(function (response) {

          if (Object.prototype.hasOwnProperty.call(response,'error') && response.error != null) {
            reject(new Error(Translations.Errors.ClaimKeyInvalid));
            return;
          } else {
            //validate response
            if (!Object.prototype.hasOwnProperty.call(response, 'is_valid')){
              reject(new Error(Translations.Errors.ClaimKeyInvalid));
              return;
            }
            resolve(response.is_valid);
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });
  }

  validate(claimKey): Promise<boolean> {
    let service = this;
    let dataUrl = `${apiUrl}/device-registration/validate`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({claim_key: claimKey})
      })
        .then(service.config.afterFetch)
        .then( (response) => {
          switch (response.status){
            case 200:
              return response.json();
            case 400:
              return response.json();
            case 403:
              throw new Error('Unauthorised to Access Resource');
            default:
              throw new Error(Translations.Errors.ServerUnavailable);
          }
        })
        .then(function (response) {

          if (Object.prototype.hasOwnProperty.call(response,'error') && response.error != null) {
            reject(new Error(Translations.Errors.ClaimKeyInvalid));
            return;
          } else {
            //validate response
            if (!Object.prototype.hasOwnProperty.call(response, 'is_valid')){
              reject(new Error(Translations.Errors.ClaimKeyInvalid));
              return;
            }
            resolve(response.is_valid);
          }
        })
        .catch((error) => {
          reject(new Error(error));
        })

    });

  }
}

export {DeviceRegistrationService};
