import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardActions,
  Divider,
  Button,
  Typography
} from '@material-ui/core';

import validate from 'validate.js';

//import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import withWidth from '@material-ui/core/withWidth';
import ConfirmDialog from '../../../../components/ConfirmDialog';

//loadReCaptcha(config.reCaptchaSiteKey, () => {});




const schema = {
  name: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 32
    }
  },
  legalName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 32
    }
  },
  primaryEmail: {
    presence: {allowEmpty: true, message: 'is required'},
  }
}



const AccountBackup = props => {
  const { className, account, ...rest } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  //const [confirmTransferOpen, setConfirmTransferOpen] = React.useState(false);
  //const classes = useStyles();
  //let defaultCountryCode = 'AU';
  //const [selectedCountry, setSelectedCountry] = useState(defaultCountryCode);
  // const [values, setValues] = useState(user);


  const accountToFormValues = account => {
    return {
      name:  (Object.prototype.hasOwnProperty.call(account ?? {},'name'))? account.name : '',
      legalName:  (Object.prototype.hasOwnProperty.call(account ?? {},'legal_name'))? account.legal_name : '',
      primaryEmail:  (Object.prototype.hasOwnProperty.call(account ?? {},'primary_email'))? account.primary_email : '',
    }
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: accountToFormValues(account),
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false
    }
  });

  useEffect(() => {
    let newValues =  accountToFormValues(account);
    setFormState(formState => ({
      ...formState,
      values: newValues
    }));

  }, [account]);


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);




  return (
    <Card
      {...rest}
    >
      <CardHeader
        subheader="Backup Account and retrieve all data"
        title="Backup Account"
      />
      <Divider />
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {setConfirmOpen(true)}}
        >
          Backup Account
        </Button>
        <ConfirmDialog
          title="Backup Account"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={() => { }}
        >
          <Typography
            variant="body1"
          >
            Are you sure you want to download all data from account?
          </Typography>
        </ConfirmDialog>

      </CardActions>

    </Card>
  );
};

AccountBackup.propTypes = {
  className: PropTypes.string
};

export default withWidth()(withRouter(AccountBackup));
