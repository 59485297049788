import React, {useEffect, useState} from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, TextField
} from '@material-ui/core';

import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import validate from "validate.js";

const useStyles = makeStyles(theme => ({
  root: {},
  spacer: {
    paddingTop: theme.spacing(1)
  }
}));



const ConfirmDialogWithTextVerify = (props) => {
  const { className, title, validationText, children, open, setOpen, onConfirm, ...rest  } = props;
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      verificationPhrase: ''
    },
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false
    }
  });
  const schema = {
    verificationPhrase: {
      presence: {allowEmpty: false, message: 'is required'},
      length: {
        maximum: 32
      }
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    let validPhase = (errors == null && formState.values.verificationPhrase === validationText);

    setFormState(formState => ({
      ...formState,
      isValid: validPhase,//errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values, schema, validationText]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog"><Typography variant="h4">{title}</Typography></DialogTitle>
        <DialogContent><>
          {children}
          <div className={classes.spacer} />
          <Typography variant="body1">
            Please type {validationText} to continue
          </Typography>
          <div className={classes.spacer} />
          <form>
            <TextField
              className={classes.textField}
              error={hasError('verificationPhrase')}
              fullWidth
              helperText={
                hasError('verificationPhrase') ? formState.errors.verificationPhrase[0] : null
              }
              // label={validationText}
              placeholder={validationText}
              name="verificationPhrase"
              onChange={handleChange}
              required
              value={formState.values.verificationPhrase || ''}
              variant="outlined"
            />
          </form>
        </></DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setFormState(formState => ({
                  ...formState,
                  values: {},
                  isValid: false,//errors ? false : true,
                  errors: {},
                  touched: {},
                }));
              }, 500);
            }}
            color="primary"
          >
            No
          </Button>
          <Button
            variant="contained"
            disabled={!formState.isValid}
            onClick={() => {
              setOpen(false);
              onConfirm();
              setTimeout(() => {
                setFormState(formState => ({
                  ...formState,
                  values: {},
                  isValid: false,//errors ? false : true,
                  errors: {},
                  touched: {},
                }));
              }, 500)
            }}
            color="default"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmDialogWithTextVerify;
