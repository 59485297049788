import React, { useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Link
} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CircularProgress from '@material-ui/core/CircularProgress';

import { loadReCaptcha } from 'react-recaptcha-v3'
import withWidth  from '@material-ui/core/withWidth';
import {config} from '../../config';
import {DataService} from '../../App';

loadReCaptcha(config.reCaptchaSiteKey, () => {});

// const schema = {
//   password: {
//     presence: {  allowEmpty: false, message: 'is required' },
//     format: {
//       pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/,
//       message: "must contain at least 1 Capital, 1 Number, 1 lowercase and 1 symbol"
//     },
//     length: {
//       minimum: 9
//     }
//   },
//   password_confirm: {
//     // You need to confirm your password
//     presence: true,
//     // and it needs to be equal to the other password
//     equality: {
//       attribute: 'password',
//       message: '^The passwords do not match'
//     }
//   },
//   mfa_verify : {
//     presence: { allowEmpty: false, message: 'is required' },
//   }
// };
//



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    paddingTop: theme.spacing(3)
  },
  expiredLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));



// function getSteps() {
//   return ['SMS Verification', 'Reset Password'];
// }



const AccountJoin = props => {
  const location = props.location;

  const dataProvider = React.useContext(DataService);
  let token = new URLSearchParams(location.search).get('invite');

  const classes = useStyles();

  const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [hasValidatedToken, setHasValidatedToken] = React.useState(false);


  useEffect(() => {
    dataProvider.Account().acceptInviteToJoinAccount(token)
      .then((isValid) => {
        setHasValidatedToken(true);
        setIsTokenValid(isValid);
      }).catch((err) => {
        //set error state,
        setHasValidatedToken(true);
        setIsTokenValid(false);
      });
  }, [dataProvider, token]);


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <div className={classes.content}>
            <Typography
              className={classes.title}
              variant="h2"
            >
              Join Account
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
            >
              Adding your access details
            </Typography>
            {(!hasValidatedToken)? <>
              <div className={classes.expiredLinkContainer} >
                <CircularProgress size={'1rem'}/>
                <Typography
                  style={{paddingLeft:'0.5rem', justifyContent: 'unset'}}
                  className={classes.contentBody}
                  variant='h5'
                >Validating Token</Typography>
              </div>
            </> : <>
              {(isTokenValid)? <>
                <div>
                  <Typography className={classes.instructions}>Account Joined Successfully</Typography>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Click to proceed to dashboard{' '}
                    <Link
                      component={RouterLink}
                      to="/dashboard"
                      variant="h6"
                    >
                      Dashboard
                    </Link>
                  </Typography>
                </div>
              </> : <>
                <div className={classes.expiredLinkContainer} >
                  <NotInterestedIcon />
                  <Typography
                    className={classes.contentBody}
                    style={{paddingLeft:'0.5rem', justifyContent: 'unset'}}
                    variant="h5"
                  >Link has expired or is invalid</Typography>
                </div>
              </>}
            </>}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

AccountJoin.propTypes = {

};



export default withWidth()(withRouter(AccountJoin));
