import React, {useState} from 'react';

import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
  Resizable,
  Legend,
  styler,
} from 'react-timeseries-charts';
import { TimeSeries, TimeRange } from 'pondjs';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Box, CircularProgress} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';

import Divider from '@material-ui/core/Divider';
import chartDataRepo from '../../helpers/chartDataRepo'
import {makeStyles} from '@material-ui/styles';
import parseDateRFC3339 from '../../helpers/parseDateRFC3339';


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: theme.spacing(44),
    padding: theme.spacing(4),
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  }
}));
const WattageGraph = props => {
  const classes = useStyles();

  const setShowLoading = props.setIsLoading;
  const timeZoneOffset = props.timeZoneOffset;
  const graphContainerParentRef   = React.useRef(null);
  // const [chartData, setChartData] = React.useState([]);
  // const [query, setQuery] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [reloadGraphToggle, setReloadGraphToggle] = useState( false);


  // const [dataType, setDataType] = React.useState('watts');

  // const [columnColours, setColumnColours] = React.useState({});
  //
  // const [pendingRequests, setPendingRequests] = React.useState(0);
  //
  // const [lastLoadedChartQuery, setLastLoadedChartQuery] = React.useState({});

  //const [controllerSerialNumbers, setControllerSerialNumbers] = React.useState([]);
  const [series, setSeries] = React.useState({});
  const [maxDataPoint, setMaxDataPoint] = React.useState(0);
  const [tracker, setTracker] = React.useState(null);
  const [timeRange, setTimeRange] = React.useState( new TimeRange(props.from, props.to));
  const createQuery = (dataType, installId, from, to, _controllerSerialNumbers, limit) => {
    limit = parseInt(limit);
    if (limit === 0) {
      limit = 1200;
    }

    return {
      dataType: dataType,
      filters: {
        installId: installId,
        from: from,
        to: to,
        optimiserIds: _controllerSerialNumbers,
      },
      limit: limit
    }
  }

  const createQueryFromContext = (props, datapointsNeeded, from, to) => {
    return createQuery(
      props.dataType,
      props.installid,
      from,
      to,
      props.selectedControllerSerialNumbers,
      datapointsNeeded
    );
  }

  const loadChart = (_query) => {
    return new Promise((resolve) => {
      chartDataRepo.loadCSV(_query, timeZoneOffset)
        .then((response) => {

          console.log('[WattageGraph] - Receiving chart data');
          //todo: there's a bug here that changes the viewpoint incorrectly
          //
          // if (JSON.stringify(chartData.query) !== JSON.stringify(this.createQueryFromContext(this.state, props))){
          //   console.log('[WattageGraph] - Received chart data from old query. Stored as cache but didn\'t update chart');
          //   return;
          // }
          // if (JSON.stringify(response.query) !== JSON.stringify(lastLoadedChartQuery)) {
          //   console.log('[WattageGraph] - Received chart data from old query. Stored as cache but didn\'t update chart');
          //   return;
          // }

          // let lightweightChartData = [];
          //attached props.insights as markers
          //insights
          //console.log(this.props.insights);
          //let controllerSerialNumbers = [];
          //for (let optimiserId in chartData.data) {
          //  if (!Object.prototype.hasOwnProperty.call(chartData.data, optimiserId)) continue;
          //  //if (!chartData.data.hasOwnProperty(optimiserId)) continue;
          //  controllerSerialNumbers[controllerSerialNumbers.length] = optimiserId;
          //}

          // let dataRows = [];
          // for (let controllerSerialNumber in response.data) {
          //   if (!Object.prototype.hasOwnProperty.call(response.data, controllerSerialNumber)) continue;
          //   if (!Array.isArray(response.data[controllerSerialNumber])) continue;
          //
          //   for (let i=0; i < response.data[controllerSerialNumber].length; i++){
          //
          //     dataRows[0] = 'timestamp'
          //     response.data[controllerSerialNumber][i]
          //   }
          // }

          resolve(response.data);
          return;
          // //   let controllerSerialNumbers = Object.keys(response.data);
          //    //console.log('[WattageGraph] - Received chart data from old query. Stored as cache but didn\'t update chart');
          //
          //    //console.log('[ControllerSerialNumbers]' + controllerSerialNumbers.join(','));
          //
          //    //let markers = this.generateAllMarkersFromInsights(controllerSerialNumbers, props.insights);
          //
          //    let seriesFound = {};
          //    //let timeDataPoints = [];
          //    for (let controllerSerialNumber in response.data) {
          //      if (!Object.prototype.hasOwnProperty.call(response.data, controllerSerialNumber)) continue;
          //      //if (!chartData.data.hasOwnProperty(controllerSerialNumber)) continue;
          //      if (!Object.prototype.hasOwnProperty.call(props, 'selectedControllerSerialNumbers')) continue;
          //
          //      let _propsControllerSerialNumbers = props.selectedControllerSerialNumbers;
          //      if (_propsControllerSerialNumbers.includes(controllerSerialNumber)) {
          //        // markers = this.generateMarkersFromInsights(controllerSerialNumber, props.insights);
          //        seriesFound[controllerSerialNumber] = response.data[controllerSerialNumber].map(item => [item.time * 1000, item.value])
          //
          //        // lightweightChartData[lightweightChartData.length] = {
          //        //   type: 'line',
          //        //   //  options: defaultLineOptions,
          //        //   label: controllerSerialNumber,
          //        //   data: response.data[controllerSerialNumber],
          //        //   markers: [],
          //        // };
          //        // for (let i = 0; i < response.data[controllerSerialNumber].length; i++) {
          //        //   timeDataPoints[timeDataPoints.length] = {time: response.data[controllerSerialNumber][i].time, value: 0};
          //        // }
          //      }
          //
          //    }
          //    // if (timeDataPoints.length > 0){ // && markers.length > 0) {
          //    //   lightweightChartData[lightweightChartData.length] = {
          //    //     type: 'line',
          //    //     // options: { ...defaultLineOptions, lineWidth: 0, crosshairMarkerVisible: false,   color: 'rgba(255, 44, 128, 0)', },
          //    //     label: 'markers',
          //    //     data: timeDataPoints,
          //    //     //markers: markers,
          //    //   };
          //    // }
          //
          //    // let pendingRequests = this.state.pendingRequests;
          //
          //    switch (response.cacheResult) {
          //      case chartDataRepo.results.pass:
          //        //pass will continue to load, but passed back the cache data
          //        //so we're expecting another request.
          //        break;
          //      case chartDataRepo.results.miss:
          //      case chartDataRepo.results.hit:
          //      default:
          //        //pendingRequests--;
          //        break;
          //    }
          //
          //
          //    // setChartData(response.data)
          //    // setIsLoaded(response.isLoaded) //must ONLY be set after ChartData is set correctly
          //
          //    //setPendingRequests(pendingRequests);
          //    // this.setState({
          //    //   ...this.state,
          //    //   isLoaded: response.isLoaded,
          //    //   data: lightweightChartData,
          //    //   isTimedOut: false,
          //    //   isLoading: true,
          //    //   pendingRequests: pendingRequests
          //    // });
          //    console.log('[WattageGraph] - Finished Receiving Chart Data');
          //    resolve(seriesFound);
          //    //return response;

        })
        .catch((err) => {

        })

    });

  }

  React.useEffect(() => {
    //wait 1 second to
    // if (Object.prototype.hasOwnProperty.call(window, 'viewPointChangeTimeout')){
    //   clearTimeout(window.viewPointChangeTimeout);
    // }
    // window.viewPointChangeTimeout = setTimeout(() => {
    const timer = setTimeout(() => {

      let bufferPercentage = 0.3;

      let startTimeToFetch = new Date(timeRange.begin());
      let endTimeToFetch = new Date(timeRange.end());
      let durationBuffer = timeRange.duration() * bufferPercentage;
      startTimeToFetch.setMilliseconds( startTimeToFetch.getMilliseconds() - durationBuffer)
      endTimeToFetch.setMilliseconds(endTimeToFetch.getMilliseconds() + durationBuffer);

      // new Date(timeRange.begin().getTime() - );
      // timeRange.end()
      // timeRange.duration()
      let datapointsNeeded = 1200;
      //no-restricted-globals
      if (graphContainerParentRef.current){
        if (typeof graphContainerParentRef.current.state !== 'undefined') {
          datapointsNeeded = Math.ceil(graphContainerParentRef.current.state.width * (1+(bufferPercentage*2)));// parent.innerWidth;
        }
      }
      let _query = createQueryFromContext(props, datapointsNeeded, startTimeToFetch, endTimeToFetch);
      console.debug('new viewpoint change from: '+ _query.filters.from + 'to: '+ _query.filters.to);
      //console.debug('new viewpoint change from: '+ _query.filters.from.toLocaleString("en-AU", {timeZone: "Australia/Brisbane"}) + 'to: '+ _query.filters.to.toLocaleString("en-AU", {timeZone: "Australia/Brisbane"}));
      setShowLoading(true);
      loadChart(_query)
        .then((dataPoints) => {

          let seriesToRender = {};

          // for (let controllerSerialNumber in dataPoints ){
          //   if (!Object.prototype.hasOwnProperty.call(dataPoints, controllerSerialNumber)) {continue;}
          //
          //   seriesToRender[controllerSerialNumber] = new TimeSeries({
          //
          //     name: controllerSerialNumber,
          //     columns: ['time', controllerSerialNumber],
          //     points: dataPoints[controllerSerialNumber]//.map()
          //   });
          //
          // }
          // let points = {};
          // for (let controllerSerialNumber in dataPoints ) {
          //   if (!Object.prototype.hasOwnProperty.call(dataPoints, controllerSerialNumber)) {continue;}
          //   points[]
          // }
          let columns = dataPoints.shift();
          if (typeof columns === 'undefined'){
            return;
          }
          columns[0] = 'time';
          dataPoints = dataPoints.filter(point => {return (typeof point !== 'undefined' && Array.isArray(point) && point.length > 1 && point[0] != null)}).map(point => {
            let timestamp =  point.shift();
            let unixTime = (timestamp == null)? 0 : parseDateRFC3339(timestamp );
            for (let i = 0; i < point.length; i++){
              point[i] = parseFloat(point[i]);
            }
            point.unshift(new Date(unixTime * 1000));
            return point;
          });

          seriesToRender = new TimeSeries({
            name: 'Wattages',
            columns: columns,
            points: dataPoints//.map()
          });
          console.log('changing series...');
          // console.log(md5(JSON.stringify(seriesToRender)));
          //let _controllerSerialNumbers = Object.keys(seriesToRender)
          // if(_controllerSerialNumbers.length < 1) {
          //   let debug = true;
          // }
          setSeries(seriesToRender);
          console.log(columns)
          //setColumns(columns);
          // if (columns.length > 0 ){
          //   console.log(seriesToRender.begin());
          //   console.log(seriesToRender.end());
          // }
          // console.log(_query.filters);
          // console.log(seriesToRender);
          if (columns.length > 0 && timeRange == null) {

            setTimeRange(seriesToRender.range());
          }

          //setChartData(dataPoints);
          setIsLoaded(true);
          setShowLoading(false);
          // }, 5000)
        })
    }, 500);
    return () => clearTimeout(timer);
    //},250);
  },
  // eslint-disable-next-line
      [
    props.dataType, props.selectedControllerSerialNumbers, timeRange, props.installid, props.from, props.to
  ]
  );


  React.useEffect(() => {
    setIsLoaded(false);
    setTimeRange( new TimeRange(props.from, props.to))
    // console.debug('inital viewpoint from: ' + props.from + " to:" + props.to)
  }, [props.from, props.to, reloadGraphToggle]);
  // const currencySeries = new TimeSeries({
  //   name: 'Currency',
  //   columns: ["time", "aud", "euro"],
  //   points: buildPoints()
  // });

  //let maxDataPoint = 0;


  const colours = [ '#E373FF', '#FAB85C', '#796EFA', '#FA593E', '#61BFFA', '#93FA45'];
  const randomColour = () => {
    return colours[ Math.floor((Math.random() * 5) + 1) - 1];
  }
  // let columns = ['time'];
  // let datapoints = {};
  // let columnNumber = {};
  // let currentColumnNumber = 1; // time is 0
  // for (let controllerSerialNumber in chartData ){
  //   if (!Object.prototype.hasOwnProperty.call(chartData, controllerSerialNumber)) {continue;}
  //   columns.push(controllerSerialNumber);
  //   columnNumber[controllerSerialNumber] = currentColumnNumber
  //   currentColumnNumber++;
  // }
  // for (let controllerSerialNumber in chartData ) {
  //   for (let i = 0; i < chartData[controllerSerialNumber].length; i) {
  //     let timestamp = chartData[controllerSerialNumber][i][0];
  //     let datapoint = (Object.prototype.hasOwnProperty.call(datapoints, timestamp))? datapoints[timestamp] : [];
  //     datapoint[columnNumber[controllerSerialNumber]] = chartData[controllerSerialNumber][i][1];
  //     datapoints[timestamp] = datapoint;
  //   }
  // }

  // let series =  new TimeSeries({
  //   name: dataType,
  //   columns: columns,
  //   points: Object.values(datapoints),
  // });
  const getColourForController = (serialNumber) => {
    if (!Object.prototype.hasOwnProperty.call(window, 'columnColours')){
      window.columnColours = {};
    }
    if (!Object.prototype.hasOwnProperty.call(window.columnColours, serialNumber)){

      window.columnColours[serialNumber] = (serialNumber === 'combined_string')? '#424242' : randomColour();
    }
    return  window.columnColours[serialNumber];
  }

  const handleTrackerChanged = (_tracker) => {
    setTracker(_tracker)
  }

  const handleTimeRangeChange = (_timerange) => {

    setTimeRange(_timerange);
  }


  React.useEffect(() => {
    console.log('changing series - start... ');
    if (series instanceof TimeSeries) {

      console.log('changing series - valid... ');
      let _maxDataPoint = 0
      for (let column of series.columns()){
        let maxForColumn = series.max(column, (dataPoint) => dataPoint);
        if (_maxDataPoint < maxForColumn){
          _maxDataPoint = maxForColumn;
        }
      }
      setMaxDataPoint(_maxDataPoint);
      //setControllerSerialNumbers( );

    }

  }, [series]);

  let legend = [];
  let toStyle = [];
  let controllerSerialNumbers = [];
  if (series instanceof TimeSeries) {

    controllerSerialNumbers = series.columns().filter(item => {return item !== 'time'});
    let index = null;
    let trackerEvent = null;
    if (tracker) {
      index = series.bisect(tracker);
      trackerEvent = series.at(index);
    }
    for (let controllerSerialNumber of controllerSerialNumbers) {
      toStyle.push({
        key: controllerSerialNumber, width: 2, color: getColourForController(controllerSerialNumber)
      });
      let currentValue = 0;
      if (tracker && trackerEvent != null) {
        currentValue = trackerEvent.get(controllerSerialNumber);
      }
      legend.push({
        key: controllerSerialNumber, label: controllerSerialNumber, value: currentValue
      });
    }
  }

  const _style = styler(toStyle);
  return (
    <div className={classes.root}>

      {/*{"user selected: " + JSON.stringify(props.selectedControllerSerialNumbers)}*/}

      {(!isLoaded)? <>


        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          //height={'100%'}
          //bgcolor="lightgreen"
          style={{minHeight: '200px'}}
          width={'100%'}
        >
          <CircularProgress />
        </Box></>:
        (controllerSerialNumbers.length < 1)?  <>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            //height={'100%'}
            //bgcolor="lightgreen"
            style={{
              minHeight: '200px',

              flexDirection: 'column',
            }}
            width={'100%'}
          >
            <span  style={{display: 'inline-flex', paddingBottom: '2em'}}>
              <InfoIcon /><Typography style={{marginLeft: '0.5em'}}>No Data Available</Typography>
            </span>
            <div >
              <Divider variant="middle" />
              <Button
                onClick={() => {
                  setReloadGraphToggle(!reloadGraphToggle)
                }}
                variant="contained"
              >
                <RefreshIcon />
                <Typography>Refresh</Typography>
              </Button>
            </div>

          </Box>
        </>:
          <div >
            {/*<Typography>seriesHash: {md5(series)}</Typography>*/}
            {/*<Typography>controllerSerialNumbers:{controllerSerialNumbers.length }</Typography>*/}

            {/*{Object.keys(series).map((controllerSerialNumber) => {*/}
            {/*  return (*/}
            {/*    <Typography>{controllerSerialNumber} length :{series[controllerSerialNumber].size() }</Typography>*/}
            {/*  )})*/}
            {/*}*/}

            <Resizable ref={graphContainerParentRef}>
              <ChartContainer
                enablePanZoom
                onTimeRangeChanged={handleTimeRangeChange}
                onTrackerChanged={handleTrackerChanged}
                //format={"%H:%M:%S%p"}
                showGrid
                timeAxisStyle={{
                  ticks: {
                    stroke: '#AAA',
                    opacity: 0.25,
                    'stroke-dasharray': '1,1'
                    // Note: this isn't in camel case because this is
                    // passed into d3's style
                  },
                  values: {
                    fill: '#AAA',
                    'font-size': 12
                  }
                }}
                timeRange={timeRange}
                width={800}
              >
                <ChartRow height="200">
                  <YAxis
                    format=".2f"
                    id="axis1"
                    label={props.dataType}
                    max={maxDataPoint * 1.2}
                    min={0}
                    type="linear"
                    width="60"
                  />
                  <Charts>
                    <LineChart
                      //interpolation="curveBasis"
                      axis="axis1"
                      columns={series.columns()}
                      // columns={['time','combined_string','OSA10001','OSA10002','OSA10003','OSA100014']}
                      series={series}
                      style={_style}
                      // maxTime={series.timerange().end()}//series.timerange().end()}
                      // minTime={series.timerange().start()}//series.timerange().begin()}
                    />
                    {/*{Object.keys(series).map((controllerSerialNumber) => {*/}
                    {/*  return (*/}
                    {/*    <LineChart*/}
                    {/*      //interpolation="curveBasis"*/}
                    {/*      axis="axis1"*/}
                    {/*      columns={['time', controllerSerialNumber]}*/}
                    {/*      series={series[controllerSerialNumber]}*/}
                    {/*      style={_style}*/}
                    {/*      // maxTime={series.timerange().end()}//series.timerange().end()}*/}
                    {/*      // minTime={series.timerange().start()}//series.timerange().begin()}*/}
                    {/*    />*/}
                    {/*  )*/}
                    {/*}*/}
                    {/*)}*/}
                  </Charts>
                  {/*<YAxis id="axis2" label="NASA_north" min={0.5} max={1.5} width="80" type="linear" format="$,.2f"/>*/}
                </ChartRow>
              </ChartContainer>

            </Resizable>
            <Legend
            //align="right"
              categories={legend}
              className={classes.root}
              style={_style}
              //highlight={this.state.highlight}
              //onHighlightChange={highlight => this.setState({ highlight })}
              //selection={this.state.selection}
              //onSelectionChange={selection => this.setState({ selection })}
              type="line"
            />
          </div> }

    </div>
  );
}

WattageGraph.propTypes = {
  className: PropTypes.string,
};

export default WattageGraph;
