import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  IconButton,
  Button
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Translations from '../../helpers/translations';

import withWidth from '@material-ui/core/withWidth';
import MfaSmsVerify from '../../components/MfaSmsVerify';
import {DataService} from '../../App';
import {SidePlaneGridItem} from '../../layouts/Main/components';


const schema = {
  mfa_verify: {
    presence: { allowEmpty: false, message: 'is required' }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  nameGrid: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mfaWrapper: {
    paddingBottom: theme.spacing(4),
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
}));

const MfaVerify = props => {
  const { history, location } = props;

  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false
    }
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);



  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBack = () => {
    history.goBack();
  };


  const handleMFAVerify = event => {

    event.preventDefault();
    dataProvider.User().loginConfirmMFA(formState.values['mfa_verify'])
      .then( () => {
        let redirect = '/';
        if (location.state != null && location.state.from != null && location.state.from.pathname != null) {
          redirect = location.state.from.pathname;
        }
        history.push(redirect);
      }).catch( (error) => {

        if (error != null){
          let errorText = '';
          errorText = error.message;
          if (errorText === ''){
            errorText = Translations.Errors.ServerUnavailable;
          }
          // incorrect password or user disabled
          let errors = {
            mfa_verify: [errorText]
          };
          setLoadingNetworkRequest(false);
          setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
          }));
        }
      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [reSendAttempts, setReSendAttempts] = React.useState(0);
  const [ableToReSendMFA, setAbleToReSendMFA] = React.useState(true);
  const [showReSentTick, setShowReSentTick] = React.useState(false);
  const [loadingNetworkRequest, setLoadingNetworkRequest] = React.useState(false);

  const MaxSMSRetries = 5;
  const resendMFA = () => {

    //refresh token
    if (reSendAttempts > MaxSMSRetries-1){
      setAbleToReSendMFA(false);
      return;
      //cause error
      //cannot retry
    }

    setLoadingNetworkRequest(true)
    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        forceReset: !formState.reCaptcha.forceReset,
        captchaResetCallback: (reCaptchaToken) => {
          setReSendAttempts(() => reSendAttempts + 1);
          setAbleToReSendMFA(false);
          dataProvider.User().forgotPasswordResendMFA(
            reCaptchaToken,
            '')
            .then(() => {
              setShowReSentTick(true);
              setTimeout(() => {setShowReSentTick(false); }, 5000);
            })
            .catch((error) => {})
            .finally(() => {
              if (reSendAttempts < MaxSMSRetries-1){
                setTimeout(() => {
                  setAbleToReSendMFA(true);
                }, 5000)
              }
              setLoadingNetworkRequest(false)
            });
        }
      }
    }));


  };


  return (
    <div className={classes.root}>


      <Grid
        className={classes.grid}
        container
      >
        <SidePlaneGridItem />
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <form
              className={classes.form}
              onSubmit={handleMFAVerify}
            >
              <div className={classes.mfaWrapper}>
                <MfaSmsVerify
                  errorText={hasError('mfa_verify') ? formState.errors.mfa_verify[0] : null}
                  hasError={hasError('mfa_verify')}
                  isDisabled={!ableToReSendMFA}
                  showSentTick={showReSentTick}
                  resendMFA={resendMFA}
                  onChange={handleChange}
                  value={formState.values.mfa_verify || ''}
                />
              </div>
              <Button
                className={classes.submitButton}
                color="primary"
                disabled={loadingNetworkRequest === true}
                type='submit'
                variant='contained'
              >
                {'Next'}
              </Button>
            </form>

          </div>
        </Grid>
      </Grid>
    </div>
  );
};

MfaVerify.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};



export default withWidth()(withRouter(MfaVerify));
