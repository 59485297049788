import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import { UserApiTokensTable, UserApiTokensToolbar } from './components';


import Query from '../../data/Query';

import {DataService} from "../../App";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ApiAccessList = () => {
  const classes = useStyles();


  //dataProvider DataProvider
  const dataProvider = React.useContext(DataService);

  const [userApiTokens, setUserApiTokens] = useState([]);

  // const [users, setUsers] = useState([]);
  // const [currentUser, setCurrentUser] = useState({});
  // const [currentAccount, setCurrentAccount] = useState({});
  const [query, setQuery] = useState('');
 // const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [rowTotal, setRowTotal] = useState(0);

  useEffect(() => {

    let listQuery = new Query({ filter: {query: query, page: page, page_size: rowsPerPage} });

    dataProvider.UserApiToken().list(listQuery)
      .then((response) => {
        setUserApiTokens(response.data);
        setRowTotal(response.meta.total);
      })
  }, [dataProvider, page, rowsPerPage, query, rowTotal])

  return (
    <div className={classes.root}>
      <UserApiTokensToolbar
        query={query}
        setQuery={(query) => { setPage(0); setQuery(query) }}
      />
      <div className={classes.content}>
        <UserApiTokensTable
          userApiTokens={userApiTokens}
          rowTotal={rowTotal}
          setRowTotal={setRowTotal}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>
  );
};

export default ApiAccessList;
