import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardActions,
  Divider,
  Button,
  Typography,
  List,
  ListItem
} from '@material-ui/core';

import validate from 'validate.js';

import withWidth from '@material-ui/core/withWidth';
import {UserSelector, ConfirmDialogWithPasswordVerify} from '../../../../components';
import {DataService} from '../../../../App';

// loadReCaptcha(config.reCaptchaSiteKey, () => {});




const schema = {
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 128
    }
  },

}


const AccountTransfer = props => {
  const { reCaptchaToken, reCaptchaForceReset, setReCaptchaForceReset } = props;
  const dataProvider = React.useContext(DataService);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmTransferOpen, setConfirmTransferOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [waitingOnNetworkRequest, setWaitingOnNetworkRequest] = useState(false);
  //const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: ''
    },
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false,
      captchaResetCallback: (token) => {}
    }
  });

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // const reCaptchaRef = React.useRef();
  //
  // const verifyReCaptchaCallback = (recaptchaToken) => {
  //   setFormState(formState => ({
  //     ...formState,
  //     reCaptcha: {
  //       ...formState.reCaptcha,
  //       token: recaptchaToken
  //     },
  //   }));
  //   formState.reCaptcha.captchaResetCallback(recaptchaToken)
  // };
  //
  // useEffect(() => {
  //   if (reCaptchaRef.current) {
  //     reCaptchaRef.current.execute();
  //   }
  // }, [ formState.reCaptcha.forceReset]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);


  useEffect(() => {
    const errors = validate(formState.values, schema);

    //if formstate is valid
    if (errors == null && reCaptchaToken !== null && !waitingOnNetworkRequest){
      setWaitingOnNetworkRequest(true);
      dataProvider.Account().transferAccountOwnership(selectedUser.email, formState.values.password, reCaptchaToken)
        .then(() => {
          props.history.push('/');
          window.location.reload();
          //reload page, and redirect to dashboard (or success page)
          //
        }).catch(error => {
          // incorrect password or user disabled
          let errors = {
            password: [error.message]
          };
          setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
          }));
        }).finally(() => {
          setWaitingOnNetworkRequest(false);
        });
    }

    //
    // setFormState(formState => ({
    //   ...formState,
    //   isValid: errors ? false : true,
    //   errors: errors || {}
    // }));
  },
      //[dataProvider, props.history, selectedUser, waitingOnNetworkRequest,
      // eslint-disable-next-line
           [reCaptchaToken, formState.values ]);

  return (
    <>
      <Card>
        <CardHeader
          subheader="Transfer Account ownership to an existing user"
          title="Transfer Account"
        />
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {setConfirmTransferOpen(true)}}
          >
            Transfer Account
          </Button>
          <UserSelector
            title="Transfer Account Ownership"
            open={confirmTransferOpen}
            setOpen={setConfirmTransferOpen}
            onConfirm={() => setConfirmOpen(true) }
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          >
            <Typography
              variant="body1"
            >
              Are you sure you want to transfer account?
            </Typography>
          </UserSelector>
          <ConfirmDialogWithPasswordVerify
            title="Transfer Account Ownership"
            validationText={'Current Password'}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            hasError={hasError('password')}
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            onConfirm={(password) => {
              setFormState(formState => ({
                ...formState,
                values: {
                  ...formState.values,
                  password: password
                },
                touched: {
                  ...formState.touched,
                  password: true
                }
              }));
              //sets password and forces recaptcha to refresh.
              setReCaptchaForceReset(!reCaptchaForceReset);
            }}
          >
            <Typography
              variant="body1"
            >
              This will transfer all administrative functions to this user:
              <List>
                <ListItem>User Management</ListItem>
                <ListItem>Role Management</ListItem>
                <ListItem>Billing and Plan Selection</ListItem>
                <ListItem>Account Backups</ListItem>
              </List>
            </Typography>
            <Typography
              variant="body1"
            >
              This will transfer the account to {(selectedUser !== null)? selectedUser.first_name + ' ' + selectedUser.last_name + '(' + selectedUser.email + ')' : ''} Are you sure?
            </Typography>
          </ConfirmDialogWithPasswordVerify>
        </CardActions>

      </Card>
    </>
  );
};

AccountTransfer.propTypes = {
  className: PropTypes.string
};

export default withWidth()(withRouter(AccountTransfer));
