
export default (date) => {
  if (typeof date !== 'string') {
    return 0;
  }
  // eslint-disable-next-line
  const RFC3339datePattern = /^([0-9]+)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])[Tt]([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60)(\.[0-9]+)?(([Zz])|([\+|\-]([01][0-9]|2[0-3]):[0-5][0-9]))$/;
  // eslint-disable-next-line
  const [RFC3339, year, month, day, hour, minute, second, usingZ, offset] = RFC3339datePattern.exec(date);
  //let offsetOverride = parseInt(offset);
  //js time months start at 0 -> 11, so we need to -1 off to get the right timestmap)
  //  let browserOffset = new Date().getTimezoneOffset() / 60;

  let unixTimestamp = Math.round((new Date(year, month-1, day, hour, minute, second)).getTime() / 1000);
  return unixTimestamp;
  // let unixTimestamp = Math.round((new Date(year, month-1, day, hour, minute, second)).getTime() / 1000);



  //return new Date(unixTimestamp * 1000);
}
