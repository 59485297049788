import React  from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  activeRow: {
    // color: 'red'
  },
  inactiveRow: {
    color:  theme.palette.text.secondary
  }

}));

const AccountInviteTable = props => {
  const StatusUnclaimed = 'unclaimed';
  const { className,
    invites,
    inviteTotal,
    selectedInvites,
    setSelectedInvites,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    revokeAccountInvite,
    ...rest } = props;



  const classes = useStyles();


  // eslint-disable-next-line
  const handleSelectAll = event => {
    const { invites } = props;

    let selectedInvites;

    if (event.target.checked) {
      selectedInvites = invites.map(invite => invite.id);
    } else {
      selectedInvites = [];
    }

    setSelectedInvites(selectedInvites);
  };

  // eslint-disable-next-line
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedInvites.indexOf(id);
    let newSelectedInvites = [];

    if (selectedIndex === -1) {
      newSelectedInvites = newSelectedInvites.concat(selectedInvites, id);
    } else if (selectedIndex === 0) {
      newSelectedInvites = newSelectedInvites.concat(selectedInvites.slice(1));
    } else if (selectedIndex === selectedInvites.length - 1) {
      newSelectedInvites = newSelectedInvites.concat(selectedInvites.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedInvites = newSelectedInvites.concat(
        selectedInvites.slice(0, selectedIndex),
        selectedInvites.slice(selectedIndex + 1)
      );
    }

    setSelectedInvites(newSelectedInvites);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };



  const getTableCellClass = row => {
    return (row.status === StatusUnclaimed)? classes.activeRow : classes.inactiveRow
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow >
                  <TableCell>Email</TableCell>
                  <TableCell>Sent date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Invited By</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/*invites.slice( (page) * rowsPerPage, (page+1) * rowsPerPage)*/}
                {invites.map(invite => (
                  <TableRow
                    hover
                    key={invite.id}
                    selected={selectedInvites.indexOf(invite.id) !== -1}
                  >
                    {/*<TableCell*/}
                    {/*  className={getTableCellClass(invite)}*/}
                    {/*  padding="checkbox">*/}
                    {/*  <Checkbox*/}
                    {/*    checked={selectedInvites.indexOf(invite.id) !== -1}*/}
                    {/*    color="primary"*/}
                    {/*    onChange={event => handleSelectOne(event, invite.id)}*/}
                    {/*    value="true"*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell
                      className={getTableCellClass(invite)}
                    >{invite.email}</TableCell>
                    <TableCell
                      className={getTableCellClass(invite)}
                    >
                      {moment(invite.created_at).format('DD/MM/YYYY h:mm:ss a')}
                    </TableCell>
                    <TableCell
                      className={getTableCellClass(invite)}
                    >{invite.status}</TableCell>
                    <TableCell
                      className={getTableCellClass(invite)}
                    >{invite.created_by_user_id}</TableCell>
                    <TableCell
                      className={getTableCellClass(invite)}
                    >{(invite.status === StatusUnclaimed)? <Link  href="#" onClick={() => { revokeAccountInvite(invite.id); }} >Revoke</Link>: <></> }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={inviteTotal}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

AccountInviteTable.propTypes = {
  className: PropTypes.string
};

export default AccountInviteTable;
