import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button, FormControl, FormHelperText, Input, Paper } from '@material-ui/core';

import validate from 'validate.js';
import {DataService} from '../../../../App';
const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    //padding: theme.spacing(1),
    flexBasis: 420
  },
  spacer: {
    flexGrow: 1
  },
  quoteText: {
    fontWeight: 300,
    paddingRight: theme.spacing(2),
    textDecoration: 'underline'
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  emailInputRoot: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  emailInput: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  inviteButton: {
    marginLeft: theme.spacing(2)
  }
}));


const schema = {
  email: {
    presence: {allowEmpty: false, message: 'is required'},
    email: true,
    length: {
      maximum: 64
    }
  }
}


const AccountInviteToolbar = props => {
  const { className, style, inviteTotal, setInviteTotal, query, setQuery, ...rest } = props;
  const dataProvider = React.useContext(DataService);
  const [currentlySubmittingInvite, setCurrentlySubmittingInvite] = useState(false)
  const [formState, setFormState] = useState({
    isValid: true,
    values: {
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);



  const inviteButton = useRef();


  const classes = useStyles();
  const handleChange = event => {
    event.persist();
    if (event.target == null){
      return
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));

  };


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const sendAccountInvite = event => {
    event.preventDefault();
    if (hasError('email')){
      return;
    }
    if (!currentlySubmittingInvite) {
      setCurrentlySubmittingInvite(true)
      dataProvider.Account().inviteToJoinAccount(inviteButton.current.value)
        .then(() => {
          //show success
          inviteButton.current.value = '';
          setInviteTotal( inviteTotal +1)
        })
        .catch((err) => {
          //show failure
          let emailFieldName = 'email';
          setFormState(formState => ({
            ...formState,
            touched: {
              ...formState.touched,
              [emailFieldName]: true
            },
            errors: {
              ...formState.touched,
              [emailFieldName]: [err.toString()]
            }
          }));
        }).finally(() => {
          setCurrentlySubmittingInvite(false);
        })

    }
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />

      </div>
      <form
        className={classes.row}
        onSubmit={sendAccountInvite}
      >
        <Paper
          className={clsx(classes.emailInputRoot, className)}
          style={style}
        >
          {/*<TextField*/}
          {/*  className={classes.textField}*/}
          {/*  error={hasError('email')}*/}
          {/*  fullWidth*/}
          {/*  helperText={*/}
          {/*    hasError('email') ? formState.errors.email[0] : null*/}
          {/*  }*/}
          {/*  label="Email address"*/}
          {/*  name="email"*/}
          {/*  onChange={handleChange}*/}
          {/*  type="search"*/}
          {/*  value={formState.values.email || ''}*/}
          {/*  variant="standard"*/}
          {/*   disableUnderline*/}
          {/*  // placeholder={'Email Address'}*/}
          {/*/>*/}
          <FormControl
            // className={classes.textField}
            className={classes.textField}
            error={hasError('email')}
            fullWidth
            variant="outlined"
          >
            <Input

              {...rest}
              fullWidth
              name="email"
              inputRef={inviteButton}
              className={classes.emailInput}
              disableUnderline
              placeholder={'Email Address'}
              onChange={handleChange}
            />
            <FormHelperText id="input-email-helper-text">{hasError('email') ? formState.errors.email[0] : null}</FormHelperText>
          </FormControl>

        </Paper>
        <Button
          color="primary"
          variant="contained"
          disabled={currentlySubmittingInvite}
          className={classes.inviteButton}
          type="submit"
        >
          Invite user
        </Button>
      </form>
    </div>
  );
};

AccountInviteToolbar.propTypes = {
  className: PropTypes.string
};

export default AccountInviteToolbar;
