import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button, Divider, Drawer, List, ListItem} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 480,//240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    },
    position: 'absolute',
  },
  floatingDrawer: {
    position: 'relative', marginRight: 'auto'
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

// const CustomRouterLink = forwardRef((props, ref) => (
//   <div
//     ref={ref}
//     // style={{ flexGrow: 1 }}
//   >
//     <RouterLink {...props} />
//   </div>
// ));



const GroupsSidebar = props => {
  const { className, query, setQuery, ...rest } = props;

  const classes = useStyles();
  // const handleOnChange = event => {
  //   event.preventDefault();
  //   setQuery(event.target.value);
  // };

  return (
    <div
      style={{ position: 'relative' }}
    >
      <Drawer
        anchor="left"
        className={classes.floatingDrawer}
        //style={{ position: 'relative', marginRight: 'auto' }}
        classes={{ paper: classes.drawer }}
        // onClose={onClose}
        open={true}
        variant={'persistent'}
      >
        Test
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          <List
            {...rest}
            className={clsx(classes.root, className)}
          >
            <ListItem
              className={classes.item}
              disableGutters
              // key={page.title}
            >
              <Button
                activeClassName={classes.active}
                className={classes.button}
                // component={CustomRouterLink}
                // to={page.href}
              >
                Test
                {/*<div className={classes.icon}>{page.icon}</div>*/}
                {/*{page.title}*/}
              </Button>
            </ListItem>
            {/*{pages.map(page => (*/}
            {/*  <MenuItem key={page.href}  page={page} />*/}

            {/*))}*/}
          </List>
          <Divider className={classes.divider} />
        </div>
      </Drawer>
    </div>
  );
};

GroupsSidebar.propTypes = {
  className: PropTypes.string
};

export default GroupsSidebar;
