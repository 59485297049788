import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';


const useStyles = makeStyles((theme) => ({
  "@keyframes myEffectExit": {
    "0%": {
      fill: theme.palette.primary,
    },
    "50%": {
      fill: "#000",
    },
    "100%": {
      fill: theme.palette.primary,
    },
  },

  largeIcon: {

    '& svg': {
      fontSize: 100,
      animation:  `$myEffectExit 3s infinite`,
    }
  }
}));
const GraphSkeletonLoader = (props) => {
  let classes = useStyles();

  return (
    <IconButton className={classes.largeIcon}>
      <TimelineIcon/>
    </IconButton>
  );


};

export default GraphSkeletonLoader
