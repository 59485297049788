import React from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {},
}));

const ConfirmDialog = (props) => {
  const { className, title, children, open, setOpen, onConfirm, disableNoButton, ...rest  } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Dialog
        {...rest}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog"><Typography variant="h4">{title}</Typography></DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {(!disableNoButton)? <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="primary"
          >
            No
          </Button>: <></>}
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color={(!disableNoButton)? "default": "primary"}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmDialog;
