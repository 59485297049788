import React, {Suspense, lazy, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  ControllerSelector,
  DeviceEventLog,
} from './components';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Grid,
  Fade,
  CircularProgress,
  Typography
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Popover from '@material-ui/core/Popover';

import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateRangePicker } from 'material-react-daterange-picker';

import {DataService} from '../../App';
//import Query from '../../data/Query'
import LinearProgress from '@material-ui/core/LinearProgress';
import chartDataRepo from "../../helpers/chartDataRepo";


const WattageGraph = lazy(() => import('../../components/WattageGraph') );

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  wattagegraph: {
    order: 10,
  },
  controllerSelector: {
    order: 20,
    marginTop: 'auto',
    [theme.breakpoints.up('lg')]: {
      order: 30,
      marginTop: -theme.spacing(65)
    },
    // [theme.breakpoints.up('md')]: {
    //   order: 30,
    // }
  },
  insightGrid: {
    order: 30,
    [theme.breakpoints.up('lg')]: {
      order: 20,
    },
  },

}));



const SolarStringDetail = (props) => {
  const classes = useStyles();

  const dataProvider = React.useContext(DataService);
  //const [isPermitted, setIsPermitted] = useState(false);
  const [dataTypeOpen, setDataTypeOpen] = useState(false);
  //const [pendingRequests, setPendingRequests] = useState(0);
  const pendingRequests = 0;
  const [showTopLoading, setShowTopLoading] = useState(false);
  const [dataTypePopoverTarget, setDataTypePopoverTarget] = useState(null);
  const [dataType, setDataType] = useState('watts');

  const [timeZone, setTimeZone] = useState({name:'UTC+10', offset:10});
  let installId = props.match.params.id;
  let view = {};
  try {
    view = JSON.parse(atob(props.match.params.view));
  } catch (e) {  }

  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    setFinishedLoading(true)
  }, [])

  useEffect(() => {
    dataProvider.CommsUnit().isPermitted(installId)
      .then(isPermitted => {
        if (!isPermitted){
          //this is a nice way to redirect users.
          //data won't be available without this function being called,
          //but otherwise would still display the same page with no data in it.
          props.history.push('/not-found');
        }
        //setIsPermitted(isPermitted);
      })
      .catch(err =>{
        console.error(err);
      });

  },
  // eslint-disable-next-line
      [dataProvider, installId]
  );

  const updateUrlParams = (installId, startDate, endDate, selectedControllers) => {
    //updateUrlParams(installId, startDate, endDate, selectedControllers);
    let newUrl ='/solar-string/detail/id/' + installId + '/' + btoa(JSON.stringify({
      from: Math.floor(startDate.getTime() / 1000),
      to: Math.floor(endDate.getTime() / 1000),
      controllerIds: selectedControllers
    }));
    props.history.push(newUrl);
  }




  const [totalKwhr, setTotalKwhr] = useState(0);
  const [selectedTotalKwhr, setSelectedTotalKwhr] = useState(0);

  const [open, setOpen] = useState(false);
  const [popOverAnchorElement, setPopOverAnchorElement] = useState(null);

  let initialSelectedControllers = ['combined_string'];
  if (Object.prototype.hasOwnProperty.call(view, 'controllerIds') && Array.isArray(view.controllerIds) && view.controllerIds.length > 0 ){
    initialSelectedControllers = view.controllerIds;
  }
  const [selectedControllers, setSelectedControllers] = useState(initialSelectedControllers);



  let initialEndDate = new Date();
  if (Object.prototype.hasOwnProperty.call(view, 'to') && Number.isInteger(view.to) && view.to > 0 ){
    initialEndDate = new Date(view.to * 1000)
  }
  const [endDate, setEndDate] = useState(initialEndDate);

  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  let initialStartDate = new Date(endDate.getTime() - 86400000)
  if (Object.prototype.hasOwnProperty.call(view, 'from') && Number.isInteger(view.from) && view.from > 0 ){
    initialStartDate = new Date(view.from * 1000)
  }
  const [startDate, setStartDate] = useState(initialStartDate); // -1 day in ms , so we get today's data

  let isSameDate = (
    startDate.getFullYear() === selectedEndDate.getFullYear() &&
      startDate.getMonth() === selectedEndDate.getMonth() &&
      startDate.getDate() === selectedEndDate.getDate()
  );
  let selectedDateText = (isSameDate)?  startDate.toDateString() : startDate.toDateString() + ' - ' + selectedEndDate.toDateString() ;

  //todo: add location
  useEffect(() => {
    dataProvider.CommsUnit()
      .total(installId, startDate, endDate, ['combined_string'])
      .then(total => {
        setTotalKwhr(total.toFixed(2))
      }).catch(err => {
        console.log(err);
      });

    dataProvider.CommsUnit().timezone(installId, endDate)
      .then(timeZone => {
        setTimeZone(timeZone);
      }).catch(err => {
        console.log(err);
      });
    // dataProvider.CommsUnit()
    //   .list(new Query({filter: {query:installId}}))
    //   .then(response => {
    //     if (response.data.length > 0) {
    //       for (let commsUnit of response.data){
    //         if (commsUnit.install_id === installId){
    //           setTimeZone(response.data[0].time_zone)
    //           break;
    //         }
    //       }
    //     }
    //     else {
    //       console.error('Cannot find commsunit with install id: ' + installId);
    //     }
    //   }).catch(err => {
    //     console.error(err);
    //   });

  }, [dataProvider, startDate, endDate, installId]);


  useEffect(() => {

    dataProvider.CommsUnit()
      .total(installId, startDate, endDate,  selectedControllers.join(','))
      .then(total => {
        setSelectedTotalKwhr(total.toFixed(2))
      }).catch(err => {
        console.log(err);
      });

  }, [dataProvider, startDate, endDate, selectedControllers, installId]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTotalKwhrText( totalKwhrText => parseFloat(Number(totalKwhrText + Math.random()*0.3).toFixed(2)) );
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  const startDownloadChartData = () => {
    let query = {
      filters: {
        from: startDate,
        to: endDate,
      }
      // ...createQueryFromContext(this.state, this.props)
    };
    let newFromTime = new Date(
        query.filters.from.getFullYear() , //-20% of the diff rounded.
        query.filters.from.getMonth(),
        query.filters.from.getDate(),
        0,
        0,
        0,
        0
    );
    let newToTime = new Date(
        query.filters.to.getFullYear(),
        query.filters.to.getMonth(),
        query.filters.to.getDate(),
        23,
        59,
        59,
        0
    );

    query.dataType = dataType;
    query.filters.optimiserIds = null;
    query.filters.from = newFromTime;
    query.filters.to = newToTime;
    query.filters.installId = installId;
    query.limit = null;
    query.mode = chartDataRepo.mode.download;
    chartDataRepo.loadCSV(query, timeZone.offset);
  }

  function handleControllerSelectionChange(newOptimisers) {
    setSelectedControllers(newOptimisers);
    updateUrlParams(installId, startDate, endDate, newOptimisers);

  }
  const onChangeDataType = (dataType) => {
    setDataType(dataType)
    setDataTypeOpen(false);
  }
  const handleDataTypeListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setDataTypeOpen(false);
    }
  }

  const handleDataTypeOpenClick = (event) => {
    event.preventDefault();
    setDataTypeOpen(!dataTypeOpen);
    setDataTypePopoverTarget(event.currentTarget);
  }

  let selectedControllersOnly = selectedControllers.filter((optimiserId) => { return optimiserId !== 'combined_string' && optimiserId !== 'median_controller' })

  //attempt to verify installId, and return 404 if not found
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        spacing={4}
      >
        <Grid
          item
          lg={2}
          xs={12}
        >
          <Typography variant="h1">
            {installId}
          </Typography>
          <Typography
            className={classes.locationText}
            color="textSecondary"
            variant="body1"
          >
            {timeZone.name}
          </Typography>
          {/*<Typography*/}
          {/*  className={classes.locationText}*/}
          {/*  color="textSecondary"*/}
          {/*  variant="body1"*/}
          {/*>*/}
          {/*  Local Time: {moment().format('hh:mm A')} (+10 UTC)*/}
          {/*</Typography>*/}
        </Grid>
        <Grid
          item
          lg={2}
          xs={12}
        >
          <Typography
            color="inherit"
            variant="h3"
          >
            {totalKwhr} kWhr
          </Typography>
          <Typography
            className={classes.title}
            color="inherit"
            gutterBottom
            variant="body2"
          >
              Total Energy
          </Typography>
        </Grid>
        {selectedControllersOnly.length > 0 ?
          <Grid
            item
            lg={2}
            xs={12}
          >
            <Typography
              color="inherit"
              variant="h3"
            >
              {selectedTotalKwhr} kWhr
            </Typography>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              {selectedControllersOnly.length} controllers/s selected
            </Typography>
          </Grid>
          : <></>
        }
        <Grid
          item
          lg={3}
          style={{ flex: 1 }}
          xs={12}
        />
        <Grid
          item
          lg={3}
          style={{textAlign: 'right'}}
          xs={12}
        >
          <div
            style={{
              position: 'relative',
              right: '0%',
            }}
          >
            <Typography
              color="inherit"
              display="inline"
            >{selectedDateText}</Typography>
            <IconButton
              aria-label="set-date-range"
              onClick={(event) => {
                setPopOverAnchorElement(event.currentTarget);
                setOpen(!open);
              }}
              title="Set Date Range"
            >
              <DateRangeIcon
                name="Calandar"
                size={1}
              />
            </IconButton>
          </div>

          <Popover
            anchorEl={popOverAnchorElement}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onBackdropClick={(event) => {
              setPopOverAnchorElement(event.currentTarget);
              setOpen(!open);
            }}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <DateRangePicker
              initialDateRange={{startDate: startDate, endDate: selectedEndDate}}
              onChange={range => {
                let endDate = new Date(range.endDate);
                //below we're aiming for all the data within the day, minus 1 second
                //this is so we don't go to the next day in the query, which would cause
                //cache misses and more server bandwidth
                endDate.setSeconds(endDate.getSeconds() + ((24 * 60 * 60) -1));
                //endDate.setDate(endDate.getDate() + 1)
                setStartDate(range.startDate);
                setSelectedEndDate(range.endDate);
                setEndDate(endDate);
                updateUrlParams(installId, range.startDate, endDate, selectedControllers);

              }}
              open
              style={{position: 'absolute', zIndex:1}}
            />
          </Popover>

        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
        >
          <Grid
            container
            justify="space-between"
            spacing={4}
          >
            <Grid
              className={classes.wattagegraph}
              item
              lg={10}
              xs={12}
            >

              <Suspense fallback={<Typography variant="h3">Loading...</Typography>}>
                <Card  >
                  {(showTopLoading)? <LinearProgress color="primary" />: <></>}
                  <CardHeader
                    action={
                      <>
                        <Fade
                          in={(pendingRequests > 0)}
                          style={{
                            transitionDelay: (pendingRequests > 0) ? '800ms' : '0ms',
                          }}
                          unmountOnExit
                        >
                          <span>
                            <CircularProgress
                              className={classes.spinner}
                              size={15}
                              thickness={2}
                            />
                          </span>
                        </Fade>

                        <Button
                          onClick={handleDataTypeOpenClick}
                          size="small"
                          variant="text"
                        >
                          {dataType} <ArrowDropDownIcon />
                        </Button>
                        <Popover
                          anchorEl={dataTypePopoverTarget}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          open={dataTypeOpen}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <ClickAwayListener onClickAway={(() => {setDataTypeOpen(false)})}>
                            <MenuList
                              autoFocusItem={dataTypeOpen}
                              id="menu-list-grow"
                              onKeyDown={handleDataTypeListKeyDown}
                            >

                              <MenuItem
                                onClick={() => { onChangeDataType('watts') }}
                              >Watts</MenuItem>
                              <MenuItem
                                onClick={() => { onChangeDataType('watt_hours') }}
                              >WattHours</MenuItem>
                              <MenuItem
                                onClick={() => { onChangeDataType('volts') }}
                              >Volts</MenuItem>
                              <MenuItem
                                onClick={() => { onChangeDataType('amps') }}
                              >Amps</MenuItem>
                              <MenuItem
                                onClick={() => { onChangeDataType('temperature') }}
                              >Temperature</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Popover>

                      </>

                    }
                    title=""
                  />
                  <CardContent>
                    <WattageGraph
                      className={classes.wattageGraphInner}
                      dataType={dataType}
                      from={startDate}
                      height={400}
                      installid={installId}
                      key={installId}
                      selectedControllerSerialNumbers={selectedControllers}
                      setIsLoading={setShowTopLoading}
                      timeZoneOffset={timeZone.offset}
                      to={endDate}
                    />
                  </CardContent>
                  <Divider />
                  <CardActions className={classes.actions}>
                    <Button
                      color="primary"
                      onClick={startDownloadChartData}
                      size="small"
                      variant="text"
                    >
                      <Typography >Download </Typography><CloudDownloadIcon />
                    </Button>
                  </CardActions>
                </Card>

              </Suspense>

            </Grid>
            <Grid
              className={classes.controllerSelector}
              item
              lg={2}
              xs={12}
            >
              <Suspense fallback={<Typography variant="h3">Loading...</Typography>}>
                <ControllerSelector
                  from={startDate}
                  handleControllerSelectionChange={handleControllerSelectionChange}
                  installid={installId}
                  key={installId}
                  selectedControllers={selectedControllers}
                  to={endDate}
                />
              </Suspense>


            </Grid>
            {/*<Grid*/}
            {/*  className={classes.insightGrid}*/}
            {/*  item*/}
            {/*  lg={10}*/}
            {/*  xs={12}*/}
            {/*>*/}
            {/*  <LatestInsights*/}
            {/*    from={startDate}*/}
            {/*    installId={installId}*/}
            {/*    to={endDate}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid
              className={classes.insightGrid}
              item
              lg={10}
              xs={12}
            >
              <DeviceEventLog
                from={startDate}
                installId={installId}
                selectedControllers={selectedControllers}
                to={endDate}
              />
            </Grid>
          </Grid>
        </Grid>
        {/*DateRangePicker style bug workaround */}
        <div hidden>
          <DateRangePicker open={!finishedLoading} />
        </div>
      </Grid>
    </div>
  );
};

export default SolarStringDetail;
