import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Translations from '../../helpers/translations';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import withWidth from '@material-ui/core/withWidth';
import {config} from '../../config';
import {DataService} from '../../App';
import {SidePlaneGridItem} from '../../layouts/Main/components';

loadReCaptcha(config.reCaptchaSiteKey, () => {});

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true

  },
  mobile_number_fragment : {
    presence: {  allowEmpty: false, message: 'is required' },
    length: {
      maximum: 4
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    }
  },
  mfa_verify : {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  nameGrid: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    minHeight: 436,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  }
}));


function getSteps() {
  return ['Enter Email Address', 'Confirm your account'];//, 'Verification'];
}




const ForgotPassword = props => {
  const { history } = props;

  const dataProvider = React.useContext(DataService);

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false,
      captchaResetCallback: (token) => {}
    }
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);



  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBack = () => {
    history.goBack();
  };


  const [loadingNetworkRequest, setLoadingNetworkRequest] = React.useState(false);


  const handleForgotPasswordSubmit = event => {
    event.preventDefault();
    //handlePasswordReset();
  };

  const [activeStep, setActiveStep] = React.useState(0);


  const steps = getSteps();

  const getStepContent = (step) => {

    switch (step) {
      case 0:
        return <>
          <TextField
            className={classes.textField}
            error={hasError('email')}
            fullWidth
            helperText={
              hasError('email') ? formState.errors.email[0] : null
            }
            label="Email"
            name="email"
            onChange={handleChange}
            type="text"
            value={formState.values.email || ''}
            variant="outlined"
          />
        </>;
      case 1:
        return <>
          <Typography className={classes.instruction}>
            Enter in the last for digits of the phone number attached to the account.
            Example:
            +1-541-754-xxxx
          </Typography>
          <TextField
            className={classes.textField}
            error={hasError('mobile_number_fragment')}
            fullWidth
            helperText={
              hasError('mobile_number_fragment') ? formState.errors.mobile_number_fragment[0] : null
            }
            label="Last 4 Digits - Account Mobile Number"
            name="mobile_number_fragment"
            onChange={handleChange}
            type="text"
            value={formState.values.mobile_number_fragment || ''}
            variant="outlined"
          />
          {/*<Button type='button' className={classes.submitButton}>I no longer have access to this phone</Button>*/}
        </>;
      // case 2:
      //   return <>
      //     <Typography>
      //       To Confirm your identity, please enter in the code that was sent to you.
      //     </Typography>
      //     <TextField
      //       className={classes.textField}
      //       error={hasError('mfa_verify')}
      //       fullWidth
      //       helperText={
      //         hasError('mfa_verify') ? formState.errors.mfa_verify[0] : null
      //       }
      //       label="Verification Number"
      //       name="mfa_verify"
      //       onChange={handleChange}
      //       type="text"
      //       value={formState.values.mfa_verify || ''}
      //       variant="outlined"
      //     />
      //     <Button type='button' className={classes.submitButton}>Send Again</Button>
      //   </>;
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  const startForgotPassword = (onComplete) => {
    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        forceReset: !formState.reCaptcha.forceReset,
        captchaResetCallback: (reCaptchaToken) => {
          dataProvider.User().forgotPassword(
            reCaptchaToken,
            formState.values['email'],
            formState.values['mobile_number_fragment'])
            .then(() => {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              if (onComplete != null){
                onComplete();
              }
            })
            .catch((error) => {
              let errorText = '';
              if (error != null){

                if (errorText === ''){
                  errorText = Translations.Errors.ServerUnavailable;
                }
                // incorrect password or user disabled
                let errors = {
                  password: [errorText]
                };
                setLoadingNetworkRequest(false);
                setFormState(formState => ({
                  ...formState,
                  isValid: errors ? false : true,
                  errors: errors || {},
                  reCaptcha: {
                    ...formState.reCaptcha,
                    forceReset: !formState.reCaptcha.forceReset
                  },
                }));

              }

            });
        }
      }
    }));

  }

  const handleNextStep = (event, onComplete) => {

    validateStep(activeStep)
      .then( () => {
        switch(activeStep){
          case 1:
            setLoadingNetworkRequest(true);
            startForgotPassword(() => {
              setLoadingNetworkRequest(false);
              if (typeof onComplete === 'function') {
                onComplete();
              }
            });
            break;
          default:
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setLoadingNetworkRequest(false);
            if (typeof onComplete === 'function') {
              onComplete();
            }
            break;
        }
      })
      .catch(() => {
        // show validation errors
      });

  };

  // const handleBackStep = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  //
  // const handleResetSteps = () => {
  //   setActiveStep(0);
  // };
  //


  const validateStep = (step) => {
    return new Promise((resolve, reject) => {
      let schemaStub = {};
      switch (step) {
        case 0:
          schemaStub.email = schema.email;
          break;
        case 1:
          schemaStub.mobile_fragment  = schema.mobile_fragment;
          break;
        case 2:
          schemaStub.mfa_verify  = schema.mfa_verify;
          break;
        default:
          break;
      }
      const errors = validate(formState.values, schemaStub);

      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {},
        reCaptcha: {
          ...formState.reCaptcha,
          forceReset: !formState.reCaptcha.forceReset
        }
      }));

      if (errors) {
        reject(errors);
        return;
      }
      resolve();
      // //otherwise continue to validate step
      // let params = {};
      // let queryString = '';
      // let validationUrl = '';
      //
      // switch (step) {
      //   default:
      //
      // }
    });

  };

  const reCaptchaRef = React.useRef();

  const verifyReCaptchaCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    //console.log(recaptchaToken, '<= your recaptcha token');
    setFormState(formState => ({
      ...formState,
      reCaptcha: {
        ...formState.reCaptcha,
        token: recaptchaToken
      },
    }));
    formState.reCaptcha.captchaResetCallback(recaptchaToken)


  };


  useEffect(() => {
    if (reCaptchaRef.current) {
      reCaptchaRef.current.execute();
    }
  }, [ formState.reCaptcha.forceReset]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>

      <Grid
        className={classes.grid}
        container
      >
        <SidePlaneGridItem />
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>

            <div className={classes.contentBody}>

              <form
                className={classes.form}
                onSubmit={handleForgotPasswordSubmit}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Forgot Password
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Let's reset your password
                </Typography>

                {activeStep === steps.length ? (
                  <div>
                    <MailOutlineIcon />
                    <Typography variant='h5' className={classes.instructions}>
                      Check your Email
                    </Typography>
                    <Typography className={classes.instructions}>
                      We've sent through an email with a link that can reset your password. Please check your junk mail to ensure the link isn't missed.
                    </Typography>
                    {/*<Button onClick={handleResetSteps} className={classes.submitButton}>*/}
                    {/*  Reset*/}
                    {/*</Button>*/}
                  </div>
                ) : (
                  <div>
                    {getStepContent(activeStep)}
                    <div>

                      {/*{isStepOptional(activeStep) && (*/}
                      {/*  <Button*/}
                      {/*    variant="contained"*/}
                      {/*    color="primary"*/}
                      {/*    onClick={handleSkip}*/}
                      {/*    className={classes.button}*/}
                      {/*  >*/}
                      {/*    Skip*/}
                      {/*  </Button>*/}
                      {/*)}*/}
                      {/*
                                              <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNextStep}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                        */}
                      <Button
                        className={classes.submitButton}
                        color="primary"
                        fullWidth
                        disabled={loadingNetworkRequest === true}
                        onClick={handleNextStep}
                        type='button'
                        variant='contained'
                      >Next</Button>
                      {loadingNetworkRequest === true ? (<CircularProgress color="secondary" />) : (<></>) }

                    </div>
                  </div>
                )}
                <ReCaptcha
                  ref={ reCaptchaRef }
                  sitekey={config.reCaptchaSiteKey}
                  action='user_forgotpassword'
                  verifyCallback={verifyReCaptchaCallback}
                />

              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object
};



export default withWidth()(withRouter(ForgotPassword));
