import React, {useEffect, useState} from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, TextField
} from '@material-ui/core';

import {
  Alert
} from '@material-ui/lab'

import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import validate from 'validate.js';

import Translations from '../../helpers/translations';

const useStyles = makeStyles(theme => ({
  root: {},
  spacer: {
    paddingTop: theme.spacing(1)
  }
}));

const schema = {
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      minimum: 9,
      maximum: 128
    }
  }
};

const ConfirmDialogWithPasswordVerify = (props) => {
  const { className, title, validationText, children, hasError, helperText, open, setOpen, onConfirm, ...rest  } = props;
  const classes = useStyles();
  const [showCapsLockWarning, setShowCapsLockWarning] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    enableParentErrors: true,
    values: {
      password: ''
    },
    touched: {},
    errors: {},
    reCaptcha: {
      token: '',
      forceReset: false
    }
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      enableParentErrors: true
    }));
  }, [hasError, helperText]);



  useEffect(() => {
    const errors = validate(formState.values, schema);
    // let validPhase = (errors == null && formState.values.verificationPhrase === validationText);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);
  const _hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      enableParentErrors: false,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  let errorText =  (_hasError('password')) ? formState.errors.password[0] : null;
  if (formState.enableParentErrors && (errorText === null || errorText === '' )){
    errorText = helperText;
  }

  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  const onKeyDown = keyEvent => {
    if (keyEvent.getModifierState('CapsLock')) {
      setShowCapsLockWarning(true);
    } else {
      setShowCapsLockWarning(false)
    }
  };

  const handleDialogSubmit = event => {
    event.preventDefault();
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog"><Typography variant="h4">{title}</Typography></DialogTitle>
        <DialogContent><>
          {children}
          <div className={classes.spacer} />
          <Typography variant="body1">
            Please enter your password to continue
          </Typography>
          <div className={classes.spacer} />
          <form
            onSubmit={handleDialogSubmit}
          >
            <TextField
              className={classes.textField}
              error={ _hasError('password') || (formState.enableParentErrors && hasError)}
              fullWidth
              helperText={
                errorText
              }
              // label={validationText}
              placeholder={validationText}
              name="password"
              type="password"
              onKeyDown={onKeyDown}
              onChange={handleChange}
              required
              value={formState.values.password || ''}
              variant="outlined"
            />
            { (showCapsLockWarning === true) ?
              <Alert
                severity="warning"
                style={{ marginTop: '1rem' }}
              >{Translations.Warnings.CapsLockOn}</Alert>
              : <></>
            }

          </form>
        </></DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setFormState(formState => ({
                  ...formState,
                  values: {},
                  isValid: false,//errors ? false : true,
                  errors: {},
                  touched: {},
                }));
              }, 500);
            }}
            color="primary"
          >
            No
          </Button>
          <Button
            variant="contained"
            disabled={!formState.isValid}
            onClick={() => {
              onConfirm(formState.values.password || '');
            }}
            color="default"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmDialogWithPasswordVerify;
