import React from 'react';
import { Switch, Redirect } from 'react-router-dom';


import { RouteWithLayout, ProtectedRouteWithLayout } from './components';

import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  SolarStringList as SolarStringListView,
  SolarStringDetail as SolarStringDetailView,
  SolarStringClaim as SolarStringClaimView,
  InsightList as InsightListView,
  InsightDetail  as InsightDetailView,
  ReportList as ReportListView,
  ReportCleaning as ReportCleaningView,
  ProductList as ProductListView,
  UserList as UserListView,
  ApiAccessList as ApiAccessListView,
  ApiAccessEdit as ApiAccessEditView,
  Account as AccountView,
  AccountJoin as AccountJoinView,
  AccountInviteList as AccountInviteListView,
  Settings as SettingsView,
  SignUpBegin as SignUpBeginView,
  SignUpVerify as SignUpVerifyView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  Login as LoginView,
  MfaSetupMobile as MfaSetupMobilView,
  MfaVerify as MfaVerifyView,
  NotFound as NotFoundView
} from './views';



const Routes = () => {
  return (
    <Switch>

      <ProtectedRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />
      <ProtectedRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <ProtectedRouteWithLayout
        component={SolarStringListView}
        exact
        layout={MainLayout}
        path="/solar-string/list"
      />
      <ProtectedRouteWithLayout
        component={SolarStringDetailView}
        exact
        layout={MainLayout}
        path="/solar-string/detail/id/:id/:view?"
      />
      <ProtectedRouteWithLayout
        component={SolarStringClaimView}
        exact
        layout={MainLayout}
        path="/solar-string/claim"
      />
      <ProtectedRouteWithLayout
        component={InsightDetailView}
        exact
        layout={MainLayout}
        path="/insight/detail/id/:id"
      />
      <ProtectedRouteWithLayout
        component={InsightListView}
        exact
        layout={MainLayout}
        path="/insight/list"
      />
      <ProtectedRouteWithLayout
        component={ReportCleaningView}
        exact
        layout={MainLayout}
        path="/report/cleaning/list"
      />
      <ProtectedRouteWithLayout
        component={ReportListView}
        exact
        layout={MainLayout}
        path="/report/cleaning/detail/id/:id"
      />
      <ProtectedRouteWithLayout
        component={ReportListView}
        exact
        layout={MainLayout}
        path="/report/maintenance/list"
      />
      <ProtectedRouteWithLayout
        component={ReportListView}
        exact
        layout={MainLayout}
        path="/report/maintenance/detail/id/:id"
      />
      <ProtectedRouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <ProtectedRouteWithLayout
        component={ApiAccessListView}
        exact
        layout={MainLayout}
        path="/api-access/list"
      />
      <ProtectedRouteWithLayout
        component={ApiAccessEditView}
        exact
        layout={MainLayout}
        path="/api-access/new"
      />
      <ProtectedRouteWithLayout
        component={ApiAccessEditView}
        exact
        layout={MainLayout}
        path="/api-access/edit/id/:id"
      />
      <ProtectedRouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <ProtectedRouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <ProtectedRouteWithLayout
        component={AccountInviteListView}
        exact
        layout={MainLayout}
        path="/account/invites"
      />
      <ProtectedRouteWithLayout
        component={AccountJoinView}
        exact
        layout={MainLayout}
        path="/account/join/:invite?"
      />
      <ProtectedRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpBeginView}
        exact
        layout={MinimalLayout}
        path="/sign-up/begin/(invite)?/:invite?"
      />
      <RouteWithLayout
        component={SignUpVerifyView}
        exact
        layout={MinimalLayout}
        path="/sign-up/verify"
      />
      <RouteWithLayout
        component={ForgotPasswordView}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={MinimalLayout}
        path="/reset-password"
      />
      <ProtectedRouteWithLayout
        component={MfaSetupMobilView}
        exact
        layout={MinimalLayout}
        path="/login/setup-mobile-2fa"
      />
      <ProtectedRouteWithLayout
        component={MfaVerifyView}
        exact
        layout={MinimalLayout}
        path="/login/verify"
      />
      <ProtectedRouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
