
//import { Component } from 'react';
//import './index.css';
import React   from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Fade,
  CircularProgress,
  Typography
} from '@material-ui/core';


import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from "@material-ui/core/Popover";
import LightWeightChart from "../LightWeightChart";

import moment from 'moment';
import chartDataRepo from '../../helpers/chartDataRepo'


const styles = theme => ({
  root: {},
  chartContainer: {
 //   height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  spinner: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 400 : 900],
  },
  chartContents: {
    // minHeight: "auto",
    // maxWidth: "100px",
    // [theme.breakpoints.up('lg')]: {
    //   minHeight: '400px',
    //   maxWidth: '100px',
    // },
  }

});

//
// const useContainerDimensions = myRef => {
//   const getDimensions = () => ({
//     width: myRef.offsetWidth,
//     height: myRef.offsetHeight
//   });
//
//   const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
//
//   useEffect(() => {
//     const handleResize = () => {
//       setDimensions(getDimensions())
//     }
//
//     if (myRef.current) {
//       setDimensions(getDimensions())
//     }
//
//     window.addEventListener('resize', handleResize)
//
//     return () => {
//       window.removeEventListener('resize', handleResize)
//     }
//   }, [myRef])
//
//   return dimensions;
// };

const defaultLineOptions = {
  priceLineVisible: false,
  lastValueVisible: false,
  lineWidth: 2
};

//
// const colours = [ '#27AE60', '#9B59B6', '#2980B9', '#1ABC9C', '#F1C40F'];


export class WattagePreviewGraph extends React.Component {
  graph = null;
  visibleTimeRangeTimer = null;
  static propTypes = { initialCount: PropTypes.number };
  state = {
    data: {},
    chart: {
      width: 200,
      height: 400,
      chartElement: null,
      chart: null,
    },
    lastLoadedChartQuery: null,
    viewpoint: {
      from: null,
      to: null
    },
    inner: {
      minWidth: 200,
      maxWidth:300,
      minHeight: 370
    },
    options:  {
      height: 400,
      priceScale:{
        autoScale: true,
      },
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.7)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.7)',
        },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
        lockVisibleTimeRangeOnResize: true,
      },
      localization: {
        timeFormatter: businessDayOrTimestamp => {
          // console.log(businessDayOrTimestamp);
          let isTimeStamp = (parseInt(businessDayOrTimestamp) > 0);
          if (!isTimeStamp){
            return businessDayOrTimestamp;
          }
          return new Date(businessDayOrTimestamp * 1000).toLocaleString('en-US', { timeZone: 'Australia/Brisbane' });
          // if (LightweightCharts.isBusinessDay(businessDayOrTimestamp)) {
          //   return 'Format for business day';
          // }
          //
          // return 'Format for timestamp';
        },
      }

    },
    isLoading: false,
    pendingRequests: 0,
    isLoaded: false,
    isTimedOut: false,
    dataType: 'watts',
    dataTypeOpen: false,
    dataTypePopoverTarget: null

  };
  static defaultProps = { isLoaded: false, data: []  };

  generateMarkersFromInsights(optimiserId, insights){
    let markers = [];
    for (let insightIndex in insights){
      if (!insights.hasOwnProperty(insightIndex)) continue;
      let insight = insights[insightIndex];

      if (insight.optimiser_ids.includes(optimiserId)){
        markers.push({
          time: insight.detected_at,
          position: 'belowBar',
          color: '#f68410',
          shape: 'circle',
          text: insight.message,
          size: 1
        });
      }
    }
    console.log(markers);
    return markers;
  }
  generateAllMarkersFromInsights(optimiserIds, insights){
    let markers = [];
    for (let insightIndex in insights){
      if (!insights.hasOwnProperty(insightIndex)) continue;
      let insight = insights[insightIndex];
      let include = false;

      for (let i=0; i < optimiserIds.length; i++){
        include = insight.optimiser_ids.includes(optimiserIds[i])
        if (include === true){
          break;
        }
      }
      if (include === true){
        markers.push({
          time: insight.detected_at,
          position: 'belowBar',
          color: '#f68410',
          shape: 'circle',
          text: insight.message,
          size: 1
        });
      }
    }
    console.log(markers);
    return markers;
  }

  setChartData(chartData){
    console.log('[WattageGraph] - Receiving chart data');
    //todo: there's a bug here that changes the viewpoint incorrectly
    //
    // if (JSON.stringify(chartData.query) !== JSON.stringify(this.createQueryFromContext(this.state, this.props))){
    //   console.log('[WattageGraph] - Received chart data from old query. Stored as cache but didn't update chart');
    //   return;
    // }
    if (JSON.stringify(chartData.query) !== JSON.stringify(this.state.lastLoadedChartQuery)){
      console.log('[WattageGraph] - Received chart data from old query. Stored as cache but didn\'t update chart');
      return;
    }

    let lightweightChartData = [];
    //attached props.insights as markers
    //insights
    //console.log(this.props.insights);
    let optimiserIds = [];
    for (let optimiserId in chartData.data) {
      if (!chartData.data.hasOwnProperty(optimiserId)) continue;
      optimiserIds[optimiserIds.length] = optimiserId;
    }


    let markers = this.generateAllMarkersFromInsights(optimiserIds, this.props.insights);
    let timeDataPoints = [];
    for (let optimiserId in chartData.data) {
      if (!Object.prototype.hasOwnProperty.call(chartData.data, optimiserId)) continue;
      //if (!chartData.data.hasOwnProperty(optimiserId)) continue;
      if (!Object.prototype.hasOwnProperty.call(this.props, 'optimisers')) continue;

      let _propsOptimiserIds = this.props.optimisers ;
      if (_propsOptimiserIds.includes(optimiserId)) {
        // markers = this.generateMarkersFromInsights(optimiserId, this.props.insights);
        lightweightChartData[lightweightChartData.length] = {
          type: 'line',
          options: defaultLineOptions,
          label: optimiserId,
          data: chartData.data[optimiserId],
          markers: [],
        };
        for (let i=0; i < chartData.data[optimiserId].length; i++){
          timeDataPoints[timeDataPoints.length] = { time:  chartData.data[optimiserId][i].time, value: 0 };
        }
      }

    }
    if (timeDataPoints.length > 0 && markers.length > 0) {
      lightweightChartData[lightweightChartData.length] = {
        type: 'line',
        options: { ...defaultLineOptions, lineWidth: 0, crosshairMarkerVisible: false,   color: 'rgba(255, 44, 128, 0)', },
        label: 'markers',
        data: timeDataPoints,
        markers: markers,
      };
    }

    let pendingRequests = this.state.pendingRequests;

    switch( chartData.cacheResult) {
      case chartDataRepo.results.pass:
        //pass will continue to load, but passed back the cache data
        //so we're expecting another request.
        break;
      case chartDataRepo.results.miss:
      case chartDataRepo.results.hit:
      default:
        pendingRequests--;
        break;
    }

    this.setState({
      ...this.state,
      isLoaded: chartData.isLoaded,
      data: lightweightChartData,
      isTimedOut: false,
      isLoading: true,
      pendingRequests: pendingRequests
    });
    console.log('[WattageGraph] - Finished Receiving Chart Data');
    return chartData;
  }


  componentDidMount() {
    this.loadChart();
  }

  createQuery(dataType, installId, from, to, optimiserIds, limit){
    limit = parseInt(limit);
    if (limit === 0) {
      limit = 1200;
    }
    return {
      dataType: dataType,
      filters: {
        installId: installId,
        from: from,
        to: to,
        optimiserIds: optimiserIds,
      },
      limit: limit
    }
  }

  createQueryFromContext(state, props){
    if (state == null){
      state = this.state;
    }
    if (props == null){
      props = this.props;
    }
    // let width = 0;
    // if (this.props.width == null){
    //   width = state.chart.width;
    // }
    return this.createQuery(
      state.dataType,
      props.installid,
      props.from,
      props.to,
      props.optimisers,
      state.chart.width
    );

  }

  loadChart(query) {
    if (query == null){
      query = this.createQueryFromContext(this.state, this.props);
    }
    let newState = {
    ...this.state,
      isLoading: true,
      pendingRequests: this.state.pendingRequests + 1,
      lastLoadedChartQuery: query,
    };
    if (newState.viewpoint.from == null){
      newState.viewpoint.from = moment(query.filters.from).toDate();
    }
    if (newState.viewpoint.to == null){
      newState.viewpoint.to = moment(query.filters.to).toDate();
    }

    this.setState(newState,
      () => {
        chartDataRepo.load(query, this.setChartData.bind(this))
      }
    );
  }

  handleDataTypeOpenClick(event) {
    this.setState({
      ...this.state,
      dataTypeOpen: !this.state.dataTypeOpen,
      dataTypePopoverTarget: event.currentTarget
    });
  }

  handleDataTypeListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        ...this.state,
        dataTypeOpen: false,
      });
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('[WattageGraph] - Did Update');
    let currentQuery = this.createQueryFromContext(this.state, this.props);
    let prevQuery =  this.createQueryFromContext(prevState, prevProps);

    if (!(JSON.stringify(currentQuery) === JSON.stringify(prevQuery))) {

      if (currentQuery.filters.from !== prevQuery.filters.from ||
          currentQuery.filters.to !== prevQuery.filters.to)
      {
        this.setState({
          ...this.state,
          viewpoint: {
            from: null,
            to: null
          },
          //isLoaded: false,
        }, this.loadChart.bind(this));
      }
      else{
        this.loadChart();
      }

    }
  }

  onChartResize(width, height){
    this.setState({
      ...this.state,
      chart: {
        ...this.state.chart,
        width: width,
        height: height
      },
    });

    console.log('[WattageGraph - onChartResize] width:'  + width +' Height:'+ height);
  }
  onLoadChartFinish(chartLoadedParams){
    //if modifying please see source:
    //component/LightWeightChart.js - loadChart(div)
    //and change defaults in this.state
    // let newState = {
    //   ...this.state,
    //   isLoading: false,
    //   chart: chartLoadedParams
    // };
    // if (this.state.viewpoint.from == null){
    //   newState.viewpoint.from = this.state.lastLoadedChartQuery.from;
    // }
    // if (this.state.viewpoint.to == null){
    //   newState.viewpoint.to = this.state.lastLoadedChartQuery.to;
    // }


    //this.setState(newState);
    this.setState({
      ...this.state,
      chart: chartLoadedParams,
      isLoading: false,
    });

    console.log('[WattageGraph - onLoadChartFinish] width:'  + chartLoadedParams.width +' Height:'+ chartLoadedParams.height);
  }
  onUpdateChartFinish(chartLoadedParams){
    //if modifying please see source:
    //component/LightWeightChart.js - loadChart(div)
    //and change defaults in this.state
    this.setState({
      ...this.state,
      isLoading: false
    });

    console.log('[WattageGraph - onUpdateChartFinish]');
  }

  startDownloadChartData(){
    let query = {
      ...this.createQueryFromContext(this.state, this.props)
    };
    let newFromTime = new Date(
      query.filters.from.getFullYear() , //-20% of the diff rounded.
      query.filters.from.getMonth(),
      query.filters.from.getDate(),
      0,
      0,
      0,
      0
    );
    let newToTime = new Date(
      query.filters.to.getFullYear(),
      query.filters.to.getMonth(),
      query.filters.to.getDate(),
      23,
      59,
      59,
      0
    );

    query.filters.optimiserIds = null;
    query.filters.from = newFromTime;
    query.filters.to = newToTime;
    query.limit = null;
    query.mode = chartDataRepo.mode.download;
    chartDataRepo.load(query, function(){});
  }

  onVisibleTimeRangeChange(newVisibleTimeRange){
    return;
    // if (newVisibleTimeRange == null){
    //   return;
    // }
    //
    // //check to see how far out it is to the hour.
    // //only query on the hour (and set data outside of visible range if needed)
    // //to avoid cache misses
    // //if really zoomed in take it down to the 10min block
    // if (this.state.isLoading){
    //   return;
    // }
    // if (this.visibleTimeRangeTimer !== null) {
    //   clearTimeout(this.visibleTimeRangeTimer);
    // }
    // // //wait 200ms then start query
    // this.visibleTimeRangeTimer = setTimeout(() => {
    //
    //
    //   // let newFromInputTime = new Date((newVisibleTimeRange.from) * 1000);
    //   // let newToInputTime = new Date((newVisibleTimeRange.to) * 1000);
    //   //fetch upto the day
    //   let paddingFactor = 0.3;
    //
    //  // let browserOffset = new Date().getTimezoneOffset();
    //   //currentVisibleTimeFrom  new visible time range
    //   //if new visible time range
    //   let diffInSeconds = moment(newVisibleTimeRange.to * 1000 ).unix() - moment(newVisibleTimeRange.from  * 1000 ).unix();
    //   let secondsPerLogicalPoint = (this.state.chart.width * (1 + (2 * paddingFactor))) / diffInSeconds;
    //   let logicalPointsPerSecond =  diffInSeconds / (this.state.chart.width * (1 + (2 * paddingFactor))) ;
    //
    //
    //
    //   let inputtedFrom = newVisibleTimeRange.from;
    //   let inputtedTo = newVisibleTimeRange.to;
    //   // if (this.state.chart.chart != null) {
    //   //   let graphLogicalRange = this.state.chart.chart.timeScale().getVisibleLogicalRange();
    //   //   // if (graphLogicalRange.from < 0) {
    //   //   //   let secondsToVisiblePoint = (graphLogicalRange.from * secondsPerLogicalPoint);
    //   //   //   inputtedFrom = newVisibleTimeRange.from  + secondsToVisiblePoint ;
    //   //   //   console.log('newVisibleTimeRange.from: '+ new Date(newVisibleTimeRange.to*1000) );
    //   //   //   console.log('proposed new from '+ new Date(inputtedFrom*1000));
    //   //   // }
    //   //   if (graphLogicalRange.to > 0) {
    //   //     //let diffInPoints =  diffInSeconds * secondsPerLogicalPoint ;
    //   //     //let secondsToVisiblePoint = (graphLogicalRange.to - diffInPoints) / secondsPerLogicalPoint;
    //   //     let secondsToVisiblePoint = parseInt((graphLogicalRange.to - (diffInSeconds * secondsPerLogicalPoint)) / secondsPerLogicalPoint);
    //   //
    //   //     inputtedTo = (secondsToVisiblePoint < 0)? newVisibleTimeRange.to : newVisibleTimeRange.to + secondsToVisiblePoint;
    //   //
    //   //     console.log('newVisibleTimeRange.to: '+ new Date(newVisibleTimeRange.to * 1000) );
    //   //     console.log('proposed new to '+ new Date(newVisibleTimeRange.to + secondsToVisiblePoint * 1000));
    //   //   }
    //   // }
    //
    //   //we need to load a little more data than the viewable area,
    //   //so that we can allow for users zooming out and still have
    //   //data points so reloading becomes only background in the background.
    //   let diffPadding = diffInSeconds * paddingFactor;
    //   //try a new time with the diff and see if we're going over to the next day
    //   //keep it within the same day to easily get data from caches.
    //   let proposedNewFromTime = new Date( (inputtedFrom - diffPadding) * 1000);
    //   let proposedNewToTime = new Date( (inputtedTo + diffPadding) * 1000);
    //
    //   let newFromInputTime =  (moment(proposedNewFromTime).isAfter(this.props.from))? proposedNewFromTime : this.props.from ;
    //   let newToInputTime =  (moment(proposedNewToTime).isBefore(this.props.to))? proposedNewToTime : this.props.to ;
    //
    //   let newFromTime = new Date(
    //     newFromInputTime.getFullYear() , //-20% of the diff rounded.
    //     newFromInputTime.getMonth(),
    //     newFromInputTime.getDate(),
    //     newFromInputTime.getHours(), //(newFromInputTime.getHours() > 1)? newFromInputTime.getHours()-2 : newFromInputTime.getHours(),
    //     0,
    //     0, //(newFromInputTime.getHours() > 1)? 0 : -(diffPadding * 1000),
    //     0
    //   );
    //   let newToTime = new Date(
    //     newToInputTime.getFullYear(),
    //     newToInputTime.getMonth(),
    //     newToInputTime.getDate(),
    //     newToInputTime.getHours(), //(newToInputTime.getHours() < 23)? newToInputTime.getHours()+2 : newToInputTime.getHours(),
    //     59,
    //     59, //(newToInputTime.getHours() < 23)? 59 : 59 + (diffPadding * 1000),
    //     0
    //   );
    //
    //
    //
    //   console.debug('[onVisibleTimeRangeChange] inputted from: ' + newFromTime + ' to: ' + newToTime);
    //   //return;
    //   let newQuery = this.createQuery(
    //     this.state.dataType,
    //     this.props.installid,
    //     newFromTime, //need to account for timezone here
    //     newToTime,
    //     this.props.optimisers,
    //     parseInt(this.state.chart.width * (1 + (2 * paddingFactor))) //allow 20% more padding each side of the graph
    //   );
    //
    //   // if (this.state.viewpoint.from != null ){
    //   //   //check to see that the current query isn't
    //   //   5% more
    //   //   moment(this.state.lastLoadedChartQuery.from).unix() * 0.05 < newQuery.from
    //   // }
    //   // if (this.state.viewpoint.to ){
    //   //
    //   // }
    //   if (JSON.stringify(this.state.lastLoadedChartQuery) === JSON.stringify(newQuery)) {
    //     return;
    //   }
    //
    //   //todo: newVisibleTimeRange.from seems to be based on the initial date (today's date) we need to find the data range on the visible range
    //   console.debug('[onVisibleTimeRangeChange] from: ' + newFromTime + ' to: ' + newToTime);
    //   //return;
    //   this.setState({
    //       ...this.state,
    //       isLoading: true,
    //       viewpoint: {
    //         from: new Date(inputtedFrom *1000),
    //         to: new Date(inputtedTo * 1000)
    //       },
    //       lastLoadedChartQuery: newQuery
    //     },
    //     () => {
    //   this.loadChart(newQuery);
    //     }
    //   );
    // }, 300);



    //check to  newVisibleTimeRange.from
    //check newVisibleTimeRange.to
    //may need to force a timeout here (maybe 500ms, then start the load)



   //check if we have enough datapoints for the from -> to
    //if so, ignore, if not, start grabbing in the background
  }


  onChangeDataType(dataType){
    this.setState({
      ...this.state,
      dataType: dataType,
      dataTypeOpen: false,
      isLoading: true
    }, this.loadChart.bind(this));
  }

  render() {
    const { installid, height, width, from, to, optimisers, insights, className, classes, ...rest } = this.props;

    // this.setState({
    //   ...this.state,
    //   chart: {
    //     ...this.state.chart,
    //     width: width,
    //     height: height
    //   }
    // });

    return     <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardHeader
        action={
          <>
            <Fade
              in={(this.state.pendingRequests > 0)}
              style={{
                transitionDelay: (this.state.pendingRequests > 0) ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <span>
                <CircularProgress className={classes.spinner}  thickness={2} size={15} />
              </span>
            </Fade>

            <Button
              size='small'
              variant='text'
              onClick={this.handleDataTypeOpenClick.bind(this)}
            >
              {this.state.dataType} <ArrowDropDownIcon />
            </Button>
            <Popover
              anchorEl={this.state.dataTypePopoverTarget}
              open={this.state.dataTypeOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ClickAwayListener onClickAway={(() => this.setState({...this.state, dataTypeOpen: false}))}>
                <MenuList autoFocusItem={this.state.dataTypeOpen} id='menu-list-grow' onKeyDown={this.handleDataTypeListKeyDown.bind(this)}>

                  <MenuItem onClick={this.onChangeDataType.bind('watts')}>Watts</MenuItem>
                  <MenuItem onClick={this.onChangeDataType.bind('watt_hours')}>WattHours</MenuItem>
                  <MenuItem onClick={this.onChangeDataType.bind('volts')}>Volts</MenuItem>
                  <MenuItem onClick={this.onChangeDataType.bind('amps')}>Amps</MenuItem>
                  <MenuItem onClick={this.onChangeDataType.bind('temperature')}>Temperature</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Popover>

          </>

        }
        title=''
      />
      <Divider />
      {/*// style={{minHeight: this.props.height }}*/}
      <CardContent className={classes.chartContents} >
        { (this.state.data == null || this.state.data.length < 1) ?
          <div className={classes.chartContents}><Typography >No data found</Typography></div>  :

          this.state.isTimedOut === false
            ? <LightWeightChart chartOptions={this.state.options}
                                chartDataLoaded={this.state.isLoaded}
                                chartData={this.state.data}
                                onLoadChartFinish={this.onLoadChartFinish.bind(this)}
                                onUpdateChartFinish={this.onUpdateChartFinish.bind(this)}
                                onResize={this.onChartResize.bind(this)}
                                onVisibleTimeRangeChange={this.onVisibleTimeRangeChange.bind(this)}
                                viewPointFrom={this.state.viewpoint.from}
                                viewPointTo={this.state.viewpoint.to}
            />
            : <div className={classes.chartContents}><Typography>Timed out. Refresh your page and try again.</Typography></div>


        }
      </CardContent>
    </Card>
  }
}

WattagePreviewGraph.propTypes = {
  className: PropTypes.string
};

export default withStyles(styles)(WattagePreviewGraph);
