//import 'leaflet/dist/leaflet.css'
import { Map as LeafletMap, TileLayer, Marker, Popup } from  'react-leaflet'

import PropTypes from 'prop-types';
import React from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  leafletContainer: {
    height: '400px',
    width: '100%',
  }
}));


const MapWidget = props => {

  //const className = props.className;
  const markers = props.markers;
  const zoom = props.zoom;

  const theme = useTheme();

  const classes = useStyles(theme);


  //const [zoomFactor, setZoomFactor] = useState( zoom);
  const  zoomFactor = zoom;

  let _markers = markers;

  if (_markers == null){
    _markers = [];
  }

  let position = {
      lat: 29.558435166391828,
      lng: -95.08970564198776
  };
  if (_markers.length > 0){
   position = _markers[0]
  }
  return (

    <LeafletMap  className={classes.leafletContainer} center={position} zoom={zoomFactor}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
      />
      {
        _markers.map((_marker) => {
        return (<Marker position={_marker}>
          <Popup>
            _marker.label
          </Popup>
        </Marker>)
        })
      }


    </LeafletMap>
  );
}

MapWidget.propTypes = {
  className: PropTypes.string
};

export default MapWidget;
