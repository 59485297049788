import {config} from '"../../config';
//import Translations from '../../helpers/translations';
//
// class ModuleHealthRating  {
//
//   id: '';
//   name: '';
//   restrict_to_ip: '';
//   is_active: '';
//   notes: '';
//   updated_at: '';
//   created_at: '';
//
//   constructor(obj) {
//     obj && Object.assign(this, obj);
//   }
// }
//
// class ModuleHealthRatingList {
//   data: Array<ModuleHealthRating>;
//   meta: {
//     total: 0,
//     page:  0,
//     page_size: 0
//   };
// }

const apiUrl = config.cqsolaDataApi;
export default class StatisticsService {

  constructor(config) {
    if (config == null){
      config = {};
    }
    this.config = config;
  }

  getModuleHealthRatings(): Promise<[]> {
    let service = this;
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({})
      };
      let dataUrl = `${apiUrl}/statistics/module_health/rating`;
      fetch(dataUrl, requestOptions)
        .then(service.config.afterFetch)
        .then((response) => {
          return response.json()
        })
        .then(result => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }




}

export {StatisticsService};
